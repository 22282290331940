import React, { useEffect, useRef, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { useBarcode } from 'next-barcode';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setNotes, setShowRemoteResponseToast } from '../../core/redux/action';
import { showInternalErrorAlert } from '../components/customAlert';
import { Link, useSearchParams } from 'react-router-dom';
import ToolsHead from '../components/toolsHead';
import { fechaHoraFormat, formatearFecha, formatearMoneda, IVA } from '../../core/utils';
import { Sales } from '../../services/internal/sales';
import { Table } from 'antd';
import { all_routes } from '../../Router/all_routes';
import { useReactToPrint } from 'react-to-print';
import Receipt from '../components/receipt';
import { Functions, Modules, verifyPermission } from '../../Router/authorization';

const SaleDetails = () => {

    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const loading = useSelector((state) => state.loading);
    const codeParam = searchParams.get('code');
    const [dataItem, setDataItem] = useState(null);
    const route = all_routes;
    const { inputRef } = useBarcode({
        value: dataItem?.code,
        options: {
            //format: "EAN13",
        }
    });

    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef });

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadItem = async () => {
        try {
            let data = Sales.requestData;
            data = {};
            data.id = Number(codeParam);
            if (isNaN(data.id)) {
                return;
            }
            dispatch(fetchDataRequest());

            const response = await Sales.getSingle(token, codeParam);

            setDataItem(response.data.data);
            //console.log(response)
            if (response.data.data?.comentary) {
                dispatch(setNotes(JSON.parse(response.data.data?.comentary)));
            } else {
                dispatch(setNotes(null));
            }
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    useEffect(() => {
        if (codeParam) {
            onLoadItem();
        }
    }, [codeParam]);

    const packageColumns = [
        {
            title: "Cantidad",
            align: "center",
            width: 80,
            dataIndex: "quantity",
            render: (text) => (
                <span>{text}</span>
            ),
        },
        {
            title: "Unidad",
            align: "center",
            width: 80,
            render: () => (
                <span>Paq.</span>
            ),
        },
        {
            title: "Producto",
            render: (text, record) => (
                <span
                    className="text-primary fw-semibold"
                >
                    {record?.package?.name}
                </span>
            ),
        },
        {
            title: "Precio unitario",
            align: "center",
            dataIndex: "salePrice",
            width: 120,
            render: (text, record) => (
                <span>{formatearMoneda(record?.package.unitPrice)}</span>
            ),
        },
        {
            title: "Subtotal",
            align: "right",
            dataIndex: "subtotal",
            width: 120,
            render: (text, record) => (
                <span>{formatearMoneda(record?.package.unitPrice * record?.quantity)}</span>
            ),
        },
    ];

    const articleColumns = [
        {
            title: "Cantidad",
            align: "center",
            width: 80,
            dataIndex: "quantity",
            render: (text) => (
                <span>{text}</span>
            ),
        },
        {
            title: "Unidad",
            align: "center",
            width: 80,
            render: (text, record) => (
                <span>{record?.article?.unmed?.abbreviation}</span>
            ),
        },
        {
            title: "Producto",
            render: (text, record) => (
                <Link
                    to={route.productdetails + `?code=${record?.article?.id}`}
                    className="text-primary fw-semibold"
                    target="_blank" rel="noopener noreferrer">
                    {record?.article?.name}
                </Link>
            ),
        },
        {
            title: "Precio unitario",
            align: "center",
            dataIndex: "salePrice",
            width: 120,
            render: (text, record) => (
                <span>{formatearMoneda(record?.subtotal / record?.quantity)}</span>
            ),
        },
        {
            title: "Subtotal",
            align: "right",
            dataIndex: "subtotal",
            width: 120,
            render: (text, record) => (
                <span>{formatearMoneda(record?.subtotal)}</span>
            ),
        },
    ];



    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="page-title">
                        <h4>Ficha de Venta</h4>
                        <h6>Información completa de la Venta.</h6>
                    </div>
                    <ToolsHead
                        showExportData={verifyPermission(rolePermissions, Modules.Ventas, Functions.Exportar)}
                        showPrint={true}
                        onReload={onLoadItem}
                        onPrint={reactToPrintFn}
                    />
                </div>
                {/* /add */}
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                                        <div className="bar-code-view">
                                            <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                                        <div className="bar-code-view">
                                            {loading &&
                                                <div id="global-loader" style={{ position: 'relative', height: 80 }}>
                                                    <div className="whirly-loader"></div>
                                                </div>
                                            }
                                            <svg ref={inputRef} />
                                        </div>
                                    </div>
                                </div>
                                <div className="productdetails mb-4">
                                    <ul className="product-bar">
                                        <li>
                                            <h4>Código</h4>
                                            <h6 className="text-orange fw-semibold">{dataItem?.code}</h6>
                                        </li>
                                        <li>
                                            <h4>Cliente</h4>
                                            <h6 className="text-dark">{dataItem?.client ? dataItem?.client?.name : "NA"}</h6>
                                        </li>
                                        <li>
                                            <h4>Sucursal</h4>
                                            <h6 className="text-secondary">{dataItem?.branchId ? dataItem?.branchId?.name : "NA"}</h6>
                                        </li>
                                        <li>
                                            <h4>Atendido por:</h4>
                                            <h6 className="text-primary">{dataItem?.user ? dataItem?.user?.alias : "NA"}</h6>
                                        </li>
                                        <li>
                                            <h4>Fecha</h4>
                                            <h6>{dataItem?.registered ? formatearFecha(dataItem?.registered, fechaHoraFormat) : "NA"}</h6>
                                        </li>
                                        <li>
                                            <h4>Total</h4>
                                            <h6 className="text-info">{formatearMoneda(dataItem?.total)}</h6>
                                        </li>
                                        <li>
                                            <h4>Método de pago</h4>
                                            <h6 className="text-purple fw-semibold">{dataItem?.paymentMethod?.name}</h6>
                                        </li>
                                    </ul>
                                </div>
                                <div className="row">
                                    {dataItem?.packages?.length > 0 &&
                                        <div className="table-responsive">
                                            <Table
                                                columns={packageColumns}
                                                dataSource={dataItem?.packages}
                                                rowKey="key"
                                                rowHoverable={false}
                                                pagination={false}
                                                size="small"
                                            />
                                        </div>
                                    }
                                    {dataItem?.articles?.length > 0 &&
                                        <div className="table-responsive">
                                            <Table
                                                showHeader={dataItem?.packages?.length === 0}
                                                columns={articleColumns}
                                                dataSource={dataItem?.articles}
                                                rowKey="key"
                                                rowHoverable={false}
                                                pagination={false}
                                                size="small"
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-6 ms-auto">
                                        <div className="total-order w-100 max-widthauto m-auto mb-4">
                                            <ul>
                                                <li>
                                                    <h4>Subtotal</h4>
                                                    <h5>{formatearMoneda(dataItem?.total)}</h5>
                                                </li>
                                                <li>
                                                    <h4>IVA {IVA > 0 ? `(${IVA * 100}%)` : "Incluido"}</h4>
                                                    <h5>{formatearMoneda(dataItem?.total * IVA)}</h5>
                                                </li>
                                                <li>
                                                    <h4 className="text-info fw-semibold">Total</h4>
                                                    <h5 className="text-info fw-semibold">{formatearMoneda(dataItem?.total)}</h5>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="row p-3">
                                <h4>Recibo de Compra</h4>
                            </div>
                            <Receipt innerRef={contentRef} saleData={dataItem} />
                        </div>
                        {
                            dataItem?.breakSaleId &&
                            <div className="card">
                                <div className="row p-3">
                                    <h4>Corte de Caja</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row text-center">
                                        <Link
                                            className="text-blue fw-semibold"
                                            to={route.cashBreakDetails + `?code=${dataItem.breakSaleId}`}
                                        >
                                            Ir al corte
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SaleDetails
