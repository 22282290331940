import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { Box, CheckCircle, CornerUpRight, MinusCircle, PlusCircle, RefreshCcw, RotateCw, ShoppingCart, Trash2, UserPlus, XCircle } from 'react-feather'
import Select from 'react-select'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { fechaFormat, formatearFecha, formatearMoneda, generarCodigo, IVA, noOptionsText, paymentMethodPos, validateQuantity } from '../../core/utils'
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCustomerOptions, setShowRemoteResponseToast } from '../../core/redux/action'
import { showInternalErrorAlert } from '../components/customAlert'
import { useDispatch, useSelector } from 'react-redux'
import { Catalogs } from '../../services/internal/catalogs'
import { Bs1Circle, Bs2Circle, Bs3Circle, Bs4Circle, BsCashStack, BsCheck2Circle, BsCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import { MdOutlineArrowCircleLeft, MdOutlineArrowCircleRight, MdOutlineCategory } from "react-icons/md";
import { Menus } from '../../services/internal/menus'
import CategorySelector from '../components/categorySelector'
import CashModal from '../../core/modals/payment/cash'
import MercadoPagoModal from '../../core/modals/payment/mercadopago'
import { Sales } from '../../services/internal/sales'
import { Spinner } from 'react-bootstrap'
import CashOutflowModal from '../../core/modals/sales/cashOutflowModal'
import UserSalesModal from '../../core/modals/sales/userSales'
import AddCustomerModal from '../../core/modals/sales/addCustomerModal'
import UserCashBreakModal from '../../core/modals/sales/userCashBreakModal'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Functions, Modules, verifyPermission } from '../../Router/authorization'


const Pos = () => {
  const customerOptions = useSelector((state) => state.customerOptions);
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const rolePermissions = useSelector((state) => state.rolePermissions);
  const userDetails = useSelector((state) => state.userDetails);
  const [orderProducts, setOrderProducts] = useState([]);
  const [orderPackages, setOrderPackages] = useState([]);
  const [subtotalProducts, setSubtotalProducts] = useState(0);
  const [subtotalPackages, setSubtotalPackages] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [customer, setCustomer] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [code, setCode] = useState(null);
  const [showPackages, setShowPackages] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [availableMenus, setAvailableMenus] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [validCategories, setValidCategories] = useState([]);
  const [showCashModal, setShowCashModal] = useState(false);
  const [showMercadoPagoModal, setShowMercadoPagoModal] = useState(false);
  const [showUserSalesModal, setShowUserSalesModal] = useState(false);
  const [showCashOutflowModal, setShowCashOutflowModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showUserCashBreakModal, setShowUserCashBreakModal] = useState(false);

  const handleCardClick = (payment) => {
    setPaymentMethod(payment);
    handlePayment(payment);
  };

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadOptions = async () => {
    try {
      dispatch(fetchDataRequest());
      let cOptions = [];
      const response = await Catalogs.getOptions(token, Catalogs.Name.Clients);
      dispatch(setCustomerOptions(response.data.data));
      cOptions = response.data.data;
      setCustomer(cOptions.find((i) => i.label?.toLowerCase().includes("general")));
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onLoadMenus = async () => {
    let filters = {};
    filters.enabled = true;
    filters.branchId = userDetails?.branch?.id;
    filters.platformId = 1;

    try {
      dispatch(fetchDataRequest());
      let cOptions = [];
      if (customerOptions.length === 0) {
        const responseA = await Catalogs.getOptions(token, Catalogs.Name.Clients);
        dispatch(setCustomerOptions(responseA.data.data));
        cOptions = responseA.data.data;
      } else {
        cOptions = customerOptions;
      }

      const response = await Menus.getList(token, filters);
      const menus = response.data.data;
      setAvailableMenus(menus);
      if (menus?.length === 1) {
        await onLoadMenu(menus[0]?.id);
      }

      setCustomer(cOptions.find((i) => i.label?.toLowerCase().includes("general")));
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onLoadMenu = async (menuId) => {
    try {
      dispatch(fetchDataRequest());

      const response = await Menus.getSingle(token, menuId);
      const menu = response.data.data;
      if (menu?.packages?.length > 0) {
        menu.packages = menu.packages.sort((a, b) => a.package?.name?.localeCompare(b.package?.name));
        onShowPackages();
      } else if (menu?.articles?.length > 0) {
        onShowProducts();
      }
      setCurrentMenu(menu);
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onSaveSale = async () => {
    try {
      let data = Sales.requestData;
      data = {};
      data.branchId = userDetails?.branch?.id;
      data.clientId = customer?.value;
      data.code = code;
      data.paymentMethod = paymentMethod?.value;
      data.total = total;
      data.terminal = userDetails?.alias;
      data.saleArticles = orderProducts;
      data.salePackages = orderPackages;
      if (orderPackages?.length > 0) {
        orderPackages.map((p) => {
          if (p?.requiredProducts?.length > 0) {
            p?.requiredProducts.map((i) => {
              data.saleArticles.push({
                articleId: i.productId,
                quantity: 1,
                subtotal: 0
              });
            });
          }
          if (p?.optionalProducts?.length > 0) {
            p?.optionalProducts.map((i) => {
              data.saleArticles.push({
                articleId: i.productId,
                quantity: 1,
                subtotal: 0
              });
            });
          }

        });
      }

      dispatch(fetchDataRequest());
      const response = await Sales.postSingle(token, data);
      dispatch(fetchDataSuccess(response.data.success));
      onReset();
      dispatch(setShowRemoteResponseToast(true));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onShowCategories = (selectedPackage) => {
    if (!selectedPackage) {
      return;
    }
    const simplePackage = selectedPackage.categories?.length === 0;
    if (orderPackages.find((i) => i.id === selectedPackage.id)) {
      if (simplePackage) {
        showSameProductAlert("Ya tienes este paquete en la orden.");
      } else {
        showConfirmPackageAlert(selectedPackage, simplePackage);
      }
      return;
    }

    setCurrentPackage(selectedPackage);

    if (simplePackage) {
      onAddPackage(selectedPackage);
    }
    setValidCategories([]);
  }

  const onShowPackages = (e) => {
    e?.preventDefault();
    setShowProducts(false);
    setShowPackages(true);
  }

  const onShowProducts = (e) => {
    e?.preventDefault();
    setShowPackages(false);
    setCurrentPackage(null);
    setValidCategories([]);
    setShowProducts(true);
  }

  const onAddProduct = (currentProduct, fromPackage) => {
    if (!currentProduct) {
      return;
    }

    if (orderProducts.find((i) => i.productId === currentProduct.id)) {
      showSameProductAlert("Ya tienes este producto en la orden.");
      return;
    }

    let item = {
      key: generarCodigo(),
      code: currentProduct.code,
      productId: currentProduct.id,
      articleId: currentProduct.id,
      quantity: 1,
      name: currentProduct.name,
      salePrice: fromPackage ? 0 : currentProduct.salePrice,
      unit: currentProduct.unmed?.abbreviation,
      imagePath: currentProduct.imageUrls?.length > 0 ? currentProduct.imageUrls[0] : null
    };

    item.subtotal = item.quantity * item.salePrice;
    setOrderProducts(prevOrderList => [...prevOrderList, item].sort((a, b) => a.id - b.id));
    setSubtotalProducts(subtotalProducts + item.subtotal);
    setSubtotal(subtotalPackages + subtotalProducts + item.subtotal);
    setTotal((subtotalPackages + subtotalProducts + item.subtotal) * (1 + IVA));
    setShowOrder(true);
    toast.success(`Agregado ${currentProduct.name}`);
  }

  const onAddPackage = (currentPackage) => {
    let item = {
      id: currentPackage.id,
      key: generarCodigo(),
      packageId: currentPackage.id,
      quantity: 1,
      name: currentPackage.name,
      salePrice: currentPackage.unitPrice,
      imagePath: currentPackage.imagePath,
      requiredProducts: currentPackage.requiredProducts,
      optionalProducts: currentPackage.optionalProducts,
    };
    item.subtotal = item.quantity * item.salePrice;
    setOrderPackages(prevDataSource => [...prevDataSource, item].sort((a, b) => a.id - b.id));
    setCurrentPackage(null);
    setSubtotalPackages(subtotalPackages + item.subtotal);
    setSubtotal(subtotalProducts + subtotalPackages + item.subtotal);
    setTotal((subtotalProducts + subtotalPackages + item.subtotal) * (1 + IVA));
    setShowOrder(true);
    toast.success(`Agregado ${currentPackage.name}`);
  }

  const onChangeProductQty = (value, product) => {
    const newQuantity = validateQuantity(value);

    //Incluido en paquete
    if (product.salePrice === 0) {
      product.quantity = newQuantity;
    } else {
      product.quantity = newQuantity;
      product.subtotal = newQuantity * product.salePrice;
    }

    setOrderProducts(prevDataSource => {

      let items = [...prevDataSource.filter(item => item.key !== product.key), product].sort((a, b) => a.id - b.id);
      const stProducts = calculateTotal(items);
      setSubtotalProducts(stProducts);
      setSubtotal(subtotalPackages + stProducts);
      setTotal((subtotalPackages + stProducts) * (1 + IVA));
      return items;
    });
  }

  const onChangePackageQty = (value, pkg) => {
    const newQuantity = validateQuantity(value);
    pkg.quantity = newQuantity;
    pkg.subtotal = newQuantity * pkg.salePrice;

    setOrderPackages(prevDataSource => {

      let items = [...prevDataSource.filter(item => item.key !== pkg.key), pkg].sort((a, b) => a.id - b.id);
      const stPackages = calculateTotal(items);
      setSubtotalPackages(stPackages);
      setSubtotal(subtotalProducts + stPackages);
      setTotal((subtotalProducts + stPackages) * (1 + IVA));
      return items;
    });
  }

  const onDeletePackage = (pkg) => {
    if (loading) {
      return;
    }

    setOrderPackages(prevOrderList => [...prevOrderList.filter(item => item.key !== pkg.key)]);

    setSubtotalPackages(subtotalPackages - pkg.subtotal);
    setSubtotal(subtotalProducts + subtotalPackages - pkg.subtotal);
    setTotal((subtotalProducts + subtotalPackages - pkg.subtotal) * (1 + IVA));
  }

  const onDeleteProduct = (product) => {
    if (loading) {
      return;
    }

    setOrderProducts(prevOrderList => [...prevOrderList.filter(item => item.key !== product.key)]);

    setSubtotalProducts(subtotalProducts - product.subtotal);
    setSubtotal(subtotalPackages + subtotalProducts - product.subtotal);
    setTotal((subtotalPackages + subtotalProducts - product.subtotal) * (1 + IVA));
  }

  const calculateTotal = (items) => {

    let sumaTotal = items.reduce((acumulador, objetoActual) => {
      return acumulador + objetoActual.subtotal;
    }, 0);

    return sumaTotal;
  }

  const showCleanOrderListAlert = async () => {
    if (orderProducts.length === 0 && orderPackages.length === 0) {
      return;
    }
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      html: `Vamos a eliminar todos productos de la orden.`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Limpiar orden",
      customClass: {
        confirmButton: "btn btn-warning",
      },
    });

    if (result.isConfirmed) {
      setOrderProducts([]);
      setOrderPackages([]);
      setSubtotalPackages(0);
      setSubtotalProducts(0);
      setSubtotal(0);
      setTotal(0);
    } else {
      MySwal.close();
    }
  }

  const showSameProductAlert = async (text) => {
    const result = await MySwal.fire({
      title: `${text}`,
      icon: "info",
      html: `Puedes modificar la cantidad si necesitas agregar más del mismo producto.`,
      confirmButtonText: "De acuerdo",
      confirmButtonColor: "#3fc3ee"
    });

    if (result.isConfirmed) {
      MySwal.close();
    }
  }

  const showConfirmPackageAlert = async (selectedPackage, simplePackage) => {
    const result = await MySwal.fire({
      title: `Ya tienes este paquete en la orden.`,
      icon: "info",
      html: `¿Deseas agregar otro?`,
      showCancelButton: true,
      confirmButtonText: "Agregar",
      confirmButtonColor: "#3fc3ee",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      setCurrentPackage(selectedPackage);

      if (simplePackage) {
        onAddPackage(selectedPackage);
      }
      setValidCategories([]);
      MySwal.close();
    }
  }

  const handlePayment = (payment) => {
    switch (payment?.value) {
      case 2:
        setShowMercadoPagoModal(true);
        break;
      default:
        setShowCashModal(true);
    }
  }

  const onValidSelection = (category, isValid, requiredProducts, optionalProducts) => {
    const currentCat = {
      categoryId: category.id,
      valid: isValid
    };

    currentPackage.requiredProducts = requiredProducts.filter((f) => f.name !== null);
    currentPackage.optionalProducts = optionalProducts.filter((f) => f.name !== null);

    setValidCategories(prev => [...prev.filter((f) => f.categoryId !== category.id), currentCat]);
  }

  const isValidSelection = () => {
    return validCategories.filter((f) => f.valid === true).length === currentPackage.categories.length;
  }

  const onClearPackage = () => {
    setCurrentPackage(null);
  }

  const onHideCustomerModal = async (refresh) => {
    if (refresh) {
      await onLoadOptions();
    }
    setShowCustomerModal(false);
  }

  const onReset = () => {
    if (availableMenus?.length > 1) {
      setCurrentMenu(null);
    }
    setCurrentPackage(null);
    setPaymentMethod(null);
    setShowProducts(false);
    setShowPackages(false);
    setShowOrder(false);
    setShowCashModal(false);
    setShowMercadoPagoModal(false);
    setValidCategories([]);
    setCode(generarCodigo());
    setOrderProducts([]);
    setOrderPackages([]);
    setSubtotalPackages(0);
    setSubtotalProducts(0);
    setSubtotal(0);
    setTotal(0);
  }

  const menuText = () => {

    if (loading) {
      return <span className="text-primary fw-bold">Cargando menú <Spinner className='text-primary' size='sm' /></span>;
    }
    if (!currentMenu) {
      return <span className="text-primary fw-bold">No encontramos un menú activo para tu sucursal</span>;
    }

    return (
      <span>
        Puedes agregar a tu orden productos individuales y/o paquetes del menú:
        <span className="text-primary fw-bold"> {currentMenu?.name}</span>.
      </span>
    );
  }

  useEffect(() => {
    if (userDetails) {
      onLoadMenus();
      setCode(generarCodigo());
    }
  }, [userDetails]);

  return (
    <div className="content p-3" style={{ marginTop: 80 }}>
      <div className="row">
        {verifyPermission(rolePermissions, Modules.Ventas, Functions.Ver) &&
          <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
            <div
              onClick={() => setShowUserSalesModal(true)}
              className="d-flex justify-content-center align-items-center btn btn-lg btn-info"
            >
              <ShoppingCart className="me-2" size={16} />
              Mis ventas
            </div>
          </div>
        }
        {verifyPermission(rolePermissions, Modules.SalidasEfectivo, Functions.Registrar) &&
          <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
            <div
              onClick={() => setShowCashOutflowModal(true)}
              className="d-flex justify-content-center align-items-center btn btn-lg btn-warning"
            >
              <CornerUpRight className="me-2" size={16} />
              Salida de efectivo
            </div>
          </div>
        }
        {verifyPermission(rolePermissions, Modules.CortesCaja, Functions.Ver) &&
          <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
            <div
              className="d-flex justify-content-center align-items-center btn btn-lg btn-primary"
              onClick={() => setShowUserCashBreakModal(true)}
            >
              <BsCashStack className="me-2" size={16} />
              Corte de caja
            </div>
          </div>
        }
        <div className="col-sm-12 col-md-3 col-lg-3 mb-3">
          <div
            onClick={() => onReset()}
            className="d-flex justify-content-center align-items-center btn btn-lg btn-secondary"
          >
            <RotateCw className=" me-2" size={16} />
            Reiniciar orden
          </div>
        </div>
      </div>
      <div className="row">
        {!currentMenu &&
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="row">
              {availableMenus?.length > 1 && availableMenus?.map((item) => (

                <div
                  key={item?.id}
                  className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12"
                >
                  <div
                    id={item.name}
                    className="pos-products"
                  >
                    <div
                      className="product-info card"
                      onClick={() => onLoadMenu(item?.id)}
                    >
                      <a className="img-bg">
                        <ImageWithBasePath
                          src={item.imagePath ? item.imagePath : "assets/img/menu/default.png"}
                          alt="Menu"
                        />
                        <span>
                          <BsCheckCircleFill size={30} />
                        </span>
                      </a>
                      <h4 className="text-secondary text-uppercase">{item.name}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
        {currentMenu &&
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="row">
              <div className="col-auto">
                <Bs1Circle className="text-secondary" size={35} />
              </div>
              <div className="col-auto">
                <h5 className="h4 text-secondary">¿Cómo quieres armar tu orden?</h5>
                <div>{menuText()}</div>
              </div>
            </div>
            <div className="row">
              {availableMenus?.length > 1 &&
                <div className="col-auto mb-3">
                  <button
                    className="d-flex flex-row justify-content-center align-items-center btn btn-lg btn-cancel"
                    onClick={() => setCurrentMenu(null)}
                    disabled={loading}
                  >
                    <MdOutlineArrowCircleLeft className="me-2" size={40} />
                    Menús
                  </button>
                </div>
              }
              {currentMenu?.packages?.length > 0 &&
                <div className="col mb-3">
                  <button
                    className={
                      showPackages ? "d-flex flex-row justify-content-center align-items-center btn btn-lg btn-purple-active w-100"
                        : "d-flex flex-row justify-content-center align-items-center btn btn-lg btn-outline-purple w-100"
                    }
                    onClick={(e) => onShowPackages(e)}
                    disabled={loading}
                  >
                    <MdOutlineCategory className="me-2" size={40} />
                    Paquetes
                  </button>
                </div>
              }
              {currentMenu?.articles?.length > 0 &&
                <div className="col mb-3">
                  <button
                    className={
                      showProducts ? "d-flex flex-row justify-content-center align-items-center btn btn-lg btn-dark-active w-100"
                        : "d-flex flex-row justify-content-center align-items-center btn btn-lg btn-outline-dark w-100"
                    }
                    onClick={(e) => onShowProducts(e)}
                    disabled={loading}
                  >
                    <Box className="me-2" size={40} />
                    Productos individuales
                  </button>
                </div>
              }
            </div>
            {showPackages &&
              <div className="row mb-1">
                <div className="col-auto">
                  <Bs2Circle className="text-secondary" size={35} />
                </div>
                <div className="col-auto">
                  <h5 className="h4 text-secondary">Escoge un paquete</h5>
                  <p>Selecciona un paquete para agregarlo a la orden.</p>
                </div>
              </div>
            }
            {showProducts &&
              <div className="row mb-1">
                <div className="col-auto">
                  <Bs2Circle className="text-secondary" size={35} />
                </div>
                <div className="col-auto">
                  <h5 className="h4 text-secondary">Escoge un producto</h5>
                  <p>Selecciona un producto para agregarlo a la orden.</p>
                </div>
              </div>
            }
            <div className="row">
              {showProducts && currentMenu.articles.map((item) => (
                <div
                  key={item?.article.code}
                  className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12"
                >
                  <div
                    id={item?.article.id}
                    className="pos-products"
                    onClick={() => onAddProduct(item?.article)}
                  >
                    <div className="product-info card">
                      <a className="img-bg">
                        <ImageWithBasePath
                          src={item?.article.imageUrls?.length > 0
                            ? item?.article.imageUrls[0]
                            : "assets/img/product/default.png"}
                          alt="Products"
                        />
                        <span>
                          <BsPlusCircleFill size={30} />
                        </span>
                      </a>
                      <h4 className="text-secondary text-uppercase">{item?.article.name}</h4>
                      <div className="d-flex align-items-center justify-content-between price">
                        <h5 className="text-purple">{item?.article.unmed?.name}</h5>
                        <h4 className="text-primary fw-bold">{formatearMoneda(item?.article.salePrice)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {!currentPackage && showPackages && currentMenu.packages.map((item) => (
                <div
                  key={item?.package.id}
                  className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12"
                >
                  <div
                    id={item.package?.name}
                    className="pos-products"
                  >
                    <div
                      className="product-info card"
                      style={{ minHeight: 280 }}
                      onClick={() => onShowCategories(item.package)}
                    >
                      <a className="img-bg">
                        <ImageWithBasePath
                          src={item.package?.imagePath ? item.package?.imagePath : "assets/img/package/default.png"}
                          alt="Paquetes"
                        />
                        <span>
                          {currentPackage?.categories?.length > 0 ? <BsCheckCircleFill size={30} /> : <BsPlusCircleFill size={30} />}
                        </span>
                      </a>
                      <h4 className="text-secondary text-uppercase">{item.package?.name}</h4>
                      <span className='text-info'>
                        {item.package?.categories?.length > 0 ? `+${item.package?.categories?.length} opciones para acompañar` : ""}
                      </span>
                      <h4 className="text-primary fw-bold">{formatearMoneda(item.package?.unitPrice)}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {showPackages && currentPackage?.categories?.length > 0 &&
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <div
                    className="card"
                    style={{ height: 'calc(100% - 24px)' }}
                  >
                    <ImageWithBasePath
                      src={currentPackage?.imagePath ? currentPackage?.imagePath : "assets/img/package/default.png"}
                      alt="Paquetes"
                    />
                    <div className="d-flex flex-column justify-content-center align-items-center h-100 p-1">
                      <h4 className="text-secondary text-uppercase">{currentPackage?.name}</h4>
                      <span className='text-info'>
                        {currentPackage?.categories?.length > 0 ? `+${currentPackage?.categories?.length} opciones para acompañar` : ""}
                      </span>
                      <h4 className="text-primary fw-bold">{formatearMoneda(currentPackage?.unitPrice)}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8">
                  <div className="row">
                    <div className="col-auto">
                      <BsCheck2Circle className="text-secondary" size={30} />
                    </div>
                    <div className="col-auto">
                      <h4 className="text-secondary">Elige las opciones para acompañar</h4>
                      <p>de las siguientes categorias:</p>
                    </div>
                  </div>
                  {currentPackage?.categories.map((item) => (
                    <CategorySelector
                      key={item.id}
                      category={item.category}
                      requiredSlotQuantity={item.minQuantity}
                      optionalSlotQuantity={item.maxQuantity - item.minQuantity}
                      onValidSelection={onValidSelection}
                    />
                  ))}
                </div>
                <div className={isValidSelection() ? "col-sm-12 col-md-12 col-lg-4 mb-3" : "col-sm-12 col-md-12 col-lg-12"}>
                  <button
                    className="d-flex flex-row justify-content-center align-items-center btn btn-lg btn-cancel w-100"
                    onClick={() => onClearPackage()}
                    disabled={loading}
                  >
                    Cambiar
                    <RefreshCcw className="ms-2" size={30} />
                  </button>
                </div>
                {isValidSelection() &&
                  <div className="col-sm-12 col-md-12 col-lg-8">
                    <button
                      className="d-flex flex-row justify-content-center align-items-center btn btn-lg btn-primary w-100"
                      onClick={() => onAddPackage(currentPackage)}
                      disabled={loading}
                    >
                      Agregar paquete a la orden
                      <MdOutlineArrowCircleRight className="ms-2" size={30} />
                    </button>
                  </div>
                }
              </div>
            }
          </div>
        }
        <div className="col-sm-12 col-md-12 col-lg-4">
          <aside className="product-order-list p-2">
            <div className="card p-3 mb-3">
              {/*<h4 className="h4 text-secondary">Datos de la orden</h4>*/}
              <table>
                <tbody>
                  <tr>
                    <td>Fecha:</td>
                    <td className="text-end text-primary-emphasis fw-bold">{formatearFecha(new Date().toISOString(), fechaFormat + " hh:mm aa")}</td>
                  </tr>
                  <tr>
                    <td>Operador(a):</td>
                    <td className="text-end text-primary-emphasis fw-bold">{userDetails?.alias}</td>
                  </tr>
                  <tr>
                    <td>Susursal:</td>
                    <td className="text-end text-primary-emphasis fw-bold">{userDetails?.branch?.name}</td>
                  </tr>
                  <tr>
                    <td>Número de caja:</td>
                    <td className="text-end text-primary-emphasis fw-bold">{"Desconocido"}</td></tr>
                  <tr>
                    <td>Código de orden: </td>
                    <td className="text-end text-orange fw-bold">{code}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {showOrder &&
              <>
                <div className="row">
                  <div className="col-auto">
                    <Bs3Circle className="text-secondary" size={35} />
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    <h5 className="h4 text-secondary">Verifica tu orden</h5>
                  </div>
                </div>
                <div className="card p-3 mt-3 mb-3">
                  <h6 className="form-label">Cliente<span className="text-danger"> *</span></h6>
                  <div className="input-block d-flex align-items-center">
                    <div className="flex-grow-1 me-2">
                      <Select
                        options={customerOptions}
                        onChange={(value) => setCustomer(value)}
                        value={customer}
                        noOptionsMessage={() => noOptionsText}
                        placeholder="Elige a un cliente"
                        isDisabled={loading}
                      />
                    </div>
                    <a
                      className="btn btn-primary"
                      onClick={() => setShowCustomerModal(true)}
                    >
                      <UserPlus size={18} />
                    </a>
                  </div>
                </div>
                <div className="card p-3 mt-3 mb-3">
                  <div className="product-added m-0">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <h6 className="d-flex align-items-center mb-0">
                        Pedidos<span className="count">{orderProducts.length + orderPackages.length}</span>
                      </h6>
                      {(orderPackages.length > 0 || orderProducts.length > 0) &&
                        <a
                          className="d-flex align-items-center text-danger fw-semibold"
                          onClick={() => showCleanOrderListAlert()}
                        >
                          <span className="me-1">
                            <XCircle size={16} />
                          </span>
                          Quitar todos
                        </a>
                      }
                    </div>
                    <div className="product-wrap">
                      {orderPackages.map((item) => {
                        if (item.requiredProducts || item.optionalProducts) {
                          return (
                            <div key={item.key} className="card mb-3">
                              <div className="row product-list">
                                <div className='col-sm-12 col-md-10 col-lg-10 mt-3 mb-3 d-flex'>
                                  <div className="product-info me-2">
                                    <ImageWithBasePath
                                      className="img-bg"
                                      src={item.imagePath ? item.imagePath : "assets/img/package/default.png"}
                                      alt="Paquete"
                                    />
                                  </div>
                                  <div>
                                    <div className="badge badge-purple mb-2">Paquete</div>
                                    <h4 className="text-secondary text-uppercase fw-bold">{item.name}</h4>
                                    <p className="text-info fw-semibold">
                                      {`${formatearMoneda(item.salePrice)} x ${item.quantity} = ${formatearMoneda(item.subtotal)} `}
                                    </p>
                                  </div>
                                </div>
                                <div className="col mb-3 d-flex justify-content-center align-items-center action">
                                  <a onClick={() => onDeletePackage(item)}
                                    className="btn delete-icon"
                                  >
                                    <Trash2 size={30} />
                                  </a>
                                </div>
                                {item.requiredProducts?.map((rp) => (
                                  <div
                                    key={rp.productId}
                                    className='col-sm-12 col-md-12 col-lg-12 mb-3 d-flex'>
                                    <div className="product-info me-2">
                                      <ImageWithBasePath
                                        className="img-bg"
                                        style={{ width: 65 }}
                                        src={rp.imagePath ? rp.imagePath : "assets/img/product/default.png"}
                                      />
                                    </div>
                                    <div>
                                      <div className="badge badge-warning mb-2">Incluido</div>
                                      <h5 className="text-secondary text-uppercase fw-bold">{rp.name}</h5>
                                      <h6 className="text-purple">{rp.unit}</h6>
                                    </div>
                                  </div>
                                ))}
                                {item.optionalProducts?.map((op) => (
                                  <div
                                    key={op.productId}
                                    className='col-sm-12 col-md-12 col-lg-12 mb-3 d-flex'>
                                    <div className="product-info me-2">
                                      <ImageWithBasePath
                                        className="img-bg"
                                        style={{ width: 65 }}
                                        src={op.imagePath ? op.imagePath : "assets/img/product/default.png"}
                                      />
                                    </div>
                                    <div>
                                      <div className="badge badge-warning mb-2">Incluido</div>
                                      <h5 className="text-secondary text-uppercase fw-bold">{op.name}</h5>
                                      <h6 className="text-purple">{op.unit}</h6>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        }

                        return (
                          <div key={item.key} className="row me-0">
                            <div className="col mb-3 d-flex">
                              <div className="input-group">
                                <a onClick={() => onChangePackageQty(item.quantity + 1, item)}
                                  className="input-group-text link-info pe-auto"
                                >
                                  <PlusCircle size={30} />
                                </a>
                                <input
                                  type="text"
                                  className="form-control text-center"
                                  name="qty"
                                  onChange={() => onChangePackageQty(item.quantity, item)}
                                  value={item.quantity}
                                  readOnly
                                  style={{ fontSize: '2rem', minWidth: 70 }}
                                />
                                <a
                                  className="input-group-text link-info pe-auto"
                                  onClick={() => onChangePackageQty(item.quantity - 1, item)}
                                >
                                  <MinusCircle size={30} />
                                </a>
                              </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mb-3 d-flex product-list'>
                              <div className="product-info me-2">
                                <ImageWithBasePath
                                  className="img-bg"
                                  src={item.imagePath ? item.imagePath : "assets/img/package/default.png"}
                                  alt="Paquete"
                                />
                              </div>
                              <div>
                                <div className="badge badge-purple mb-2">Paquete</div>
                                <h4 className="text-secondary text-uppercase fw-bold">{item.name}</h4>
                                <p className="text-info fw-semibold">
                                  {`${formatearMoneda(item.salePrice)} x ${item.quantity} = ${formatearMoneda(item.subtotal)} `}
                                </p>
                              </div>
                            </div>
                            <div className="col mb-3 d-flex justify-content-center align-items-center product-list">
                              <div className="action">
                                <a
                                  onClick={() => onDeletePackage(item)}
                                  className="btn delete-icon"
                                >
                                  <Trash2 size={30} />
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {orderProducts.map((item) => (
                        <div key={item.key} className="row me-0">
                          <div className="col mb-3 d-flex">
                            <div className="input-group">
                              <a onClick={() => onChangeProductQty(item.quantity + 1, item)}
                                className="input-group-text link-info pe-auto"
                              >
                                <PlusCircle size={30} />
                              </a>
                              <input
                                type="text"
                                className="form-control text-center"
                                name="qty"
                                onChange={() => onChangeProductQty(item.quantity, item)}
                                value={item.quantity}
                                readOnly
                                style={{ fontSize: '2rem', minWidth: 70 }}
                              />
                              <a
                                className="input-group-text link-info pe-auto"
                                onClick={() => onChangeProductQty(item.quantity - 1, item)}
                              >
                                <MinusCircle size={30} />
                              </a>
                            </div>
                          </div>
                          <div className='col-sm-12 col-md-6 col-lg-6 mb-3 d-flex product-list'>
                            <div className="product-info me-2">
                              <ImageWithBasePath
                                className="img-bg"
                                src={item.imagePath ? item.imagePath : "assets/img/product/default.png"}
                                alt="Producto"
                              />
                            </div>
                            <div>
                              {
                                item.salePrice > 0
                                  ? <div className="badge badge-dark mb-2">Individual</div>
                                  : <div className="badge badge-purple mb-2">Paquete</div>}
                              <h4 className="text-secondary text-uppercase fw-bold">{item.name}</h4>
                              <p className="text-info fw-semibold">
                                {item.salePrice > 0 ? `${formatearMoneda(item.salePrice)} x ${item.quantity} = ${formatearMoneda(item.subtotal)} ` : "Incluido"}
                              </p>
                            </div>
                          </div>
                          <div className="col mb-3 d-flex justify-content-center align-items-center product-list">
                            <div className="action">
                              <a
                                onClick={() => onDeleteProduct(item)}
                                className="btn delete-icon"
                              >
                                <Trash2 size={30} />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {
                  (orderProducts.length > 0 || orderPackages.length > 0)
                    ?
                    <div className="order-total mb-3">
                      <table className="table table-responsive table-borderless">
                        <tbody>
                          <tr>
                            <td>Subtotal</td>
                            <td className="text-end">{formatearMoneda(subtotal)}</td>
                          </tr>
                          <tr>
                            <td>IVA ({IVA > 0 ? `${IVA * 100}%` : "Incluido"})</td>
                            <td className="text-end">{formatearMoneda(subtotal * IVA)}</td>
                          </tr>
                          <tr>
                            <td className="text-dark">Total</td>
                            <td className="text-dark text-end">{formatearMoneda(total)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    :
                    <div className="block-section">
                      <div className="order-total text-center">
                        <h6>Orden vacía</h6>
                      </div>
                    </div>
                }
                {
                  (orderProducts.length > 0 || orderPackages.length > 0) &&
                  <>
                    <div className="row mb-3">
                      <div className="col-auto">
                        <Bs4Circle className="text-secondary" size={35} />
                      </div>
                      <div className="col-auto d-flex align-items-center">
                        <h5 className="h4 text-secondary">Elige un método de pago</h5>
                      </div>
                    </div>
                    <div className="payment-method card p-3 mt-3 mb-3">
                      <div className="row d-flex align-items-center justify-content-center methods">
                        {paymentMethodPos.map((card) => (
                          <div
                            key={card.value}
                            className="col-sm-12 col-md-6 col-lg-6 mb-3"
                            onClick={() => handleCardClick(card)}
                          >
                            <div className={`default-cover ${paymentMethod?.value === card.value ? 'selected' : ''}`}>
                              <a
                              >
                                <ImageWithBasePath
                                  style={{ backgroundColor: card.backgroundColor, padding: '5px' }}
                                  src={card.image}
                                  alt="Payment Method"
                                />
                                <span className="text-info fw-bold">{card.label}</span>
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/*paymentMethod &&
                      <div className="d-grid btn-block">
                        <div className="btn btn-primary" onClick={handlePayment}>
                          Proceder con el pago de: {formatearMoneda(total)}
                        </div>
                      </div>
                    */}
                  </>
                }
              </>
            }
          </aside>
        </div>
      </div>
      {
        showCashModal &&
        <CashModal id="cash-modal" show={showCashModal} onHide={() => setShowCashModal(false)} method={paymentMethod} totalAmount={total} onSubmit={onSaveSale} />
      }
      {
        showMercadoPagoModal &&
        <MercadoPagoModal id="mercadopago-modal" show={showMercadoPagoModal} onHide={() => setShowMercadoPagoModal(false)} totalAmount={total} onSubmit={onSaveSale} />
      }
      {
        showUserSalesModal && userDetails &&
        <UserSalesModal id="user-sales-modal" show={showUserSalesModal} onHide={() => setShowUserSalesModal(false)} />
      }
      {
        showCashOutflowModal &&
        <CashOutflowModal id="cash-outflow-modal" show={showCashOutflowModal} onHide={() => setShowCashOutflowModal(false)} />
      }
      {
        showCustomerModal &&
        <AddCustomerModal id="customer-modal" show={showCustomerModal} onHide={onHideCustomerModal} />
      }
      {
        showUserCashBreakModal && userDetails &&
        <UserCashBreakModal id="user-cash-break-modal" show={showUserCashBreakModal} onHide={() => setShowUserCashBreakModal(false)} />
      }
      {/* Payment Completed */}
      <div
        className="modal fade modal-default"
        id="payment-completed"
        aria-labelledby="payment-completed"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <form>
                <div className="icon-head">
                  <a>
                    <CheckCircle className="feather-40" />
                  </a>
                </div>
                <h4>Payment Completed</h4>
                <p className="mb-0">
                  Do you want to Print Receipt for the Completed Order
                </p>
                <div className="modal-footer d-sm-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-primary flex-fill me-1"
                    data-bs-toggle="modal"
                    data-bs-target="#print-receipt"
                  >
                    Print Receipt
                  </button>
                  <a className="btn btn-secondary flex-fill">
                    Next Order
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        theme='colored'
      />
    </div>
  )
}

export default Pos