import { axiosClientApi } from '../../axiosClientConfiguration';
import { postRequest } from '../../axiosFunctions';
import CryptoJS from 'crypto-js';

const abortController = new AbortController();

export const baseConfig = {
  signal: abortController.signal,
  headers: {
    "Accept": 'application/json',
  }
};

export const setToken = (token) => {
  let config = baseConfig;
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
}

export function decryptAES256(encryptedText) {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedText, process.env.REACT_APP_AXIOS_KEY);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  } catch (e) {
    console.error("Error al descifrar:", e);
    return null;
  }
}

function encryptAES256(plainText) {
  try {
    const encrypted = CryptoJS.AES.encrypt(plainText, process.env.REACT_APP_AXIOS_KEY).toString();
    return encrypted;
  } catch (e) {
    console.error("Error al cifrar:", e);
    return null;
  }
}

const requestData = {
  user: undefined,
  pass: undefined
}

const responseData = {
  data: undefined,
  message: undefined,
}

const postLogin = (data) => {
  return postRequest(
    axiosClientApi,
    "/Authentication/Login",
    data,
    baseConfig
  );
}

const postRefresh = (data) => {
  return postRequest(
    axiosClientApi,
    "/Authentication/Refresh",
    data,
    baseConfig
  );
}

const saveToken = (token) => {
  try {
    localStorage.setItem('token', encryptAES256(token));
  } catch (e) {
    console.error("Error al cifrar:", e);
  }
}

const savePermissions = (rolePermissions) => {
  try {
    localStorage.setItem('rolePermissions', encryptAES256(JSON.stringify(rolePermissions)));
  } catch (e) {
    console.error("Error al cifrar:", e);
  }
}

export const Authentication = {
  requestData,
  responseData,
  saveToken,
  savePermissions,
  postLogin,
  postRefresh
};