import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { authorizedRoutes, posRoutes, publicRoutes, userRoutes } from "./router.link";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../feature-module/loader/loader";
import Error404 from "../feature-module/pages/errorpages/error404";
import Signin from "../feature-module/pages/login/signin";
//import Dashboard from "../feature-module/dashboard/Dashboard";
import { fetchPermissions, loadUserInfo, removePermissions, removeToken, setToken } from "../core/redux/action";
import { jwtDecode } from "jwt-decode";
import { all_routes } from "./all_routes";
import { Authentication } from "../services/internal/authentication";
import RemoteReponseToast from "../feature-module/components/remoteResponseToast";
import HeaderPos from "../InitialPage/Sidebar/HeaderPos";
import StoreList from "../feature-module/people/storelist";
import { checkModule } from "./authorization";

const AllRoutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.toggle_header);
  const token = useSelector((state) => state.token);
  const rolePermissions = useSelector((state) => state.rolePermissions);

  useEffect(() => {
    if (token) {
      validateToken();
    }
  }, [token, rolePermissions, navigate]);

  const validateToken = async () => {
    let userData = jwtDecode(token);

    /*console.log(new Date(userData.exp * 1000))
    console.log(new Date())*/
    if (new Date().getTime() >= (userData.exp * 1000)) {

      try {
        const response = await Authentication.postRefresh({ token: token });
        if (response.data.success) {
          let userInfo = jwtDecode(token);
          Authentication.saveToken(response.data.data.token);
          Authentication.savePermissions(response.data.data.rolePermisions);
          dispatch(setToken(response.data.data.token));
          dispatch(fetchPermissions(response.data.data.rolePermisions));
          dispatch(loadUserInfo(userInfo));
        } else {
          dispatch(removeToken());
          dispatch(setToken(null));
          dispatch(fetchPermissions([]));
          dispatch(removePermissions());
          navigate(all_routes.home);
        }
      }
      catch (err) {
        console.log(err);
        dispatch(removeToken());
        dispatch(setToken(null));
        dispatch(removePermissions());
        dispatch(fetchPermissions([]));
        navigate(all_routes.home);
      }
    } else {
      dispatch(loadUserInfo(userData));
    }
  }

  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      <Header />
      <Sidebar />
      <Outlet />
      <Loader />
      <RemoteReponseToast />
    </div>
  );

  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      <Loader />
      <RemoteReponseToast />
    </div>
  );

  const Pospages = () => (
    <div>
      <HeaderPos />
      <Outlet />
      <Loader />
      <RemoteReponseToast />
    </div>
  );

  return (
    <Routes>
      <Route path={"/"} element={token ? <HeaderLayout /> : <Authpages />}>
        {
          token
            ? <Route index element={<StoreList />} key={1000} />
            : <Route index element={<Signin />} key={1000} />
        }
        {
          publicRoutes.map((route) => (
            <Route path={route.path} element={route.element} key={route.name} />
          ))
        }
        {
          token
            ? userRoutes.map((route) => (
              <Route path={route.path} element={route.element} key={route.name} />
            ))
            : null
        }
        {
          token
            ? authorizedRoutes.map((route) => (
              checkModule(rolePermissions, route.module, route.function) ?
                <Route path={route.path} element={route.element} key={route.name} />
                :
                null
            ))
            : null
        }
      </Route>
      {
        token
          ?
          <Route path={"/"} element={<Pospages />}>
            {posRoutes.map((route) => (
              checkModule(rolePermissions, route.module, route.function) ?
                <Route path={route.path} element={route.element} key={route.name} />
                : null
            ))}
          </Route>
          : null
      }
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};
export default AllRoutes;
