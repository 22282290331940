import initialState from "./initial.value";

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DATA_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        result: "OK"
      };
    case 'FETCH_DATA_FAILURE':
      return {
        ...state,
        loading: false,
        errorData: action.payload,
        result: "ERROR"
      };
    case 'FETCH_DATA_ERRORS':
      return {
        ...state,
        serverErrors: action.payload
      };
    case 'SHOW_REMOTE_RESPONSE_TOAST':
      return {
        ...state,
        showRemoteResponseToast: action.payload
      };
    case 'LOAD_TOKEN':
      return {
        ...state,
        token: action.payload
      };
    case 'SAVE_TOKEN':
      return {
        ...state,
        token: action.payload
      };
    case 'REMOVE_TOKEN':
      return {
        ...state,
        token: undefined
      };
    case 'LOAD_USERINFO':
      return {
        ...state,
        userInfo: action.payload
      };
    case 'CATEGORY_LIST':
      return {
        ...state,
        categoryList: action.payload
      };
    case 'UNIT_LIST':
      return {
        ...state,
        unitList: action.payload
      };
    case 'STORE_LIST':
      return {
        ...state,
        storeList: action.payload
      };
    case 'SHIFT_LIST':
      return {
        ...state,
        shiftList: action.payload
      };
    case 'ARTICLE_LIST':
      return {
        ...state,
        articleList: action.payload
      };
    case 'CUSTOMER_LIST':
      return {
        ...state,
        customerList: action.payload
      };
    case 'EXPIRED_TYPES':
      return {
        ...state,
        expiredTypes: action.payload
      };
    case 'PROVIDER_LIST':
      return {
        ...state,
        providerList: action.payload
      };
    case 'STATE_LIST':
      return {
        ...state,
        stateList: action.payload
      };
    case 'DEPARTMENT_LIST':
      return {
        ...state,
        departmentList: action.payload
      };
    case 'USER_LIST':
      return {
        ...state,
        userList: action.payload
      };
    case 'DESIGNATION_LIST':
      return {
        ...state,
        designationList: action.payload
      };
    case 'ROLE_LIST':
      return {
        ...state,
        roleList: action.payload
      };
    case 'LEAVETYPE_LIST':
      return {
        ...state,
        leaveTypeList: action.payload
      };
    case 'HOLIDAY_LIST':
      return {
        ...state,
        holidayList: action.payload
      };
    case 'USER_DETAILS':
      return {
        ...state,
        userDetails: action.payload
      };
    case 'USER_DETAILS_IMAGEPATH':
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          imagePath: action.payload
        }
      };
    case 'PURCHASE_LIST':
      return {
        ...state,
        purchaseList: action.payload
      };
    case 'PURCHASE_PRODUCT_LIST':
      return {
        ...state,
        purchaseProductList: action.payload
      };
    case 'SET_NOTES':
      return {
        ...state,
        notes: action.payload
      };
    case 'SET_DEPARTMENT_OPTIONS':
      return {
        ...state,
        departmentOptions: action.payload
      };
    case 'SET_ARTICLE_OPTIONS':
      return {
        ...state,
        articleOptions: action.payload
      };
    case 'SET_STORE_OPTIONS':
      return {
        ...state,
        storeOptions: action.payload
      };
    case 'SET_CATEGORY_OPTIONS':
      return {
        ...state,
        categoryOptions: action.payload
      };
    case 'SET_CUSTOMER_OPTIONS':
      return {
        ...state,
        customerOptions: action.payload
      };
    case 'SET_DESIGNATION_OPTIONS':
      return {
        ...state,
        designationOptions: action.payload
      };
    case 'SET_EXPIREDTYPE_OPTIONS':
      return {
        ...state,
        expiredTypeOptions: action.payload
      };
    case 'SET_HOLIDAY_OPTIONS':
      return {
        ...state,
        holidayOptions: action.payload
      };
    case 'SET_LEAVETYPE_OPTIONS':
      return {
        ...state,
        leaveTypeOptions: action.payload
      };
    case 'SET_PROVIDER_OPTIONS':
      return {
        ...state,
        providerOptions: action.payload
      };
    case 'SET_ROLE_OPTIONS':
      return {
        ...state,
        roleOptions: action.payload
      };
    case 'SET_STATE_OPTIONS':
      return {
        ...state,
        stateOptions: action.payload
      };
    case 'SET_STATUS_OPTIONS':
      return {
        ...state,
        statusOptions: action.payload
      };
    case 'SET_UNIT_OPTIONS':
      return {
        ...state,
        unitOptions: action.payload
      };
    case 'SET_SHIFT_OPTIONS':
      return {
        ...state,
        shiftOptions: action.payload
      };
    case 'SET_CONCEPT_OPTIONS':
      return {
        ...state,
        conceptOptions: action.payload
      };
    case 'SET_PAYMENT_OPTIONS':
      return {
        ...state,
        paymentOptions: action.payload
      };
    case 'STOCK_LIST':
      return {
        ...state,
        stockList: action.payload
      };
    case 'STOCK_OPERATION_LIST':
      return {
        ...state,
        stockOperationList: action.payload
      };
    case 'TRANSFER_LIST':
      return {
        ...state,
        transferList: action.payload
      };
    case 'SAS_TOKEN':
      return {
        ...state,
        sasToken: action.payload
      };
    case 'RECIPE_BOOK_LIST':
      return {
        ...state,
        recipeBookList: action.payload
      };
    case 'RECIPE_LIST':
      return {
        ...state,
        recipeList: action.payload
      };
    case 'PACKAGE_LIST':
      return {
        ...state,
        packageList: action.payload
      };
    case 'MENU_LIST':
      return {
        ...state,
        menuList: action.payload
      };
    case 'SALE_LIST':
      return {
        ...state,
        saleList: action.payload
      };
    case 'VACATION_LIST':
      return {
        ...state,
        vacationList: action.payload
      };
    case 'CONCEPT_LIST':
      return {
        ...state,
        conceptList: action.payload
      };
    case 'CASHBREAK_LIST':
      return {
        ...state,
        cashBreakList: action.payload
      };
    case 'FETCH_PERMISSIONS':
      return {
        ...state,
        rolePermissions: action.payload
      };
    case 'LOAD_PERMISSIONS':
      return {
        ...state,
        rolePermissions: action.payload
      };
    case 'REMOVE_PERMISSIONS':
      return {
        ...state,
        rolePermissions: []
      };
    case "toggle_header":
      return { ...state, toggle_header: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
