import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Edit, Trash2, HelpCircle, MapPin, PlusCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AddStore from "../../core/modals/peoples/addstore";
import ToolsHead from "../components/toolsHead";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast, setStoreList } from "../../core/redux/action";
import { Branches } from "../../services/internal/branches";
import { showInternalErrorAlert, showSuccessAlert } from "../components/customAlert";
import { Functions, Modules, verifyPermission } from "../../Router/authorization";

const StoreList = () => {
  const dataSource = useSelector((state) => state.storeList);
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const rolePermissions = useSelector((state) => state.rolePermissions);
  const [filterName, setFilterName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const onChangeName = (e) => {
    setFilterName(e.target.value.trim())
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadBranches = async () => {

    let filters = {};
    filters.enabled = true;
    if (filterName) {
      filters.name = filterName;
    }

    try {
      dispatch(fetchDataRequest());
      const response = await Branches.getList(token, filters);
      //console.log(response)
      dispatch(setStoreList(response.data.data));
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onDeleteItem = async (id) => {
    try {
      dispatch(fetchDataRequest());
      const response = await Branches.deleteSingle(token, id);
      //console.log(response)
      dispatch(fetchDataSuccess(response.data.success));
      showSuccessAlert("Hemos eliminado la sucursal.");
    }
    catch (err) {
      handleException(err);
    }
    await onLoadBranches();
  }

  const showDeleteItemAlert = async (index, text) => {
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      customClass: {
        confirmButton: "btn btn-warning",
      },
    });

    if (result.isConfirmed) {
      await onDeleteItem(index.id);
    } else {
      MySwal.close();
    }
  };

  const onNewItem = () => {
    setCurrentId(null);
    setNewItem(true);
    setShowModal(true);
  }

  const onEditItem = (index) => {
    setCurrentId(index.id);
    setNewItem(false);
    setShowModal(true);
  }

  const onHideModal = async (refresh) => {
    setCurrentId(null);
    setShowModal(false);
    if (refresh) {
      await onLoadBranches();
    }
  }

  useEffect(() => {
    if (dataSource.length === 0) {
      onLoadBranches();
    }
  }, []);

  const columns = [
    {
      title: "Sucursal",
      dataIndex: "name",
      render: (text) => (
        <span className="text-secondary fw-semibold">{text}</span>
      ),
      sorter: (a, b) => a.name?.localeCompare(b.name)
    },
    {
      title: "Código de sucursal",
      dataIndex: "code",
      align: "center",
      render: (text) => (
        <span className="text-orange">{text}</span>
      ),
      sorter: (a, b) => a.code?.localeCompare(b.code)
    },
    {
      title: "Correo electrónico",
      dataIndex: "email",
      align: "center",
      sorter: (a, b) => a.email?.localeCompare(b.email)
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      align: "center",
      sorter: (a, b) => a.phone?.localeCompare(b.phone)
    },
    {
      title: "Ubicación",
      align: "center",
      dataIndex: "location",
      render: (text, record) => (
        record.location &&
        <Link
          className="me-2 p-2 link-primary"
          to={record.location}
          target="_blank"
        >
          <MapPin /> Ubicación
        </Link>
      ),
    },
    {
      title: "Acción",
      align: "center",
      dataIndex: "action",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {verifyPermission(rolePermissions, Modules.Sucursales, Functions.Modificar) &&
              <a className="me-2 p-2" onClick={() => onEditItem(record)}>
                <Edit className="feather-edit" />
              </a>
            }
            {verifyPermission(rolePermissions, Modules.Sucursales, Functions.Eliminar) &&
              <a className="confirm-text p-2" onClick={() => showDeleteItemAlert(record, "la sucursal")} >
                <Trash2 className="feather-trash-2" />
              </a>
            }
          </div>
        </div>
      )
    },
  ];


  const renderSearchTooltip = (props) => (
    <Tooltip id="search-tooltip" {...props}>
      Ingresa el nombre de la sucursal
    </Tooltip>
  );

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Sucursales</h4>
              <h6>Puedes gestionar la información de las sucursales del sistema.</h6>
            </div>
          </div>
          <ToolsHead
            showExportData={true}
            showExcel={verifyPermission(rolePermissions, Modules.Sucursales, Functions.Exportar)}
            onReload={onLoadBranches}
          />
          {
            verifyPermission(rolePermissions, Modules.Sucursales, Functions.Registrar) &&
            <div className="page-btn">
              <a
                className="btn btn-added"
                onClick={onNewItem}
              >
                <PlusCircle className="me-2 iconsize" />
                Nueva Sucursal
              </a>
            </div>
          }
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Búsqueda rápida"
                    className="form-control form-control-sm formsearch"
                    onChange={e => onChangeName(e)}
                    onBlur={onLoadBranches}
                    disabled={loading}
                  />
                  <ul className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </ul>
                </div>
                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                  <a className="link-primary">
                    <HelpCircle />
                  </a>
                </OverlayTrigger>
              </div>
            </div>
            <div className="table-responsive">
              <Table
                className="table"
                rowKey={(record) => record?.id}
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                size="small"
                pagination={
                  {
                    position: ["topRight"],
                    total: dataSource.length,
                    showTotal: (total) => `Total: ${total}`,
                    showSizeChanger: true
                  }
                }
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      {newItem
        ?
        showModal &&
        <AddStore id="add-store" show={showModal} onHide={onHideModal} isNew={true} />
        :
        showModal &&
        <AddStore id="edit-store" show={showModal} onHide={onHideModal} currentId={currentId} />

      }
    </div>
  );
};

export default StoreList;
