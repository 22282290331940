import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { PlusCircle, ArrowLeft } from 'react-feather';
import { Switch, Table } from 'antd';
import ToolsHead from '../components/toolsHead';
import AddCatalog from '../../core/modals/inventory/addcatalog';
import { all_routes } from '../../Router/all_routes';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setRoleOptions, setShowRemoteResponseToast } from '../../core/redux/action';
import { showInternalErrorAlert } from '../components/customAlert';
import { useDispatch, useSelector } from 'react-redux';
import { Catalogs } from '../../services/internal/catalogs';
import { noOptionsText } from '../../core/utils';
import Select from "react-select";
import { Roles } from '../../services/internal/roles';
import { Functions, Modules, verifyPermission } from '../../Router/authorization';

const Permissions = () => {
    const roleOptions = useSelector((state) => state.roleOptions);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const [dataSource, setDataSource] = useState([]);
    const [modules, setModules] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentRole, setCurrentRole] = useState(null);

    const [searchParams] = useSearchParams();
    const paramRoleId = searchParams.get('code');
    const route = all_routes

    /*const data = [
        {
            key: 1,
            role: "Administrador",
            children: [
                {
                    key: 2,
                    module: "Dashboard",
                    ver: {
                        key: 6,
                        value: false,
                        enabled: false,
                    },
                    todos: {
                        key: 7,
                        value: true,
                        enabled: false,
                    },
                },
                {
                    key: 8,
                    module: "Productos",
                    crear: {
                        key: 9,
                        value: true,
                        enabled: false,
                    },
                    editar: {
                        key: 10,
                        value: true,
                        enabled: false,
                    },
                    borrar: {
                        key: 11,
                        value: true,
                        enabled: false,
                    },
                    ver: {
                        key: 12,
                        value: true,
                        enabled: false,
                    },
                    todos: {
                        key: 13,
                        value: false,
                        enabled: false,
                    },
                }
            ],
        },
        {
            key: 14,
            role: "Auxiliar Operativo",
            children: [
                {
                    key: 15,
                    module: "Dashboard",
                    ver: {
                        key: 19,
                        value: true,
                        enabled: false,
                    },
                    todos: {
                        key: 20,
                        value: false,
                        enabled: false,
                    },
                },
                {
                    key: 21,
                    module: "Productos",
                    crear: {
                        key: 22,
                        value: true,
                        enabled: false,
                    },
                    editar: {
                        key: 23,
                        value: true,
                        enabled: false,
                    },
                    borrar: {
                        key: 24,
                        value: false,
                        enabled: false,
                    },
                    ver: {
                        key: 25,
                        value: false,
                        enabled: false,
                    },
                    todos: {
                        key: 26,
                        value: false,
                        enabled: false,
                    },
                }
            ],
        },
    ];*/

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadOptions = async () => {
        try {
            dispatch(fetchDataRequest());
            let rOptions = [];
            if (roleOptions.length === 0) {
                const response = await Catalogs.getOptions(token, Catalogs.Name.Roles);
                dispatch(setRoleOptions(response.data.data));
                rOptions = response.data.data;
            } else {
                rOptions = roleOptions;
            }


            const response = await Roles.getListDetailed(token);
            checkOptions(rOptions, response.data.data);
            setDataSource(response.data.data);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const checkOptions = (options, roles) => {
        if (!isNaN(Number(paramRoleId))) {
            let option = options.find((f) => f.value === Number(paramRoleId));
            if (option) {
                setCurrentRole(option);
                setModules(roles.find((f) => f.name === option.label)?.modules);
            }
        }
    }

    const onChangeRole = (value) => {
        setCurrentRole(value);
        if (value) {
            setModules(dataSource.find((f) => f.name === value.label)?.modules);
        }
    }

    const onChange = (value, type, record) => {
        if (type === "todos" && record) {
            dataSource?.map(i => {
                i?.children?.map(j => {
                    //console.log(j)
                    if (j.todos.key === record.key) {
                        if (value) {
                            if (j.crear) {
                                j.crear.value = true;
                                j.crear.enabled = true;
                            }
                            if (j.editar) {
                                j.editar.value = true;
                                j.editar.enabled = true;
                            }
                            if (j.borrar) {
                                j.borrar.value = true;
                                j.borrar.enabled = true;
                            }
                            if (j.ver) {
                                j.ver.value = true;
                                j.ver.enabled = true;
                            }
                        } else {
                            if (j.crear) {
                                j.crear.value = false;
                                j.crear.enabled = false;
                            }
                            if (j.editar) {
                                j.editar.value = false;
                                j.editar.enabled = false;
                            }
                            if (j.borrar) {
                                j.borrar.value = false;
                                j.borrar.enabled = false;
                            }
                            if (j.ver) {
                                j.ver.value = false;
                                j.ver.enabled = false;
                            }
                        }

                        j.todos.value = value;
                        return;
                    }
                });
            });
        } else if (record) {
            dataSource?.map(i => {
                i.children?.map(j => {
                    //console.log(j)
                    if (j.crear && j.crear.key === record.key) {
                        j.crear.value = value;
                        return;
                    }
                    if (j.editar && j.editar.key === record.key) {
                        j.editar.value = value;
                        return;
                    }
                    if (j.borrar && j.borrar.key === record.key) {
                        j.borrar.value = value;
                        return;
                    }
                    if (j.ver && j.ver.key === record.key) {
                        j.ver.value = value;
                        return;
                    }
                });
            });
        }


        setDataSource(prevDataSource => [...prevDataSource]);
    };

    useEffect(() => {
        onLoadOptions();
    }, [paramRoleId]);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Módulo",
            dataIndex: "name",
            render: (text) => (
                <span className="text-primary fw-bold">{text}</span>
            )
        },
        {
            title: "Permiso",
            children: [
                {
                    title: "Producir",
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === "Producir") ? (
                        <Switch value={record.value} onChange={(value) => onChange(value, 'Producir', record)} disabled={record.enabled} />
                    ) : null,
                },
                {
                    title: "Transferir",
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === "Transferir") ? (
                        <Switch value={record.value} onChange={(value) => onChange(value, 'Transferir', record)} disabled={record.enabled} />
                    ) : null,
                },
                {
                    title: "Aprobar",
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === "Aprobar") ? (
                        <Switch value={record.value} onChange={(value) => onChange(value, 'Aprobar', record)} disabled={record.enabled} />
                    ) : null,
                },
                {
                    title: "Copiar",
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === "Copiar") ? (
                        <Switch value={record.value} onChange={(value) => onChange(value, 'Copiar', record)} disabled={record.enabled} />
                    ) : null,
                },
                {
                    title: "Importar",
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === "Importar") ? (
                        <Switch value={record.value} onChange={(value) => onChange(value, 'Importar', record)} disabled={record.enabled} />
                    ) : null,
                },
                {
                    title: "Registrar",
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === "Registrar") ? (
                        <Switch value={record.value} onChange={(value) => onChange(value, 'Registrar', record)} disabled={record.enabled} />
                    ) : null,
                },
                {
                    title: "Modificar",
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === "Modificar") ? (
                        <Switch value={record.value} onChange={(value) => onChange(value, 'Modificar', record)} disabled={record.enabled} />
                    ) : null,
                },
                {
                    title: "Eliminar",
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === "Eliminar") ? (
                        <Switch value={record.value} onChange={(value) => onChange(value, 'Eliminar', record)} disabled={record.enabled} />
                    ) : null,
                },
                {
                    title: "Ver",
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === "Ver") ? (
                        <Switch value={record.value} onChange={(value) => onChange(value, 'Ver', record)} disabled={record.enabled} />
                    ) : null,
                },
                {
                    title: "Exportar",
                    align: "center",
                    render: (record) => record?.functions.find((f) => f.name === "Exportar") ? (
                        <Switch value={record.value} onChange={(value) => onChange(value, 'Exportar', record)} disabled={record.enabled} />
                    ) : null,
                },
                {
                    title: "Todos",
                    align: "center",
                    render: (record) => record?.functions.length > 0 ? (
                        <Switch value={record.value} onChange={(value) => onChange(value, 'Todos', record)} disabled={record.enabled} />
                    ) : null,
                },
            ],
        },

    ];

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>Administración de Permisos</h4>
                                <h6>Puedes administrar las funcionalidades permitidas para cada rol registrado en el sistema.</h6>
                            </div>
                        </div>
                        <ToolsHead
                            showExportData={false}
                            onReload={onLoadOptions}
                        />
                        {verifyPermission(rolePermissions, Modules.Roles, Functions.Ver) &&
                            <div className="page-btn">
                                <Link to={route.rolespermission} className="btn btn-added btn-secondary">
                                    <ArrowLeft className="me-2" />
                                    Lista de Roles
                                </Link>
                            </div>
                        }
                        {verifyPermission(rolePermissions, Modules.Roles, Functions.Registrar) &&
                            <div className="page-btn">
                                <a
                                    className="btn btn-added"
                                    onClick={() => setShowModal(true)}
                                >
                                    <PlusCircle className="me-2" />
                                    Nuevo Rol
                                </a>
                            </div>
                        }
                    </div>
                    <div className="card p-3">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 col-12 mb-3">
                                <label className="form-label">Elige un rol para configurar sus permisos<span className="text-danger"> *</span></label>
                                <Select
                                    options={roleOptions}
                                    placeholder="Elige un rol"
                                    value={currentRole}
                                    onChange={onChangeRole}
                                    noOptionsMessage={() => noOptionsText}
                                    isDisabled={loading}
                                    isClearable
                                />
                            </div>
                        </div>
                        {
                            currentRole &&
                            <div className="card-body">
                                <div className="table-responsive">
                                    <Table
                                        columns={columns}
                                        dataSource={modules}
                                        size="small"
                                        pagination={false}
                                        expandable={{ defaultExpandedRowKeys: [1] }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {
                showModal && <AddCatalog id="add-role" show={showModal} onHide={() => setShowModal(false)} type='role' isNew={true} />
            }
        </div>
    )
}

export default Permissions
