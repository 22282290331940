import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { useBarcode } from 'next-barcode';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from '../../core/redux/action';
import { showInternalErrorAlert } from '../components/customAlert';
import { Link, useSearchParams } from 'react-router-dom';
import ToolsHead from '../components/toolsHead';
import { fechaHoraFormat, formatearFecha, formatearMoneda, generarCodigo } from '../../core/utils';
import { Sales } from '../../services/internal/sales';
import { Table } from 'antd';
import { all_routes } from '../../Router/all_routes';
import { Eye } from 'react-feather';
import { Functions, Modules, verifyPermission } from '../../Router/authorization';

const CashBreakDetails = () => {

    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const loading = useSelector((state) => state.loading);
    const codeParam = searchParams.get('code');
    const [dataItem, setDataItem] = useState(null);
    const [distinctStatuses, setDistinctStatuses] = useState(null);
    const route = all_routes;
    const { inputRef } = useBarcode({
        value: dataItem?.code,
        options: {
            //format: "EAN13",
        }
    });

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadItem = async () => {
        try {
            let data = Sales.requestData;
            data = {};
            data.id = Number(codeParam);
            if (isNaN(data.id)) {
                return;
            }
            dispatch(fetchDataRequest());

            const response = await Sales.getBreakSingle(token, codeParam);

            setDataItem(response.data.data);

            setDistinctStatuses([...new Set(response.data.data?.sales?.map(item => item.paymentMethod?.id))]);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }


    useEffect(() => {
        if (codeParam) {
            onLoadItem();
        }
    }, [codeParam]);

    const saleColumns = [
        {
            title: "Fecha",
            defaultSortOrder: "descend",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record?.registered, fechaHoraFormat)}</span>
            ),
            sorter: (a, b) => a.registered?.localeCompare(b.registered),
        },
        {
            title: "Código de venta",
            align: "center",
            width: 80,
            render: (text, record) => (
                <Link
                    to={route.saleDetails + `?code=${record?.id}`}
                    className="text-orange fw-semibold"
                    target="_blank" rel="noopener noreferrer">
                    {record?.code}
                </Link>
            ),
            sorter: (a, b) => a.code?.localeCompare(b.uscodeer)
        },
        {
            title: "Vendedor",
            dataIndex: "userId",
            render: (text, record) => (
                <span className="text-primary fw-semibold">{record?.user?.alias}</span>
            ),
            sorter: (a, b) => a.user?.id - b.user?.id
        },
        {
            title: "Metodo de pago",
            align: "center",
            render: (text, record) => (
                <>
                    {record.paymentMethod?.id === 1 && (
                        <span className="badge badges-success">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 2 && (
                        <span className="badge badges-dark">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 3 && (
                        <span className="badge badges-info">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 4 && (
                        <span className="badge badges-orange">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 5 && (
                        <span className="badge badges-primary">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 6 && (
                        <span className="badge badges-black">{record.paymentMethod?.name}</span>
                    )}
                </>
            ),
        },
        {
            title: "Total",
            align: "right",
            render: (text, record) => (
                <span className="text-info fw-semibold">{formatearMoneda(record?.total)}</span>
            ),
            sorter: (a, b) => a.total - b.total
        },
        {
            title: "Acción",
            align: "center",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <Link
                            to={route.saleDetails + `?code=${record?.id}`}
                            target="_blank" rel="noopener noreferrer">
                            <Eye />
                        </Link>
                    </div>
                </div>
            )
        },
    ];

    const cashoutcomeColumns = [
        {
            title: "Fecha",
            defaultSortOrder: "descend",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record?.date, fechaHoraFormat)}</span>
            ),
            sorter: (a, b) => a.date?.localeCompare(b.date),
        },
        {
            title: "Vendedor",
            dataIndex: "userId",
            render: (text, record) => (
                <span className="text-primary fw-semibold">{record?.user?.alias}</span>
            ),
            sorter: (a, b) => a?.user?.alias?.localeCompare(b.user?.alias),
        },
        {
            title: "Concepto",
            align: "center",
            dataIndex: "concept",
            render: (text, record) => (
                <span className="badge badges-purple">{record?.concept?.name}</span>
            ),
            sorter: (a, b) => a.concept?.name?.localeCompare(b.concept?.name),
        },
        {
            title: "Monto",
            align: "right",
            render: (text, record) => (
                <span className="text-info fw-semibold">{formatearMoneda(record?.ammount)}</span>
            ),
            sorter: (a, b) => a?.ammount - b.ammount
        },
        {
            title: "Comentario",
            dataIndex: "comment",
            render: (text) => (
                <span>{text}</span>
            ),
        },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="page-title">
                        <h4>Ficha de Corte de Caja</h4>
                        <h6>Información completa del corte de caja.</h6>
                    </div>
                    <ToolsHead
                        showExportData={verifyPermission(rolePermissions, Modules.CortesCaja, Functions.Exportar)}
                        showPrint={true}
                        onReload={onLoadItem}
                    />
                </div>
                {/* /add */}
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                                    <div className="bar-code-view">
                                        <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                                    <div className="bar-code-view">
                                        {loading &&
                                            <div id="global-loader" style={{ position: 'relative', height: 80 }}>
                                                <div className="whirly-loader"></div>
                                            </div>
                                        }
                                        <svg ref={inputRef} />
                                    </div>
                                </div>
                            </div>
                            <div className="productdetails mb-4">
                                <ul className="product-bar">
                                    <li>
                                        <h4>Código</h4>
                                        <h6 className="text-orange fw-semibold">{dataItem?.code}</h6>
                                    </li>
                                    <li>
                                        <h4>Realizado por:</h4>
                                        <h6 className="text-primary">{dataItem?.user ? dataItem?.user?.alias : "NA"}</h6>
                                    </li>
                                    <li>
                                        <h4>Sucursal</h4>
                                        <h6 className="text-secondary">{dataItem?.branch ? dataItem?.branch : "NA"}</h6>
                                    </li>
                                    <li>
                                        <h4>Fecha</h4>
                                        <h6>{dataItem?.date ? formatearFecha(dataItem?.date, fechaHoraFormat) : "NA"}</h6>
                                    </li>
                                    <li>
                                        <h4>Todas las Ventas</h4>
                                        <h6 className="text-info">{Number(dataItem?.salesNumber) + Number(dataItem?.cashSalesNumber)}</h6>
                                    </li>
                                    <li>
                                        <h4>Total en Ventas</h4>
                                        <h6 className="text-info fw-bold">{formatearMoneda(Number(dataItem?.salesTotal) + Number(dataItem?.cashTotal))}</h6>
                                    </li>
                                    <li>
                                        <h4>Ventas lógicas</h4>
                                        <h6 className="text-dark ">{dataItem?.salesNumber ? dataItem?.salesNumber : 0}</h6>
                                    </li>
                                    <li>
                                        <h4>Monto ventas Lógicas</h4>
                                        <h6 className="text-dark fw-bold">{formatearMoneda(dataItem?.salesTotal)}</h6>
                                    </li>
                                    <li>
                                        <h4>Entradas de efectivo</h4>
                                        <h6 className="text-success">{dataItem?.cashSalesNumber ? dataItem?.cashSalesNumber : 0}</h6>
                                    </li>
                                    <li>
                                        <h4>Monto efectivo</h4>
                                        <h6 className="text-success fw-bold">{formatearMoneda(dataItem?.cashTotal)}</h6>
                                    </li>
                                    <li>
                                        <h4>Salidas de efectivo</h4>
                                        <h6 className="text-warning">{dataItem?.cashOutcomesNumber ? dataItem?.cashOutcomesNumber : 0}</h6>
                                    </li>
                                    <li>
                                        <h4>Monto salidas</h4>
                                        <h6 className="text-warning fw-bold">{formatearMoneda(dataItem?.cashOutcomesTotal)}</h6>
                                    </li>
                                    <li>
                                        <h4>Estatus</h4>
                                        <h6 className="text-purple fw-semibold">{dataItem?.status?.name}</h6>
                                    </li>
                                </ul>
                            </div>
                            <div className="row">
                                {dataItem?.sales?.length > 0 &&
                                    <div className={dataItem?.cashOutcomes?.length > 0 ? "col-lg-6 col-md-6 col-sm-12" : "col-lg-12 col-md-12 col-sm-12"}>
                                        <div className="page-header mb-1">
                                            <h4>Ventas</h4>
                                        </div>
                                        {distinctStatuses.map((status) => {
                                            return (
                                                <div className="table-responsive mb-3" key={generarCodigo()}>
                                                    <Table
                                                        className="table"
                                                        rowKey={(record) => record?.id}
                                                        columns={saleColumns}
                                                        dataSource={dataItem?.sales?.filter(item => item?.paymentMethod?.id === status)}
                                                        rowHoverable={false}
                                                        pagination={false}
                                                        size="small"
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                                {dataItem?.cashOutcomes?.length > 0 &&
                                    <div className={dataItem?.sales?.length > 0 ? "col-lg-6 col-md-6 col-sm-12" : "col-lg-12 col-md-12 col-sm-12"}>
                                        <div className="page-header mb-1">
                                            <h4>Salidas de efectivo</h4>
                                        </div>
                                        <div className="table-responsive">
                                            <Table
                                                className="table"
                                                rowKey={(record) => record?.id}
                                                columns={cashoutcomeColumns}
                                                dataSource={dataItem?.cashOutcomes}
                                                rowHoverable={false}
                                                pagination={false}
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CashBreakDetails
