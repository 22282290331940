import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import {
  Edit,
  Grid,
  List,
  MoreVertical,
  PlusCircle,
  Trash2,
  HelpCircle
} from "feather-icons-react/build/IconComponents";
import * as Icon from 'react-feather';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ToolsHead from "../components/toolsHead";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../components/customPagination";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setDepartmentList, setShowRemoteResponseToast, setUserList } from "../../core/redux/action";
import { Areas } from "../../services/internal/areas";
import { Users } from "../../services/internal/users";
import { showInternalErrorAlert, showSuccessAlert } from "../components/customAlert";
import { Table } from "antd";
import AddCatalog from "../../core/modals/inventory/addcatalog";
import { all_routes } from "../../Router/all_routes";
import { calculateSum } from "../../core/utils";
import Loader from "../loader/loader";
import { Functions, Modules, verifyPermission } from "../../Router/authorization";

const DepartmentGrid = () => {

  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const rolePermissions = useSelector((state) => state.rolePermissions);
  const dataSource = useSelector((state) => state.departmentList);
  const userList = useSelector((state) => state.userList);
  const [gridActive, setGridActive] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const route = all_routes;

  const renderSearchTooltip = (props) => (
    <Tooltip id="search-tooltip" {...props}>
      Ingresa el nombre del área o su código
    </Tooltip>
  );

  const setChangeView = (e, active) => {
    e?.preventDefault();
    setGridActive(active)
  };

  const onChangeName = (e) => {
    setFilterName(e.target.value.trim())
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadDepartments = async () => {

    let filters = {};
    filters.enabled = true;
    if (filterName) {
      filters.name = filterName;
    }

    try {
      dispatch(fetchDataRequest());
      if (userList.length === 0) {
        const responseUsers = await Users.getList(token, filters);
        dispatch(setUserList(responseUsers.data.data));
      }
      //console.log(response)
      const responseAreas = await Areas.getList(token, filters);
      dispatch(setDepartmentList(responseAreas.data.data));
      dispatch(fetchDataSuccess(responseAreas.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onDeleteItem = async (id) => {
    try {
      dispatch(fetchDataRequest());
      const response = await Areas.deleteSingle(token, id);
      //console.log(response)
      dispatch(fetchDataSuccess(response.data.success));
      showSuccessAlert("Hemos eliminado el área.");
    }
    catch (err) {
      handleException(err);
    }
    await onLoadDepartments();
  }

  const showDeleteItemAlert = async (index, text) => {
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      customClass: {
        confirmButton: "btn btn-warning",
      },
    });

    if (result.isConfirmed) {
      await onDeleteItem(index.id);
    } else {
      MySwal.close();
    }
  };

  const calculateName = (index) => {
    let item = userList.find(i => i.id === index);
    return `${item.name ? item.name : ''} ${item.surname ? item.surname : ''} ${item.lastname ? item.lastname : ''}`;
  }

  const calculateImagePath = (index) => {
    let item = userList.find(i => i.id === index);
    return item?.imagePath ? item?.imagePath : "assets/img/profiles/profile.png";
  }

  const onNewItem = () => {
    setCurrentId(null);
    setNewItem(true);
    setShowModal(true);
  }

  const onEditItem = (index) => {
    setCurrentId(index.id);
    setNewItem(false);
    setShowModal(true);
  }

  const onHideModal = async (refresh) => {
    setCurrentId(null);
    setShowModal(false);
    if (refresh) {
      await onLoadDepartments();
    }
  }

  useEffect(() => {
    if (dataSource.length === 0) {
      onLoadDepartments();
    }
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id
    },
    {
      title: "Área",
      dataIndex: "name",
      render: (text) => (
        <span className="text-secondary fw-semibold">{text}</span>
      ),
      sorter: (a, b) => a.name?.localeCompare(b.name)
    },
    {
      title: "Responsable",
      dataIndex: "idResponsible",
      render: (text, record) => {
        if (record.idResponsible) {
          return <span className="productimgname">
            <Link to={`${route.profile}?code=${record.idResponsible}`} className="product-img stock-img">
              <ImageWithBasePath
                alt="avatar"
                src={calculateImagePath(record.idResponsible)}
                className="product-img"
              />
            </Link>
            <Link to={`${route.profile}?code=${record.idResponsible}`} className="text-primary fw-semibold">{calculateName(record.idResponsible)}</Link>
          </span>;
        }
        return <span className="text-primary fw-semibold">{text ? text : "Sin asignar"}</span>;
      },
      sorter: (a, b) => a.idResponsible - b.idResponsible
    },
    {
      title: "Empleados",
      dataIndex: "usersCount",
      align: "center",
      render: (text) => (
        <span className="badge badges-info">{text}</span>
      ),
      sorter: (a, b) => a.totalmembers - b.totalmembers,
    },
    {
      title: "Acción",
      align: "center",
      dataIndex: "action",
      render: (record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {verifyPermission(rolePermissions, Modules.Areas, Functions.Modificar) &&
              <a className="me-2 p-2" onClick={() => onEditItem(index)}>
                <Edit className="feather-edit" />
              </a>
            }
            {verifyPermission(rolePermissions, Modules.Areas, Functions.Eliminar) &&
              <a className="confirm-text p-2" onClick={() => showDeleteItemAlert(index, "el área")} >
                <Trash2 className="feather-trash-2" />
              </a>
            }
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Áreas</h4>
              <h6>Puedes gestionar la información de las áreas registradas en el sistema.</h6>
            </div>
          </div>
          <ToolsHead
            showExportData={verifyPermission(rolePermissions, Modules.Areas, Functions.Exportar)}
            showExcel={true}
            onReload={onLoadDepartments}
          />
          {verifyPermission(rolePermissions, Modules.Areas, Functions.Registrar) &&
            <div className="page-btn">
              <a
                className="btn btn-added"
                onClick={onNewItem}
              >
                <PlusCircle className="me-2" />
                Nueva Área
              </a>
            </div>
          }
        </div>
        {/* /product list */}
        <div className="card">
          <div className="card-body">
            <div className="table-top table-top-new">
              <div className="search-set">
                <div className="total-employees">
                  <h6>
                    <Icon.Users />
                    Total de Empleados <span>{calculateSum(dataSource, "usersCount")}</span>
                  </h6>
                </div>
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Búsqueda rápida"
                    className="form-control form-control-sm formsearch"
                    onChange={e => onChangeName(e)}
                    onBlur={onLoadDepartments}
                    disabled={loading}
                  />
                  <a className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </a>
                </div>
                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                  <a className="link-primary">
                    <HelpCircle />
                  </a>
                </OverlayTrigger>
              </div>
              <div className="search-path d-flex align-items-center search-path-new">
                <div className="d-flex">
                  <a className={!gridActive ? "btn-list active" : "btn-list"} onClick={e => setChangeView(e, false)}>
                    <List />
                  </a>
                  <a className={gridActive ? "btn-grid active" : "btn-grid"} onClick={e => setChangeView(e, true)}>
                    <Grid />
                  </a>
                </div>
              </div>
            </div>
            {gridActive
              ?
              (loading
                ? <Loader />
                : <>
                  <div className="employee-grid-widget">
                    <div className="row">
                      {dataSource.map((item) => (
                        <div key={item.id} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6">
                          <div className="employee-grid-profile">
                            <div className="profile-head">
                              <div className="dep-name">
                                <h5 className={item.enabled ? "active" : "inactive"}>{item.name}</h5>
                              </div>
                              <div className="profile-head-action">
                                <div className="dropdown profile-action">
                                  <a
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <MoreVertical />
                                  </a>
                                  <ul className="dropdown-menu">
                                    {verifyPermission(rolePermissions, Modules.Areas, Functions.Modificar) &&
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => onEditItem({ id: item.id })}
                                        >
                                          <Edit className="info-img" />
                                          Editar
                                        </a>
                                      </li>
                                    }
                                    {verifyPermission(rolePermissions, Modules.Areas, Functions.Eliminar) &&
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => showDeleteItemAlert({ id: item.id }, "el área")}
                                        >
                                          <Trash2 className="info-img" />
                                          Borrar
                                        </a>
                                      </li>
                                    }
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <Link to={item.idResponsible ? `${route.profile}?code=${item.idResponsible}` : "#"}>
                              <div className="profile-info department-profile-info">
                                <h5>Responsable</h5>
                                <div className="profile-contentimg">
                                  <ImageWithBasePath
                                    src={calculateImagePath(item.idResponsible)}
                                    alt="avatar"
                                  />
                                </div>
                                <h4 className="text-secondary">{item.idResponsible ? calculateName(item.idResponsible) : 'Sin asignar'}</h4>
                              </div>
                            </Link>
                            <ul className="team-members">
                              <li className="text-info">Empleados: {item.usersCount}</li>
                              <li>
                                <ul>
                                  {
                                    [1].map((i) =>
                                    (<li key={`avatar-${i}`}>
                                      <a>
                                        <ImageWithBasePath
                                          src={i.image ? i.image : "assets/img/profiles/profile.png"}
                                          alt="avatar"
                                        />
                                      </a>
                                    </li>)
                                    )
                                  }
                                  {
                                    item.usersCount > 5 ?
                                      <li>
                                        <a>
                                          <span>+{item.usersCount - 5}</span>
                                        </a>
                                      </li>
                                      : null
                                  }
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>
                  <CustomPagination totalNumber={dataSource.length} rowsNumber={10} onClickPage={() => { }} />
                </>
              )
              :
              <div className="table-responsive">
                <Table
                  className="table"
                  rowKey={(record) => record?.id}
                  columns={columns}
                  loading={loading}
                  dataSource={dataSource}
                  size="small"
                  pagination={
                    {
                      position: ["topRight"],
                      total: dataSource.length,
                      showTotal: (total) => `Total: ${total}`,
                      showSizeChanger: true
                    }
                  }
                />
              </div>
            }
          </div>
        </div>
      </div>
      {newItem
        ?
        showModal
          ? <AddCatalog id="add-department" show={showModal} onHide={onHideModal} type='department' isNew={true} />
          : null
        :
        showModal
          ? <AddCatalog id="edit-department" show={showModal} onHide={onHideModal} type='department' currentId={currentId} />
          : null
      }
    </div>
  );
};

export default DepartmentGrid;
