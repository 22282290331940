import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShowRemoteResponseToast } from "../../redux/action";
import { showInternalErrorAlert } from "../../../feature-module/components/customAlert";
import { fechaHoraFormat, formatearFecha, formatearMoneda } from "../../utils";
import { Sales } from "../../../services/internal/sales";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { all_routes } from "../../../Router/all_routes";
import { Eye, Printer } from "react-feather";
import Receipt from "../../../feature-module/components/receipt";
import { useReactToPrint } from "react-to-print";
import { Functions, Modules, verifyPermission } from "../../../Router/authorization";

const UserSalesModal = ({ id, show = false, onHide }) => {

    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const userDetails = useSelector((state) => state.userDetails);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const [dataSource, setDataSource] = useState([]);
    const [currentSale, setCurrentSale] = useState(null);
    const route = all_routes;

    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef });

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadSales = async () => {
        try {
            dispatch(fetchDataRequest());
            let filters = {};
            filters.saleUserId = userDetails?.id;
            filters.branchId = userDetails?.branch?.id;
            const response = await Sales.getList(token, filters);
            setDataSource(response.data.data);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadSale = async (saleId) => {
        try {
            setCurrentSale(null);
            dispatch(fetchDataRequest());
            const response = await Sales.getSingle(token, saleId);
            setCurrentSale(response.data.data);
            dispatch(fetchDataSuccess());
        }
        catch (err) {
            handleException(err);
        }
    }

    const onModalHide = () => {
        onHide();
    }

    const renderPrintAction = (saleId) => {
        if (!verifyPermission(rolePermissions, Modules.Ventas, Functions.Exportar)) {
            return;
        }

        return (
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-minus">Imprimir Recibo</Tooltip>}
            >
                <a className="me-2 p-2" onClick={() => onLoadSale(saleId)} >
                    <Printer />
                    <div className="d-none">
                        {currentSale && <Receipt innerRef={contentRef} saleData={currentSale} />}
                    </div>
                </a>
            </OverlayTrigger>
        );
    }

    useEffect(() => {
        if (currentSale) {
            reactToPrintFn();
        }
    }, [currentSale]);


    useEffect(() => {
        onLoadSales();
    }, [userDetails]);

    const columns = [
        {
            title: "Fecha y Hora",
            dataIndex: "registered",
            align: "center",
            render: (text, record) => (
                <span>{formatearFecha(record.registered ? record.registered : new Date().toISOString(), fechaHoraFormat)}</span>
            ),
            sorter: (a, b) => a.registered?.localeCompare(b.registered),
            defaultSortOrder: "descend"
        },
        {
            title: "Código de venta",
            dataIndex: "code",
            align: "center",
            render: (text, record) => (
                record.breakSaleId ?
                    <span>
                        <Link
                            to={`${route.saleDetails}?code=${record.id}`}
                            target="_blank" rel="noopener noreferrer"
                            className="text-orange">
                            {record.code}
                        </Link>
                    </span>
                    : <span className="text-orange">{record.code}</span>
            ),
            sorter: (a, b) => a.code?.localeCompare(b.code)
        },
        {
            title: "Sucursal",
            dataIndex: "branchId.name",
            render: (text, record) => (
                <span className="text-secondary fw-semibold">{record.branchId?.name}</span>
            ),
            sorter: (a, b) => a.branchId?.name.localeCompare(b.branchId?.name)
        },
        {
            title: "Cliente",
            dataIndex: "client.name",
            render: (text, record) => (
                <span className="text-primary-emphasis fw-semibold">{record.client?.name}</span>
            ),
            sorter: (a, b) => a.client?.name?.localeCompare(b.client?.name)
        },
        {
            title: "Caja",
            dataIndex: "terminal",
            align: "center",
            render: (text, record) => (
                <span>{record.terminal}</span>
            ),
            sorter: (a, b) => a?.terminal.localeCompare(b?.terminal)
        },
        {
            title: "Total",
            dataIndex: "total",
            align: "right",
            render: (text, record) => (
                record?.breakSaleId
                    ? <span className="text-info fw-bold">
                        {formatearMoneda(record.total)}
                    </span>
                    : <span className="badge badges-info">Pendiente</span>
            ),
            sorter: (a, b) => a.total - b.total
        },
        {
            title: "Método de pago",
            dataIndex: "paymentMethod.id",
            align: "center",
            render: (text, record) => (
                <>
                    {record.paymentMethod?.id === 1 && (
                        <span className="badge badges-success">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 2 && (
                        <span className="badge badges-dark">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 3 && (
                        <span className="badge badges-info">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 4 && (
                        <span className="badge badges-orange">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 5 && (
                        <span className="badge badges-primary">{record.paymentMethod?.name}</span>
                    )}
                    {record.paymentMethod?.id === 6 && (
                        <span className="badge badges-black">{record.paymentMethod?.name}</span>
                    )}
                </>
            ),
            sorter: (a, b) => a.paymentMethod?.id - b.paymentMethod?.id
        },
        {
            title: "Corte de caja",
            align: "center",
            render: (text, record) => (
                record.breakSaleId
                    ? <Link
                        className="text-blue fw-semibold"
                        to={route.cashBreakDetails + `?code=${record.breakSaleId}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        Ver corte
                    </Link>
                    : <span>Ninguno</span>
            ),
        },
        {
            title: "Acción",
            align: "center",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    {record.breakSaleId ?
                        <div className="edit-delete-action">
                            <Link
                                className="me-2 p-2"
                                to={`${route.saleDetails}?code=${record.id}`}
                                target="_blank" rel="noopener noreferrer"
                            >
                                <Eye />
                            </Link>
                            {renderPrintAction(record.id)}
                        </div>
                        : <span className="text-primary">Disponible al corte</span>
                    }
                </div>
            )
        },
    ];

    return (
        <Modal show={show} centered onHide={onModalHide} id={id} backdrop="static" keyboard={false} size="xl">
            <Modal.Header className="custom-modal-header" closeButton={!loading}>
                <Modal.Title>
                    <h4>Mis Ventas</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Te mostramos las ventas de tu sucursal asignada:</p>
                <div className="table-responsive">
                    <Table
                        className="table"
                        rowKey={(record) => record?.id}
                        columns={columns}
                        loading={loading}
                        dataSource={dataSource}
                        size="small"
                        pagination={
                            {
                                position: ["bottomCenter"],
                                total: dataSource.length,
                                showTotal: (total) => `Total de Ventas: ${total}`,
                                showSizeChanger: true
                            }
                        }
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-center'>
                {
                    loading ?
                        <button disabled={loading} className="btn btn-submit">
                            <Spinner animation="border" role="status" size="sm" />
                        </button>
                        :
                        <button
                            className="btn btn-cancel me-2"
                            onClick={(e) => onModalHide(e, false)}
                            disabled={loading}
                        >
                            Regresar
                        </button>
                }
            </Modal.Footer>
        </Modal>
    );
};

UserSalesModal.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func,
};


export default UserSalesModal;
