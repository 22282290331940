import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Edit, HelpCircle, PlusCircle, Trash2 } from 'react-feather';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import ToolsHead from '../components/toolsHead/index.jsx';
import { Checkbox, Table } from 'antd';
import AddShift from '../../core/modals/hrm/addshift.jsx';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setShiftList, setShowRemoteResponseToast } from '../../core/redux/action.jsx';
import { Workshifts } from '../../services/internal/workshifts/index.js';
import { showInternalErrorAlert, showSuccessAlert } from '../components/customAlert/index.jsx';
import { convertirHoraStringADate, horaFormat } from '../../core/utils.jsx';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import { Functions, Modules, verifyPermission } from '../../Router/authorization.jsx';

const Shift = () => {
    const dataSource = useSelector((state) => state.shiftList);
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const [filterName, setFilterName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [newItem, setNewItem] = useState(false);
    const [currentId, setCurrentId] = useState(null);

    const onChangeName = (e) => {
        setFilterName(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadShiftList = async () => {

        let filters = {};
        filters.enabled = true;
        if (filterName) {
            filters.name = filterName;
        }

        try {
            dispatch(fetchDataRequest());
            const response = await Workshifts.getList(token, filters);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShiftList(response.data.data));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onDeleteItem = async (id) => {
        try {
            dispatch(fetchDataRequest());
            const response = await Workshifts.deleteSingle(token, id);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            showSuccessAlert("Hemos eliminado el horario.");
        }
        catch (err) {
            handleException(err);
        }
        await onLoadShiftList();
    }


    const showDeleteItemAlert = async (index, text) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onDeleteItem(index.id);
        } else {
            MySwal.close();
        }
    };

    const onNewItem = () => {
        setCurrentId(null);
        setNewItem(true);
        setShowModal(true);
    }

    const onEditItem = (index) => {
        setCurrentId(index.id);
        setNewItem(false);
        setShowModal(true);
    }

    const onHideModal = async (refresh) => {
        setCurrentId(null);
        setShowModal(false);
        if (refresh) {
            await onLoadShiftList();
        }
    }

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadShiftList();
        }
    }, []);

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id
        },
        {
            title: "Horario",
            dataIndex: "name",
            render: (text) => (
                <span className="text-secondary fw-semibold">{text}</span>
            ),
            sorter: (a, b) => a.name?.localeCompare(b.name)
        },
        {
            title: "Hora de entrada",
            dataIndex: "timeIn",
            align: "center",
            render: (text, record) => (
                <span className="text-orange fw-semibold">{format(convertirHoraStringADate(record.timeIn), horaFormat, { locale: es })}</span>
            ),
        },
        {
            title: "Hora de salida",
            dataIndex: "timeOut",
            align: "center",
            render: (text, record) => (
                <span className="text-primary fw-semibold">{format(convertirHoraStringADate(record.timeOut), horaFormat, { locale: es })}</span>
            ),
        },
        {
            title: "Días activos",
            //dataIndex: "leavequota",
            align: "center",
            children: [
                {
                    title: "Lunes",
                    dataIndex: "monday",
                    align: "center",
                    render: (record) => (
                        <Checkbox checked={record} disabled />
                    ),
                },
                {
                    title: "Martes",
                    dataIndex: "tuesday",
                    align: "center",
                    render: (record) => (
                        <Checkbox checked={record} disabled />
                    ),
                },
                {
                    title: "Miércoles",
                    dataIndex: "wednesday",
                    align: "center",
                    render: (record) => (
                        <Checkbox checked={record} disabled />
                    ),
                },
                {
                    title: "Jueves",
                    dataIndex: "thursday",
                    align: "center",
                    render: (record) => (
                        <Checkbox checked={record} disabled />
                    ),
                },
                {
                    title: "Viernes",
                    dataIndex: "friday",
                    align: "center",
                    render: (record) => (
                        <Checkbox checked={record} disabled />
                    ),
                },
                {
                    title: "Sábado",
                    dataIndex: "saturday",
                    align: "center",
                    render: (record) => (
                        <Checkbox checked={record} disabled />
                    ),
                },
                {
                    title: "Domingo",
                    dataIndex: "sunday",
                    align: "center",
                    render: (record) => (
                        <Checkbox checked={record} disabled />
                    ),
                },
            ]
        },
        {
            title: "Acción",
            dataIndex: "actions",
            align: "center",
            key: "actions",
            render: (record, index) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        {verifyPermission(rolePermissions, Modules.Horarios, Functions.Modificar) &&
                            <a className="me-2 p-2" onClick={() => onEditItem(index)}>
                                <Edit className="feather-edit" />
                            </a>
                        }
                        {verifyPermission(rolePermissions, Modules.Horarios, Functions.Eliminar) &&
                            <a className="confirm-text p-2" onClick={() => showDeleteItemAlert(index, "un horario")} >
                                <Trash2 className="feather-trash-2" />
                            </a>
                        }
                    </div>
                </div>
            ),
        },
    ];

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el nombre del horario
        </Tooltip>
    );

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Horarios</h4>
                            <h6>Puedes gestionar los horarios de las sucursales.</h6>
                        </div>
                    </div>
                    <ToolsHead
                        showExportData={verifyPermission(rolePermissions, Modules.Horarios, Functions.Exportar)}
                        showExcel={true}
                        onReload={onLoadShiftList}
                    />
                    {verifyPermission(rolePermissions, Modules.Horarios, Functions.Registrar) &&
                        <div className="page-btn">
                            <a
                                className="btn btn-added"
                                onClick={onNewItem}
                            >
                                <PlusCircle className="me-2" />
                                Nuevo horario
                            </a>
                        </div>
                    }
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeName(e)}
                                        onBlur={onLoadShiftList}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table
                                className="table"
                                rowKey={(record) => record?.id}
                                columns={columns}
                                loading={loading}
                                dataSource={dataSource}
                                size="small"
                                pagination={
                                    {
                                        position: ["topRight"],
                                        total: dataSource.length,
                                        showTotal: (total) => `Total: ${total}`,
                                        showSizeChanger: true
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                {/* /product list */}
            </div>
            {newItem
                ?
                showModal
                    ? <AddShift id="add-shift" show={showModal} onHide={onHideModal} isNew={true} />
                    : null
                :
                showModal
                    ? <AddShift id="edit-shift" show={showModal} onHide={onHideModal} currentId={currentId} />
                    : null
            }
        </div>

    )
}

export default Shift;
