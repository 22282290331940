import { formatInTimeZone } from 'date-fns-tz';
import es from 'date-fns/locale/es';

export const noOptionsText = "Sin opciones";

export const convertToOptions = (data) => {
    let options = [];
    data.map((i) => {
        options.push(
            {
                label: i.name ?? i.alias,
                value: i.id
            });
    });
    return options;
}

export const formatearMoneda = (valor, showSimbol = true) => {
    let opciones = {
        minimumFractionDigits: 2, // Mínimo de 2 decimales
    };
    if (showSimbol) {
        opciones = {
            currency: "MXN",
            style: 'currency',
            minimumFractionDigits: 2, // Mínimo de 2 decimales
        };
    }

    const formatoMoneda = new Intl.NumberFormat('es-MX', opciones);
    return formatoMoneda.format(valor);
}

export const formatearFecha = (dateString, formatString) => {
    if (!dateString) {
        return "";
    }
    return formatInTimeZone(dateString, timeZone, formatString, { locale: es }).toLocaleUpperCase();
}

export const generarCodigo = () => {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let codigo = '';
    for (let i = 0; i < 12; i++) {
        const indice = Math.floor(Math.random() * caracteres.length);
        codigo += caracteres.charAt(indice);
    }
    return codigo;
}

export const calculateSum = (data, property) => {
    return data.reduce((a, i) => {
        return a + i[property];
    }, 0);
}

export const calcularDiasEntreFechas = (fechaInicio, fechaFin) => {
    const unDiaEnMilisegundos = 24 * 60 * 60 * 1000; // 1 día en milisegundos
    const diferenciaEnMilisegundos = Math.abs(fechaFin - fechaInicio);
    const cantidadDeDias = Math.ceil(diferenciaEnMilisegundos / unDiaEnMilisegundos);
    return cantidadDeDias + 1;
}

export const convertirHoraStringADate = (horaString) => {
    const fecha = new Date();
    if (horaString) {
        const [horas, minutos, segundos] = horaString.split(':').map(Number);
        fecha.setHours(horas, minutos, segundos);
    }
    return fecha;
}

export const convertirDateAHoraString = (fecha) => {
    if (!fecha) {
        return "";
    }
    const horas = fecha.getHours().toString().padStart(2, '0');
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    //const segundos = fecha.getSeconds().toString().padStart(2, '0');
    return `${horas}:${minutos}`;
}

export const obtenerExtensionDeArchivo = (ruta) => {
    const partes = ruta.split('.');
    if (partes.length > 1) {
        return partes.pop(); // Obtiene la última parte (la extensión)
    } else {
        return '';
    }
}

export const removeUrlsParams = (urls) => {
    let newUrls = [];
    urls.forEach((e) => {
        const url = new URL(e);
        newUrls.push(`${url.origin}${url.pathname}`);
    });
    return newUrls;
}

export const validateQuantity = (value) => {
    let quantity = Number(value);
    if (isNaN(quantity)) {
        quantity = 1;
    }

    if (quantity <= 0) {
        quantity = 1;
    }

    return quantity;
}

export const validateRegexQty = (qty) => {
    const regex = new RegExp(quantityRegex);

    const result = regex.test(qty);
    if (!result) {
        return 1;
    }

    return Number(qty);
}

export const getFilledSlides = (fixeSliderAmount, slides) => {
    if (!slides) {
        return [];
    }

    if (slides.length === 0) {
        return slides;
    }

    const filledSlides = [];
    for (let i = 0; i < fixeSliderAmount; i++) {
        filledSlides.push(slides[i % slides.length]);
    }
    return filledSlides;
}

export const timeZone = process.env.REACT_APP_TIME_ZONE;
export const fechaHoraFormat = "dd/MMM/yyyy hh:mm aa";
export const fechaFormat = "dd/MMM/yyyy";
export const horaFormat = "hh:mm aa";
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
export const phoneRegex = /^\d{10}$/;
export const urlRegex = /^(https):\/\/[^ "]+$/;
export const priceRegex = /^\d+(\.\d{1,2})?$/;
export const quantityRegex = /^\d+(\.\d{1,4})?$/;
export const stockRegex = /^[1-9]\d{0,3}$/;
export const operationStockRegex = /^-?[1-9]\d*$/;
export const strongPassRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$.!%#*])[A-Za-z\d@$.!%#*]{8,}$/;
export const zipRegex = /^\d{5}$/;
export const customRegex = /^[a-zA-Z0-9_-]{5,}$/;
export const IVA = 0;//0.16;

export const paymentMethodPos = [
    { value: 1, label: 'Efectivo', image: 'assets/img/delivery/efectivo.svg', className: 'btn btn-success' },
    { value: 2, label: 'Tarjeta de Crédito o Débito', image: 'assets/img/delivery/mp-color-azul_hori-izq.svg', className: 'btn btn-dark' },
    { value: 3, label: 'DiDi Food Efectivo', image: 'assets/img/delivery/didi-food-logo.svg', className: 'btn btn-info' },
    { value: 4, label: 'DiDi Food', image: 'assets/img/delivery/didi-food-logo-white.svg', backgroundColor: '#ff7d41', className: 'btn btn-orange' },
    { value: 5, label: 'Rappi', image: 'assets/img/delivery/rappi-logo.svg', className: 'btn btn-primary' },
    { value: 6, label: 'Uber Eats', image: 'assets/img/delivery/uber-eats-logo.svg', className: 'btn btn-black' },
];

export const paymentMethodSimplePos = [
    { value: 1, label: 'Efectivo', image: 'assets/img/delivery/efectivo.svg', className: 'btn btn-success' },
    { value: 2, label: 'Tarjeta de Crédito o Débito', image: 'assets/img/delivery/mp-color-azul_hori-izq.svg', className: 'btn btn-dark' },
];

export const platformOptions = [
    { label: 'Punto de venta', value: 1 },
    { label: 'App Delivery', value: 2 }
];