import { useDispatch } from "react-redux";
import { fetchPermissions, removePermissions, removeToken, setToken } from "../../../core/redux/action";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { useEffect } from "react";

const SignOut = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(removeToken());
        dispatch(setToken(null));
        dispatch(fetchPermissions([]));
        dispatch(removePermissions());
        navigate(all_routes.home)
    }, [navigate, dispatch]);

};

export default SignOut;
