import {
  Edit,
  Eye,
  Filter,
  PlusCircle,
  HelpCircle,
  Trash2,
} from "feather-icons-react/build/IconComponents";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { all_routes } from "../../Router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Check, Download, X } from "react-feather";
import ToolsHead from "../components/toolsHead";
import { Articles } from "../../services/internal/articles";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setArticleList, setCategoryOptions, setShowRemoteResponseToast, setUnitOptions } from "../../core/redux/action";
import { showInternalErrorAlert, showSuccessAlert } from "../components/customAlert";
import { Table } from "antd";
import { formatearMoneda, noOptionsText } from "../../core/utils";
import FilterControl from "../components/filterControl";
import { Catalogs } from "../../services/internal/catalogs";
import { Functions, Modules, verifyPermission } from "../../Router/authorization";

const ProductList = () => {
  const dataSource = useSelector((state) => state.articleList);
  const categoryOptions = useSelector((state) => state.categoryOptions);
  const unitOptions = useSelector((state) => state.unitOptions);
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const token = useSelector((state) => state.token);
  const rolePermissions = useSelector((state) => state.rolePermissions);
  const [filterName, setFilterName] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterUnit, setFilterUnit] = useState("");
  //const [currentId, setCurrentId] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const route = all_routes;

  const onChangeName = (e) => {
    setFilterName(e.target.value.trim())
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadArticles = async () => {

    let filters = {};
    filters.enabled = true;
    if (filterName) {
      filters.name = filterName;
    }
    if (filterCategory) {
      filters.categoryId = filterCategory.value;
    }
    if (filterUnit) {
      filters.unmedId = filterUnit.value;
    }

    try {
      dispatch(fetchDataRequest());

      if (categoryOptions.length === 0) {
        const responseC = await Catalogs.getOptions(token, Catalogs.Name.Categories);
        dispatch(setCategoryOptions(responseC.data.data));
      }
      if (unitOptions.length === 0) {
        const responseU = await Catalogs.getOptions(token, Catalogs.Name.Unmeds);
        dispatch(setUnitOptions(responseU.data.data));
      }
      const response = await Articles.getList(token, filters);
      //console.log(response)
      dispatch(setArticleList(response.data.data));
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onDeleteItem = async (id) => {
    try {
      dispatch(fetchDataRequest());
      const response = await Articles.deleteSingle(token, id);
      //console.log(response)
      dispatch(fetchDataSuccess(response.data.success));
      showSuccessAlert("Hemos eliminado el producto.");
    }
    catch (err) {
      handleException(err);
    }
    await onLoadArticles();
  }

  const showDeleteItemAlert = async (index, text) => {
    const result = await MySwal.fire({
      title: "¿Estás seguro?",
      html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
      customClass: {
        confirmButton: "btn btn-warning",
      },
    });

    if (result.isConfirmed) {
      await onDeleteItem(index.id);
    } else {
      MySwal.close();
    }
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  useEffect(() => {
    if (dataSource.length === 0) {
      onLoadArticles();
    }
  }, []);

  const columns = [
    {
      title: "Producto",
      dataIndex: "name",
      render: (text, record) => (
        <span className="productimgname">
          <Link key={record.id} to={`${route.productdetails}?code=${record.id}`} className="product-img stock-img">
            <ImageWithBasePath
              alt="producto"
              src={record.imageUrls?.length > 0
                ? record.imageUrls[0]
                : "assets/img/product/default.png"}
            />
          </Link>
          <Link to={`${route.productdetails}?code=${record.id}`} className="text-primary fw-semibold">{text}</Link>
        </span>
      ),
      sorter: (a, b) => a.name?.localeCompare(b.name)
    },
    {
      title: "Código de producto",
      dataIndex: "code",
      align: "center",
      render: (text) => (
        <span className="text-orange">{text}</span>
      ),
      sorter: (a, b) => a.code?.localeCompare(b.code)
    },
    {
      title: "Categoría",
      dataIndex: "categoryId",
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{record.category?.name}</span>
      ),
      sorter: (a, b) => a.category?.id - b.category?.id
    },
    {
      title: "Unidad de medida",
      dataIndex: "unmedId",
      align: "center",
      render: (text, record) => (
        <span>{record.unmed?.abbreviation}</span>
      ),
      sorter: (a, b) => a.unmedId - b.unmedId
    },
    {
      title: "¿Inventariado?",
      dataIndex: "inventoried",
      align: "center",
      render: (text) => (
        text === true ? <Check size={20} className="text-success" /> : <X size={20} className="text-danger" />
      ),
      sorter: (a, b) => a.inventoried - b.inventoried
    },
    {
      title: "P.U. Compra",
      dataIndex: "buyPrice",
      align: "center",
      render: (text, record) => (
        <span>{formatearMoneda(record.buyPrice)}</span>
      ),
      sorter: (a, b) => a.buyPrice - b.buyPrice
    },
    {
      title: "P.U. Venta",
      dataIndex: "salePrice",
      align: "center",
      render: (text, record) => (
        <span className="text-info fw-bold">{formatearMoneda(record.salePrice)}</span>
      ),
      sorter: (a, b) => a.salePrice - b.salePrice
    },
    {
      title: "Acción",
      align: "center",
      dataIndex: "action",
      render: (record, index) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="me-2 p-2"
              to={`${route.productdetails}?code=${index.id}`}
            >
              <Eye />
            </Link>
            {verifyPermission(rolePermissions, Modules.Productos, Functions.Modificar) &&
              <Link className="me-2 p-2" to={`${route.editproduct}?code=${index.id}`}>
                <Edit className="feather-edit" />
              </Link>
            }
            {verifyPermission(rolePermissions, Modules.Productos, Functions.Eliminar) &&
              <a className="confirm-text p-2" onClick={() => showDeleteItemAlert(index, "un producto")} >
                <Trash2 className="feather-trash-2" />
              </a>
            }
          </div>
        </div>
      )
    },
  ];

  const renderSearchTooltip = (props) => (
    <Tooltip id="search-tooltip" {...props}>
      Ingresa el nombre del producto o su código
    </Tooltip>
  );
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Lista de Productos</h4>
              <h6>Puedes gestionar la información de los productos del sistema.</h6>
            </div>
          </div>
          <ToolsHead
            showExportData={true}
            showExcel={verifyPermission(rolePermissions, Modules.Productos, Functions.Exportar)}
            onReload={onLoadArticles}
          />
          {verifyPermission(rolePermissions, Modules.Productos, Functions.Registrar) &&
            <div className="page-btn">
              <Link to={route.addproduct} className="btn btn-added">
                <PlusCircle className="me-2 iconsize" />
                Nuevo Producto
              </Link>
            </div>
          }
          {verifyPermission(rolePermissions, Modules.Productos, Functions.Importar) &&
            <div className="page-btn import">
              <a
                className="btn btn-added color"
              >
                <Download className="me-2" />
                Importar Productos
              </a>
            </div>
          }
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Búsqueda rápida"
                    className="form-control form-control-sm formsearch"
                    onChange={e => onChangeName(e)}
                    onBlur={onLoadArticles}
                    disabled={loading}
                  />
                  <a className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </a>
                </div>
                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                  <a className="link-primary">
                    <HelpCircle />
                  </a>
                </OverlayTrigger>
              </div>
              <div className="search-path">
                <div
                  className={`btn btn-filter ${isFilterVisible ? "setclose" : ""}`}
                  id="filter_search"
                  onClick={toggleFilterVisibility}
                >
                  <Filter className="filter-icon"
                  />
                  <span>
                    <X className="filter-icon" />
                  </span>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-4 col-sm-4 col-12 mb-3">
                    <Select
                      options={categoryOptions}
                      placeholder="Elige una categoría"
                      onChange={(value) => setFilterCategory(value)}
                      noOptionsMessage={() => noOptionsText}
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                      isClearable
                    //isMulti
                    />
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12 mb-3">
                    <Select
                      options={unitOptions}
                      onChange={(value) => setFilterUnit(value)}
                      noOptionsMessage={() => noOptionsText}
                      isClearable
                      placeholder="Elige una unidad de medida"
                      components={{ Control: FilterControl }}
                      isDisabled={loading}
                    />
                  </div>
                  <div className="col-lg-4 col-sm-4 col-12 mb-3">
                    <button
                      className="btn btn-outline-primary w-100"
                      onClick={() => onLoadArticles()}
                      disabled={loading}
                    >
                      <span data-feather="check" className="feather-check" />Aplicar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table"
                rowKey={(record) => record?.id}
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                size="small"
                pagination={
                  {
                    position: ["topRight"],
                    total: dataSource.length,
                    showTotal: (total) => `Total: ${total}`,
                    showSizeChanger: true
                  }
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
