import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { ArrowLeft, HelpCircle, Plus, Trash2 } from "react-feather";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Checkbox, Switch, Table } from "antd";
import AddIngredient from "../../../core/modals/recipes/addingredient";
import FormCreatedBy from "../../components/createdByForm";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setMenuList, setShowRemoteResponseToast, setStoreOptions } from "../../../core/redux/action";
import { showInternalErrorAlert } from "../../components/customAlert";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import ImageUploader from "../../components/fileUpload/ImageUpload";
import { Controller, useForm } from "react-hook-form";
import { formatearMoneda, generarCodigo, platformOptions } from "../../../core/utils";
import { Menus } from "../../../services/internal/menus";
import Select from "react-select";
import { Catalogs } from "../../../services/internal/catalogs";
import { Package } from "../../../services/internal/package";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Functions, Modules, verifyPermission } from "../../../Router/authorization";

const MenuDetail = () => {

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setValue, register, handleSubmit, control, formState: { errors } } = useForm();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const storeOptions = useSelector((state) => state.storeOptions);
    const [packageList, setPackageList] = useState([]);
    const [productsDataSource, setProductsDataSource] = useState([]);
    const [packagesDataSource, setPackagesDataSource] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [imagesPath, setImagesPath] = useState([]);
    const [showInfo, setShowInfo] = useState(false);

    const [searchParams] = useSearchParams();
    const paramMenuId = searchParams.get('code');
    const route = all_routes

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadOptions = async () => {
        let filters = {};
        filters.enabled = true;

        try {
            dispatch(fetchDataRequest());

            if (storeOptions.length === 0) {
                const response = await Catalogs.getOptions(token, Catalogs.Name.Branches);
                dispatch(setStoreOptions(response.data.data));
            }

            if (packageList.length === 0) {
                const response = await Package.getList(token, { enabled: true });
                setPackageList(response.data.data);
            }
            dispatch(fetchDataSuccess(true));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadMenu = async () => {
        try {
            dispatch(fetchDataRequest());

            let stores = [];
            let packages = [];
            if (storeOptions.length === 0) {
                const response = await Catalogs.getOptions(token, Catalogs.Name.Branches);
                dispatch(setStoreOptions(response.data.data));
                stores = response.data.data;
            } else {
                stores = storeOptions;
            }

            if (packageList.length === 0) {
                const response = await Package.getList(token, { enabled: true });
                packages = response.data.data;
            }

            const response = await Menus.getSingle(token, paramMenuId);
            setValue("name", response.data.data.name);
            setValue("description", response.data.data.description);
            setValue("platformId", platformOptions.find((f) => f.value === response.data.data.platformId));
            if (response.data.data.platformId === 1) {
                setShowInfo(true);
            }
            if (response.data.data.imagePath) {
                setImagesPath([response.data.data.imagePath]);
            }
            if (response.data.data.packages) {
                setPackageList(convertPackagesToList(response.data.data.packages, packages));
            }
            if (response.data.data.articles) {
                setProductsDataSource(convertArticlesToDataSource(response.data.data.articles));
            }
            if (response.data.data.branches) {
                setValue("branchId", convertBranchesToStoreOptions(response.data.data.branches, stores));
            }
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const convertPackagesToList = (packages, packageList) => {
        let dsPkgs = [];
        const pkgs = packageList.map((p) => {
            const value = packages.filter((p2) => p2.package?.id === p.id).length > 0
            if (value) {
                dsPkgs.push({ packageId: p.id, quantity: 1 })
            }
            return ({
                ...p,
                inMenu: value
            })
        });
        setPackagesDataSource(dsPkgs);
        return pkgs;
    }

    const convertArticlesToDataSource = (articles) => {
        let ds = []
        articles.map((a) => (
            ds.push({
                articleId: a.article?.id,
                quantity: a.quantity,
                subTotal: a.quantity * a.article?.salePrice,
                inventoried: a.article?.inventoried,
                key: generarCodigo(),
                unit: a.article?.unmed?.abbreviation,
                name: a.article?.name,
                salePrice: a.article?.salePrice,
            })));
        return ds;
    }

    const convertBranchesToStoreOptions = (branches, storeOptions) => {
        const branchesIds = branches.map((b) => (b.id));
        return storeOptions.filter((f) => branchesIds.includes((f.value)));
    }

    const onSaveItem = async (formData) => {
        try {
            let data = Menus.requestData;
            data = {};
            data.id = paramMenuId ? Number(paramMenuId) : null;
            if (isNaN(data.id)) {
                return;
            }
            data.name = formData.name;
            data.description = formData.description;
            data.platformId = formData.platformId?.value;
            data.branches = convertToServiceBranches(formData.branchId);
            data.packages = packagesDataSource;
            data.articles = productsDataSource;
            if (imagesPath?.length > 0) {
                data.imagePath = imagesPath[0];
            } else {
                data.imagePath = null;
            }
            dispatch(fetchDataRequest());

            if (paramMenuId) {
                const response = await Menus.putSingle(token, data);
                dispatch(fetchDataSuccess(response.data.success));
            } else {
                const response = await Menus.postSingle(token, data);
                dispatch(fetchDataSuccess(response.data.success));
            }
            dispatch(setMenuList([]));
            dispatch(setShowRemoteResponseToast(true));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onDeleteItem = async () => {
        try {
            dispatch(fetchDataRequest());
            const response = await Menus.deleteSingle(token, paramMenuId);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setMenuList([]));
            dispatch(setShowRemoteResponseToast(true))
            navigate(route.menugrid);
        }
        catch (err) {
            handleException(err);
        }
    }

    const convertToServiceBranches = (selectData) => {
        if (Array.isArray(selectData)) {
            return selectData?.map(i => (i.value));
        } else {
            return [selectData.value];
        }
    }

    const showDeleteItemAlert = async (text) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onDeleteItem();
        } else {
            MySwal.close();
        }
    };

    const onAddProduct = (product) => {
        if (productsDataSource.find((f) => f.productId === product?.id)) {
            dispatch(fetchDataFailure({ message: "Ya tienes este producto en la lista." }));
            dispatch(setShowRemoteResponseToast(true));
            setShowAddModal(false);
            return;
        }

        const newProduct = {
            articleId: product?.id,
            quantity: product.quantity,
            subTotal: product.quantity * product?.salePrice,
            inventoried: product?.inventoried,
            key: generarCodigo(),
            unit: product?.unmed?.abbreviation,
            name: product?.name,
            salePrice: product?.salePrice,
        };
        setShowAddModal(false);
        setProductsDataSource(prevDataSource => [...prevDataSource, newProduct]);
    };

    const onDeleteProduct = (record) => {
        setProductsDataSource(prevDataSource => [...prevDataSource.filter(f => f?.key !== record?.key)]);
    };

    const onImagesChanged = (newImages) => {
        setImagesPath(newImages);
    }

    const onChangeSwitch = (value, record) => {
        const pkgs = packageList.map((p) => ({
            ...p,
            inMenu: p.id === record.id ? value : p.inMenu
        }));

        setPackageList(pkgs);

        setPackagesDataSource(pkgs.filter((f) => f.inMenu === true).map((i) => ({ packageId: i.id, quantity: 1 })));

    }

    const onShowAddModal = (e) => {
        e?.preventDefault();
        setShowAddModal(true);
    }

    useEffect(() => {
        if (paramMenuId) {
            onLoadMenu();
        } else {
            onLoadOptions();
        }
    }, [paramMenuId]);

    const packageColumns = [
        {
            title: "En el menú",
            dataIndex: "inMenu",
            align: "center",
            width: 200,
            render: (text, record) => (
                <Switch checked={record?.inMenu} onChange={(value) => onChangeSwitch(value, record)} />
            ),
        },
        {
            title: "Paquete",
            dataIndex: "name",
            render: (text, record) => (
                <span className="productimgname">
                    <Link key={record.id} to={`${route.package}?code=${record.id}`} className="product-img stock-img">
                        <ImageWithBasePath
                            alt="paquete"
                            src={record.imagePath
                                ? record.imagePath
                                : "assets/img/package/default.png"}
                            target="_blank"
                            rel="noopener noreferrer"
                        />
                    </Link>
                    <Link
                        to={`${route.package}?code=${record.id}`}
                        className="text-primary fw-semibold"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {text}
                    </Link>
                </span>
            ),
        },
        {
            title: "Precio",
            align: "center",
            render: (text, record) => (
                <span className="text-info fw-semibold">{formatearMoneda(record.unitPrice)}</span>
            ),
        },
        {
            title: "Productos únicos",
            align: "center",
            render: (text, record) => (
                <span className="badge badges-info">{record.products?.length}</span>
            ),
        },
        {
            title: "Productos variados",
            align: "center",
            render: (text, record) => (
                <span className="badge badges-info">{record.categories?.length > 0 ? record.categories?.length : "Ninguno"}</span>
            ),
        },
    ];

    const columns = [
        {
            title: "Afecta existencias",
            align: "center",
            width: 130,
            render: (text, record) => (
                <Checkbox checked={record?.inventoried} disabled />
            ),
        },
        {
            title: "Unidad",
            dataIndex: "unit",
            align: "center",
            width: 80,
            render: (text, record) => (
                <span>{record?.unit}</span>
            ),
        },
        {
            title: "Producto",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <Link
                    to={route.productdetails + `?code=${record?.productId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary fw-semibold"
                >
                    {record?.name}
                </Link>
            ),
        },
        {
            title: "Acción",
            dataIndex: "actions",
            align: "center",
            width: 60,
            key: "actions",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <a className="confirm-text p-2" onClick={() => onDeleteProduct(record)} >
                            <Trash2 className="feather-trash-2" />
                        </a>
                    </div>
                </div>
            ),
        },

    ];

    return (
        <div className="page-wrapper">
            <form className="content" onSubmit={handleSubmit((data) => onSaveItem(data))}>
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Detalle del Menú</h4>
                            <h6>Puedes editar los paquetes y productos que conforman el menú.</h6>
                            <h6>El menú se asignará automáticamente por el sistema dependiendo la sucursal asignada al usuario que inicia sesión en el sistema.</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                        <Link to={route.menugrid} className="btn btn-secondary">
                            <ArrowLeft className="me-2" />
                            Lista de Menús
                        </Link>
                    </div>
                    {paramMenuId && verifyPermission(rolePermissions, Modules.Menus, Functions.Eliminar) &&
                        <div className="page-btn">
                            <a className="btn btn-primary" onClick={() => showDeleteItemAlert("el menú")}>
                                <Trash2 className="feather-trash-2" />
                            </a>
                        </div>
                    }
                </div>
                {/* /product list */}
                <div className="card table-list-card p-3">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <div className="row">
                                <div className="col-lg-12 col-sm-12 col-12 mb-4">
                                    <label className="form-label">Nombre<span className="text-danger"> *</span></label>
                                    <input
                                        type="text"
                                        className={!errors.name ? "form-control" : "form-control is-invalid"}
                                        {...register("name",
                                            {
                                                required: "Nombre es requerido.",
                                            }
                                        )}
                                        disabled={loading}
                                        maxLength={300}
                                    />
                                    {
                                        errors.name ?
                                            <div className="invalid-feedback">
                                                {errors.name.message}
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 col-12 mb-3">
                                <label className="form-label">Descripción</label>
                                <textarea
                                    className="form-control"
                                    rows={3}
                                    maxLength={800}
                                    placeholder="Escribe una descripción..."
                                    {...register("description",
                                        {
                                            maxLength: 800
                                        }
                                    )}
                                    disabled={loading}
                                />
                                <p>Máximo 800 caracteres</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 d-flex align-items-center">
                            <ImageUploader
                                prefix={"menu_image"}
                                containerName="imagenes"
                                imageGrouperId={`menus/${paramMenuId ? paramMenuId : generarCodigo()}`}
                                imagesPath={imagesPath}
                                onImagesChanged={onImagesChanged}
                            />
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 mb-4">
                            <label className="form-label">Plataforma <span className="text-danger">*</span></label>
                            <Controller
                                name="platformId"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Select
                                        inputRef={ref}
                                        className={errors.platformId ? "form-control is-invalid" : ""}
                                        options={platformOptions}
                                        placeholder="Elige la plataforma"
                                        value={value}
                                        onChange={(value) => { onChange(value); value?.value === 1 ? setShowInfo(true) : setShowInfo(false) }}
                                        isClearable
                                        styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                        noOptionsMessage={() => "Sin opciones"}
                                        isDisabled={loading}
                                    />
                                )}
                                rules={
                                    { required: "Requerido" }
                                }
                            />
                            {
                                errors.platformId ?
                                    <div className="invalid-feedback">
                                        {errors.platformId.message}
                                    </div>
                                    : null
                            }
                        </div>
                        {showInfo &&
                            <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                <label className="form-label">Sucursales
                                    <span className="text-danger">*   </span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-copy">Selecciona nuevamente para elegir varias</Tooltip>}
                                    >
                                        <a className="link-primary"><HelpCircle size={16} /></a>
                                    </OverlayTrigger>
                                </label>
                                <Controller
                                    name="branchId"
                                    control={control}
                                    render={({ field: { onChange, value, ref } }) => (
                                        <Select
                                            inputRef={ref}
                                            className={errors.branchId ? "form-control is-invalid" : ""}
                                            options={storeOptions}
                                            placeholder="Elige multiples sucursales"
                                            value={value}
                                            onChange={onChange}
                                            isClearable
                                            isMulti
                                            styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                            noOptionsMessage={() => "Sin opciones"}
                                            isDisabled={loading}
                                        />
                                    )}
                                    rules={
                                        { required: "Se requiere al menos una sucursal" }
                                    }
                                />
                                {
                                    errors.branchId ?
                                        <div className="invalid-feedback">
                                            {errors.branchId.message}
                                        </div>
                                        : null
                                }
                            </div>
                        }
                    </div>
                    {showInfo &&
                        <div className="col-lg-12 col-sm-12 mb-3">
                            <div
                                className="alert alert-info d-flex align-items-center"
                            >
                                <i className="feather-info flex-shrink-0 me-2" />
                                <span>
                                    Recuerda que en el <span className="fw-bold">punto de venta</span>, el menú se selecciona automáticamente
                                    tomando la sucursal a la que pertenece el usuario que ha iniciado sesión en el sistema. <span className="fw-bold"><Link to={route.employeegrid}>Puedes ir a configurarlos aquí.</Link></span>
                                </span>
                            </div>
                        </div>
                    }
                    <div className="page-header mb-1">
                        <div>
                            <h4>Paquetes</h4>
                            <p>Selecciona los paquetes a incluir en el menú</p>
                        </div>
                    </div>
                    <div className="table-responsive mb-4">
                        <Table
                            className="table"
                            rowKey={(record) => record?.id}
                            columns={packageColumns}
                            loading={loading}
                            dataSource={packageList}
                            size="small"
                            pagination={
                                {
                                    total: packageList.length,
                                    showTotal: (total) => `Paquetes: ${total}`,
                                    showSizeChanger: true,
                                    style: { margin: 0 },
                                    position: ["bottomCenter"]
                                }
                            }
                        />
                        {
                            (packagesDataSource.length <= 0 && productsDataSource.length <= 0) &&
                            <div className="col-lg-6">
                                <div className='invalid-feedback' style={{ display: 'block' }}>Se requiere al menos 1 paquete en el menú.</div>
                            </div>
                        }
                    </div>
                    <div className="page-header mb-1">
                        <div>
                            <h4>Producto individuales</h4>
                            <p>Selecciona los productos para venta individual.</p>
                        </div>
                        <div className="page-btn">
                            <button
                                className="btn btn-added"
                                onClick={(e) => onShowAddModal(e)}
                                disabled={loading}
                            >
                                <Plus className="me-2" />
                                Añadir
                            </button>
                        </div>
                    </div>
                    <div className="table-responsive mb-4">
                        <Table
                            className="table"
                            rowKey={() => generarCodigo()}
                            columns={columns}
                            loading={loading}
                            dataSource={productsDataSource}
                            size="small"
                            pagination={
                                {
                                    total: productsDataSource.length,
                                    showTotal: (total) => `Productos: ${total}`,
                                    showSizeChanger: true,
                                    style: { margin: 0 },
                                    position: ["bottomCenter"]
                                }
                            }
                        />
                        {
                            (packagesDataSource.length <= 0 && productsDataSource.length <= 0) &&
                            <div className="col-lg-6">
                                <div className='invalid-feedback' style={{ display: 'block' }}>Se requiere al menos 1 producto en el menú.</div>
                            </div>
                        }
                    </div>
                    <FormCreatedBy />
                </div>
                <div className="d-flex justify-content-center mb-4">
                    {
                        loading ?
                            <button disabled={loading} className="btn btn-submit">
                                <Spinner animation="border" role="status" size="sm" />
                            </button>
                            :
                            <input
                                type="submit"
                                disabled={(packagesDataSource.length <= 0 && productsDataSource.length <= 0)}
                                value="Guardar"
                                className="btn btn-primary"
                            />
                    }
                </div>
            </form>
            {
                showAddModal
                    ? <AddIngredient id="add-product" show={showAddModal} showQuantity={false} onAddItem={onAddProduct} onHide={() => setShowAddModal(false)} />
                    : null
            }
        </div>
    );
};

export default MenuDetail;
