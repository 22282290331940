import { setToken } from '../authentication';
import { axiosClientApi } from '../../axiosClientConfiguration';
import { getRequest } from '../../axiosFunctions';

const Name = {
  Areas: "Areas",
  Articles: "Articles",
  Branches: "Branches",
  Categories: "Categories",
  Clients: "Clients",
  Configurations: "Configurations",
  Designations: "Designations",
  ExpiredTypes: "ExpiredTypes",
  Holidays: "Holidays",
  LeaveTypes: "LeaveTypes",
  Providers: "Providers",
  Roles: "Roles",
  States: "States",
  Statuses: "Statuses",
  Unmeds: "Unmeds",
  Workshifts: "Workshifts",
  Concept: "Concept",
  PaymentMethod: "PaymentMethod",
};

const getOptions = (token, catalogName) => {
  return getRequest(
    axiosClientApi,
    `/Catalogs/${catalogName}`,
    setToken(token)
  );
}

export const Catalogs = {
  Name,
  getOptions
};
