import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddCatalog from "../../core/modals/inventory/addcatalog";
import Swal from "sweetalert2";
import { PlusCircle, HelpCircle } from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Edit, Trash2 } from "react-feather";
import ToolsHead from "../components/toolsHead";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setConceptList, setShowRemoteResponseToast } from "../../core/redux/action";
import { showInternalErrorAlert, showSuccessAlert } from "../components/customAlert";
import { Table } from "antd";
import { Concept } from "../../services/internal/concept";
import { Functions, Modules, verifyPermission } from "../../Router/authorization";

export const CashOutflowList = () => {
    const dataSource = useSelector((state) => state.conceptList);
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const [filterName, setFilterName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [newItem, setNewItem] = useState(false);
    const [currentId, setCurrentId] = useState(null);


    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el nombre de la salida
        </Tooltip>
    );

    const onChangeName = (e) => {
        setFilterName(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadConceptList = async () => {

        let filters = {};
        filters.enabled = true;
        if (filterName) {
            filters.name = filterName;
        }

        try {
            dispatch(fetchDataRequest());
            const response = await Concept.getList(token, filters);
            //console.log(response)
            dispatch(setConceptList(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onDeleteItem = async (id) => {
        try {
            dispatch(fetchDataRequest());
            const response = await Concept.deleteSingle(token, id);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            showSuccessAlert("Hemos eliminado el tipo de salida.");
        }
        catch (err) {
            handleException(err);
        }
        await onLoadConceptList();
    }

    const showDeleteItemAlert = async (index, text) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onDeleteItem(index.id);
        } else {
            MySwal.close();
        }
    };

    const onNewItem = () => {
        setCurrentId(null);
        setNewItem(true);
        setShowModal(true);
    }

    const onEditItem = (index) => {
        setCurrentId(index.id);
        setNewItem(false);
        setShowModal(true);
    }

    const onHideModal = async (refresh) => {
        setCurrentId(null);
        setShowModal(false);
        if (refresh) {
            await onLoadConceptList();
        }
    }

    useEffect(() => {
        if (dataSource.length === 0) {
            onLoadConceptList();
        }
    }, []);


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id
        },
        {
            title: "Nombre",
            dataIndex: "name",
            render: (text) => (
                <span className="text-secondary fw-semibold">{text}</span>
            ),
            sorter: (a, b) => a.name?.localeCompare(b.name)
        },
        {
            title: "Descripción",
            dataIndex: "description",
            sorter: (a, b) => a.description?.localeCompare(b.description)
        },
        {
            title: 'Acción',
            dataIndex: 'actions',
            align: "center",
            key: 'actions',
            render: (record, index) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        {verifyPermission(rolePermissions, Modules.SalidasEfectivo, Functions.Modificar) &&
                            <a className="me-2 p-2" onClick={() => onEditItem(index)}>
                                <Edit className="feather-edit" />
                            </a>
                        }
                        {verifyPermission(rolePermissions, Modules.SalidasEfectivo, Functions.Eliminar) &&
                            <a className="confirm-text p-2" onClick={() => showDeleteItemAlert(index, "este registro")} >
                                <Trash2 className="feather-trash-2" />
                            </a>
                        }
                    </div>
                </div>
            )
        },
    ];

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Tipos de salida de efectivo</h4>
                            <h6>Puedes gestionar el catálogo de salidas de efectivo del sistema.</h6>
                        </div>
                    </div>
                    <ToolsHead
                        showExportData={verifyPermission(rolePermissions, Modules.SalidasEfectivo, Functions.Exportar)}
                        showExcel={true}
                        onReload={onLoadConceptList}
                    />
                    {verifyPermission(rolePermissions, Modules.SalidasEfectivo, Functions.Registrar) &&
                        <div className="page-btn">
                            <a
                                to="#"
                                className="btn btn-added"
                                onClick={onNewItem}
                            >
                                <PlusCircle className="me-2" />
                                Nuevo Tipo
                            </a>
                        </div>
                    }
                </div>
                {/* /product list */}
                <div className="card table-list-card">
                    <div className="card-body">
                        <div className="table-top">
                            <div className="search-set">
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeName(e)}
                                        onBlur={onLoadConceptList}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table
                                className="table"
                                rowKey={(record) => record?.id}
                                columns={columns}
                                loading={loading}
                                dataSource={dataSource}
                                size="small"
                                pagination={
                                    {
                                        position: ["topRight"],
                                        total: dataSource.length,
                                        showTotal: (total) => `Total: ${total}`,
                                        showSizeChanger: true
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
                {/* /product list */}
            </div>
            {newItem
                ?
                showModal
                    ? <AddCatalog id="add-cash-outflow" show={showModal} onHide={onHideModal} type='cash' isNew={true} />
                    : null
                :
                showModal
                    ? <AddCatalog id="edit-cash-outflow" show={showModal} onHide={onHideModal} type='cash' currentId={currentId} />
                    : null
            }
        </div>
    );
};

