import { decryptAES256 } from "../../services/internal/authentication";

export const fetchDataRequest = () => {
  return {
    type: 'FETCH_DATA_REQUEST'
  };
};
export const fetchDataSuccess = (data) => {
  return {
    type: 'FETCH_DATA_SUCCESS',
    payload: data
  };
};
export const fetchDataFailure = (error) => {
  return {
    type: 'FETCH_DATA_FAILURE',
    payload: error
  };
};
export const fetchDataErrors = (serverErrors) => {
  return {
    type: 'FETCH_DATA_ERRORS',
    payload: serverErrors
  };
};
export const setShowRemoteResponseToast = (show) => {
  return {
    type: 'SHOW_REMOTE_RESPONSE_TOAST',
    payload: show
  };
};
export const loadToken = () => {
  try {
    return {
      type: 'LOAD_TOKEN',
      payload: decryptAES256(localStorage.getItem('token'))
    };

  } catch (e) {
    console.error("Error al decifrar:", e);
  }
};
export const setToken = (token) => {
  return {
    type: 'SAVE_TOKEN',
    payload: token
  };
};
export const removeToken = () => {
  return {
    type: 'REMOVE_TOKEN',
    payload: localStorage.removeItem('token')
  };
};
export const loadUserInfo = (userInfo) => {
  return {
    type: 'LOAD_USERINFO',
    payload: userInfo
  };
};
export const setCategoryList = (data) => {
  return {
    type: 'CATEGORY_LIST',
    payload: data
  };
};
export const setUnitList = (data) => {
  return {
    type: 'UNIT_LIST',
    payload: data
  };
};
export const setStoreList = (data) => {
  return {
    type: 'STORE_LIST',
    payload: data
  };
};
export const setShiftList = (data) => {
  return {
    type: 'SHIFT_LIST',
    payload: data
  };
};
export const setArticleList = (data) => {
  return {
    type: 'ARTICLE_LIST',
    payload: data
  };
};
export const setCustomerList = (data) => {
  return {
    type: 'CUSTOMER_LIST',
    payload: data
  };
};
export const setExpiredTypes = (data) => {
  return {
    type: 'EXPIRED_TYPES',
    payload: data
  };
};
export const setProviderList = (data) => {
  return {
    type: 'PROVIDER_LIST',
    payload: data
  };
};
export const setStateList = (data) => {
  return {
    type: 'STATE_LIST',
    payload: data
  };
};
export const setDepartmentList = (data) => {
  return {
    type: 'DEPARTMENT_LIST',
    payload: data
  };
};
export const setUserList = (data) => {
  return {
    type: 'USER_LIST',
    payload: data
  };
};
export const setDesignationList = (data) => {
  return {
    type: 'DESIGNATION_LIST',
    payload: data
  };
};
export const setRoleList = (data) => {
  return {
    type: 'ROLE_LIST',
    payload: data
  };
};
export const setLeaveTypeList = (data) => {
  return {
    type: 'LEAVETYPE_LIST',
    payload: data
  };
};
export const setHolidayList = (data) => {
  return {
    type: 'HOLIDAY_LIST',
    payload: data
  };
};
export const setUserDetails = (data) => {
  return {
    type: 'USER_DETAILS',
    payload: data
  };
};
export const setUserDetailsImagePath = (data) => {
  return {
    type: 'USER_DETAILS_IMAGEPATH',
    payload: data
  };
};
export const setPurchaseList = (data) => {
  return {
    type: 'PURCHASE_LIST',
    payload: data
  };
};
export const setPurchaseProductList = (data) => {
  return {
    type: 'PURCHASE_PRODUCT_LIST',
    payload: data
  };
};
export const setNotes = (data) => {
  return {
    type: 'SET_NOTES',
    payload: data
  };
};
export const setDepartmentOptions = (data) => {
  return {
    type: 'SET_DEPARTMENT_OPTIONS',
    payload: data
  };
};
export const setArticleOptions = (data) => {
  return {
    type: 'SET_ARTICLE_OPTIONS',
    payload: data
  };
};
export const setStoreOptions = (data) => {
  return {
    type: 'SET_STORE_OPTIONS',
    payload: data
  };
};
export const setCategoryOptions = (data) => {
  return {
    type: 'SET_CATEGORY_OPTIONS',
    payload: data
  };
};
export const setCustomerOptions = (data) => {
  return {
    type: 'SET_CUSTOMER_OPTIONS',
    payload: data
  };
};
export const setDesignationOptions = (data) => {
  return {
    type: 'SET_DESIGNATION_OPTIONS',
    payload: data
  };
};
export const setExpiredTypeOptions = (data) => {
  return {
    type: 'SET_EXPIREDTYPE_OPTIONS',
    payload: data
  };
};
export const setHolidayOptions = (data) => {
  return {
    type: 'SET_HOLIDAY_OPTIONS',
    payload: data
  };
};
export const setLeaveTypeOptions = (data) => {
  return {
    type: 'SET_LEAVETYPE_OPTIONS',
    payload: data
  };
};
export const setProviderOptions = (data) => {
  return {
    type: 'SET_PROVIDER_OPTIONS',
    payload: data
  };
};
export const setRoleOptions = (data) => {
  return {
    type: 'SET_ROLE_OPTIONS',
    payload: data
  };
};
export const setStateOptions = (data) => {
  return {
    type: 'SET_STATE_OPTIONS',
    payload: data
  };
};
export const setStatusOptions = (data) => {
  return {
    type: 'SET_STATUS_OPTIONS',
    payload: data
  };
};
export const setUnitOptions = (data) => {
  return {
    type: 'SET_UNIT_OPTIONS',
    payload: data
  };
};
export const setShiftOptions = (data) => {
  return {
    type: 'SET_SHIFT_OPTIONS',
    payload: data
  };
};
export const setConceptOptions = (data) => {
  return {
    type: 'SET_CONCEPT_OPTIONS',
    payload: data
  };
};
export const setPaymentOptions = (data) => {
  return {
    type: 'SET_PAYMENT_OPTIONS',
    payload: data
  };
};
export const setStockList = (data) => {
  return {
    type: 'STOCK_LIST',
    payload: data
  };
};
export const setStockOperationList = (data) => {
  return {
    type: 'STOCK_OPERATION_LIST',
    payload: data
  };
};
export const setTransferList = (data) => {
  return {
    type: 'TRANSFER_LIST',
    payload: data
  };
};
export const setSasToken = (data) => {
  return {
    type: 'SAS_TOKEN',
    payload: data
  };
};
export const setRecipeBookList = (data) => {
  return {
    type: 'RECIPE_BOOK_LIST',
    payload: data
  };
};
export const setRecipeList = (data) => {
  return {
    type: 'RECIPE_LIST',
    payload: data
  };
};
export const setPackageList = (data) => {
  return {
    type: 'PACKAGE_LIST',
    payload: data
  };
};
export const setMenuList = (data) => {
  return {
    type: 'MENU_LIST',
    payload: data
  };
};
export const setSaleList = (data) => {
  return {
    type: 'SALE_LIST',
    payload: data
  };
};
export const setVacationList = (data) => {
  return {
    type: 'VACATION_LIST',
    payload: data
  };
};
export const setConceptList = (data) => {
  return {
    type: 'CONCEPT_LIST',
    payload: data
  };
};
export const setCashBreakList = (data) => {
  return {
    type: 'CASHBREAK_LIST',
    payload: data
  };
};
export const fetchPermissions = (data) => {
  return {
    type: 'FETCH_PERMISSIONS',
    payload: data
  };
};
export const loadPermissions = () => {
  try {
    return {
      type: 'LOAD_PERMISSIONS',
      payload: JSON.parse(decryptAES256(localStorage.getItem('rolePermissions')))
    };
  } catch (e) {
    console.error("Error al cifrar:", e);
  }
};
export const removePermissions = () => {
  try {
    return {
      type: 'REMOVE_PERMISSIONS',
      payload: localStorage.removeItem('rolePermissions')
    };
  } catch (e) {
    console.error("Error al decifrar:", e);
  }
};
export const toogleHeader_data = () => ({ type: "toggle_header" });

export const setToogleHeader = (payload) => ({
  type: "toggle_header",
  payload,
});