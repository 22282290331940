import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useSearchParams } from "react-router-dom";
import {
    PlusCircle,
    Trash2,
    HelpCircle
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { all_routes } from "../../../Router/all_routes";
import { ArrowLeft, BookOpen, Copy, Edit } from "react-feather";
import CustomPagination from "../../components/customPagination";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setRecipeList, setShowRemoteResponseToast } from "../../../core/redux/action";
import { showInternalErrorAlert } from "../../components/customAlert";
import { MdOutlineFactory } from "react-icons/md";
import ProduceRecipeModal from "../../../core/modals/recipes/producerecipe";
import ToolsHead from "../../components/toolsHead";
import { Recipes } from "../../../services/internal/recipes";
import { Functions, Modules, verifyPermission } from "../../../Router/authorization";

const RecipeGrid = () => {

    const dataSource = useSelector((state) => state.recipeList);
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const [filterName, setFilterName] = useState("");
    const [showProduceRecipeModal, setShowProduceRecipeModal] = useState(false);
    const [currentRecipe, setCurrentRecipe] = useState(null);

    const [searchParams] = useSearchParams();
    const paramRecipeBookId = searchParams.get('code');
    const route = all_routes;

    const renderSearchTooltip = (props) => (
        <Tooltip id="search-tooltip" {...props}>
            Ingresa el nombre del recetario
        </Tooltip>
    );

    const onChangeName = (e) => {
        setFilterName(e.target.value.trim())
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadRecipes = async () => {
        try {
            let filters = {};
            filters.enabled = true;
            if (filterName) {
                filters.name = filterName;
            }
            if (paramRecipeBookId) {
                if (!isNaN(Number(paramRecipeBookId))) {
                    filters.idRecipeBook = paramRecipeBookId;
                }
            }

            dispatch(fetchDataRequest());
            const response = await Recipes.getList(token, filters);
            setCurrentRecipe(response.data.data);
            dispatch(setRecipeList(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onDeleteItem = async (id) => {
        try {
            dispatch(fetchDataRequest());
            const response = await Recipes.deleteSingle(token, id);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
        }
        catch (err) {
            handleException(err);
        }
        await onLoadRecipes();
    }

    const showDeleteItemAlert = async (index, text) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onDeleteItem(index.id);
        } else {
            MySwal.close();
        }
    };

    const onShowProduceRecipeModal = (recipe) => {
        setCurrentRecipe(recipe);
        setShowProduceRecipeModal(true);
    }

    const onHideProduceRecipeModal = () => {
        setShowProduceRecipeModal(false);
    }

    useEffect(() => {
        onLoadRecipes();
    }, []);

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Recetas de {currentRecipe?.name}</h4>
                            <h6>A continuación te mostramos la lista de recetas.</h6>
                        </div>
                    </div>
                    <ToolsHead onReload={onLoadRecipes} />
                    <div className="page-btn">
                        <Link to={route.recipebook} className="btn btn-secondary">
                            <ArrowLeft className="me-2" />
                            Lista de Recetarios
                        </Link>
                    </div>
                    {verifyPermission(rolePermissions, Modules.Recetarios, Functions.Registrar) &&
                        <div className="page-btn">
                            <Link to={route.recipe + (paramRecipeBookId ? "?book=" + paramRecipeBookId : "")}
                                className="btn btn-added"
                            >
                                <PlusCircle className="me-2" />
                                Nueva Receta
                            </Link>
                        </div>
                    }
                </div>
                {/* /product list */}
                <div className="card">
                    <div className="card-body pb-0">
                        <div className="table-top table-top-new">
                            <div className="search-set mb-0">
                                <div className="total-employees">
                                    <h6>
                                        <BookOpen />
                                        Total de recetas <span>{dataSource.length}</span>
                                    </h6>
                                </div>
                                <div className="search-input">
                                    <input
                                        type="text"
                                        placeholder="Búsqueda rápida"
                                        className="form-control form-control-sm formsearch"
                                        onChange={e => onChangeName(e)}
                                        onBlur={onLoadRecipes}
                                        disabled={loading}
                                    />
                                    <a className="btn btn-searchset">
                                        <i data-feather="search" className="feather-search" />
                                    </a>
                                </div>
                                <OverlayTrigger placement="top" overlay={renderSearchTooltip}>
                                    <a className="link-primary">
                                        <HelpCircle />
                                    </a>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="employee-grid-widget">
                            <div className="row">
                                {dataSource.map((item) => (
                                    <div key={item.id} className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                                        <div className="employee-grid-profile">
                                            <div className="row mb-3">
                                                <div className="col-auto">
                                                    <span className={item.enabled ? "badge badge-linesuccess" : "badge badge-linedanger"}>
                                                        {item.enabled ? "Activo" : "Inactivo"}
                                                    </span>
                                                </div>
                                                <div className="col text-end">
                                                    {verifyPermission(rolePermissions, Modules.Recetarios, Functions.Copiar) &&
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-minus">Copiar receta</Tooltip>}
                                                        >
                                                            <a
                                                                className="me-2"
                                                            >
                                                                <Copy size={25} />
                                                            </a>
                                                        </OverlayTrigger>
                                                    }
                                                    {verifyPermission(rolePermissions, Modules.Recetarios, Functions.Producir) &&
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-minus">Producir receta</Tooltip>}
                                                        >
                                                            <a
                                                                onClick={() => onShowProduceRecipeModal(item)}
                                                                className="text-purple me-2"
                                                            >
                                                                <MdOutlineFactory size={25} />
                                                            </a>
                                                        </OverlayTrigger>
                                                    }
                                                    {verifyPermission(rolePermissions, Modules.Recetarios, Functions.Modificar) &&
                                                        <Link to={route.recipe + "?code=" + item.id + (paramRecipeBookId ? "&book=" + paramRecipeBookId : "")}
                                                            className="text-blue me-2"
                                                        >
                                                            <Edit size={25} />
                                                        </Link>
                                                    }
                                                    {verifyPermission(rolePermissions, Modules.Recetarios, Functions.Eliminar) &&
                                                        <a
                                                            className="text-danger"
                                                            onClick={() => showDeleteItemAlert(item, "el recetario")}
                                                        >
                                                            <Trash2 size={25} />
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                            <Link
                                                to={route.recipe + "?code=" + item.id + (paramRecipeBookId ? "&book=" + paramRecipeBookId : "")}
                                            >
                                                <div className="profile-info department-profile-info">
                                                    <ImageWithBasePath
                                                        src={item.imagePath ? item.imagePath : "assets/img/recipes/receta.jpg"}
                                                        alt=""
                                                    />
                                                </div>
                                            </Link>
                                            <div className="profile-head">
                                                <div className="container">
                                                    <h3>{item.name}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <CustomPagination totalNumber={dataSource.length} rowsNumber={dataSource.length} onClickPage={() => { }} />
                    </div>
                </div>
            </div>
            {showProduceRecipeModal
                ? <ProduceRecipeModal id="produceRecipe" show={showProduceRecipeModal} onHide={onHideProduceRecipeModal} recipe={currentRecipe} />
                : null
            }
        </div>
    );
};

export default RecipeGrid;
