import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import FormCreatedBy from '../../../feature-module/components/createdByForm';
import { PlusCircle, Trash2 } from 'react-feather';
import { Table } from 'antd';
import PropTypes from 'prop-types'
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { PurchaseOrders } from '../../../services/internal/purchaseOrders';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setNotes, setProviderOptions, setPurchaseProductList, setShowRemoteResponseToast, setStoreOptions, setTransferList } from '../../redux/action';
import { showInternalErrorAlert } from '../../../feature-module/components/customAlert';
import SearchProduct from '../../../feature-module/components/searchProduct';
import { formatearMoneda, generarCodigo, IVA, validateRegexQty } from '../../utils';
import { Catalogs } from '../../../services/internal/catalogs';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Statuses } from '../../../services/internal/statuses';

const AddPurchases = ({ id, show = false, isTransfer = false, onHide, isNew = false, currentId }) => {

    const { setValue, register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const MySwal = withReactContent(Swal);
    const loading = useSelector(state => state.loading);
    const token = useSelector(state => state.token);
    const userInfo = useSelector(state => state.userInfo);
    const notes = useSelector(state => state.notes);
    const providerOptions = useSelector(state => state.providerOptions);
    const storeOptions = useSelector(state => state.storeOptions);
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState([]);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [total, setTotal] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [resultProducts, setResultProducts] = useState([]);
    const [alterStoreOptions, setAlterStoreOptions] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(null);
    const [productColumns, setProductColumns] = useState(null);

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const calculateResponse = (isLoad, data) => {

        if (isNew) {
            return PurchaseOrders.postSingle(token, data);
        }

        if (isLoad) {
            return PurchaseOrders.getSingle(token, data.id);
        }

        return PurchaseOrders.putSingle(token, data);
    }

    const onSavePurchase = async (formData, orderStatus) => {
        try {
            let data = PurchaseOrders.requestData;
            data = {};
            data.id = currentId;
            if (isNew) {
                data.code = generarCodigo();
                data.transferCode = generarCodigo();
                data.branchIdDestination = formData.branchIdDestination?.value;
                if (isTransfer) {
                    data.branchIdOrigin = formData.branchIdOrigin?.value;
                } else {
                    data.providerId = formData.providerId?.value;
                }
                data.registered = new Date().toISOString();

                if (formData.comment) {
                    let newComment = [{
                        date: new Date().toISOString(),
                        user: userInfo?.given_name,
                        comment: formData.comment
                    }];
                    data.comment = JSON.stringify(newComment);
                }
                data.statusId = orderStatus.value;
            } else {
                if (notes.length > 0) {
                    const cleanedNotes = cleanNotes();
                    data.comment = JSON.stringify(cleanedNotes);
                }
                data.idStatus = orderStatus.value;
            }

            data.products = dataSource;
            data.subtotal = subtotal;
            data.total = total;


            dispatch(fetchDataRequest());

            const response = await calculateResponse(false, data);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            dispatch(setNotes([]));
            dispatch(setTransferList([]));
            dispatch(setPurchaseProductList([]));
            clearForm();
            onModalHide(null, true);
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadOptions = async () => {
        try {

            dispatch(fetchDataRequest());
            if (storeOptions.length === 0) {
                const responseA = await Catalogs.getOptions(token, Catalogs.Name.Branches);
                dispatch(setStoreOptions(responseA.data.data));
            }

            if (providerOptions.length === 0) {
                const responseB = await Catalogs.getOptions(token, Catalogs.Name.Providers);
                dispatch(setProviderOptions(responseB.data.data));
            }

            dispatch(fetchDataSuccess(true));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadPurchase = async () => {
        try {
            let data = PurchaseOrders.requestData;
            data = {};
            data.id = currentId;

            dispatch(fetchDataRequest());

            let branches = [];
            let providers = [];
            if (storeOptions.length === 0) {
                const responseA = await Catalogs.getOptions(token, Catalogs.Name.Branches);
                branches = responseA.data.data;
                dispatch(setStoreOptions(responseA.data.data));
            } else {
                branches = storeOptions;
            }

            if (providerOptions.length === 0) {
                const responseB = await Catalogs.getOptions(token, Catalogs.Name.Providers);
                dispatch(setProviderOptions(responseB.data.data));
            } else {
                providers = providerOptions;
            }

            const response = await calculateResponse(true, data);

            setValue('providerId', providers.find((i) => i.value === response.data.data.provider?.id));
            setValue('branchIdOrigin', branches.find((i) => i.value === response.data.data.branchIdOrigin?.id));
            setValue('branchIdDestination', branches.find((i) => i.value === response.data.data.branchIdDestination?.id));
            setTotal(response.data.data.total);
            setSubtotal(response.data.data.subtotal);
            setCurrentStatus(response.data.data.status);
            convertToDatasource(response.data.data.orders, response.data.data.status);
            onChangeColumns(response.data.data.status);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onChangePurchase = async (currentStatusValue, nextStatusValue) => {
        try {
            let data = PurchaseOrders.requestData;
            data = {};
            data.status = nextStatusValue;
            data.id = currentId;

            if (nextStatusValue === Statuses.Values.Approved.id) {
                data.products = dataSource.map(product => ({
                    ...product,
                    quantity: product.sent
                }));
            }
            else if (nextStatusValue === Statuses.Values.Received.id) {
                data.products = dataSource.map(product => ({
                    ...product,
                    quantity: product.received
                }));
            } else {
                data.products = dataSource;
            }

            dispatch(fetchDataRequest());

            //console.log(data)
            const response = await PurchaseOrders.putChangeStatus(token, data);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            dispatch(setNotes([]));
            dispatch(setTransferList([]));
            dispatch(setPurchaseProductList([]));
            clearForm();
            onModalHide(null, true);
        }
        catch (err) {
            handleException(err);
        }
    }

    const onAddProduct = (e) => {
        e?.preventDefault();
        let item = {
            key: generarCodigo(),
            productId: currentProduct.id,
            quantity: 1,
            name: currentProduct.name,
            salePrice: currentProduct.salePrice,
            unit: currentProduct.unmed?.abbreviation
        };

        item.subtotal = item.quantity * item.salePrice;
        setDataSource(prevDataSource => [...prevDataSource, item].sort((a, b) => a?.productId - b?.productId));
        setCurrentProduct(null);
        setSubtotal(subtotal + currentProduct.salePrice);
        setTotal((subtotal + currentProduct.salePrice) * (1 + IVA));
    }

    const onChangeProduct = (product) => {
        if (product) {
            let item = resultProducts.find(item => item.id === product.value);
            setCurrentProduct(item);
        } else {
            setCurrentProduct(null);
        }
    }

    const onChangeQty = (e, product) => {
        e?.preventDefault();
        product.quantity = e.target.value;

        setDataSource(prevDataSource => [...prevDataSource.filter(item => item.key !== product.key), product].sort((a, b) => a?.productId - b?.productId));
    }

    const onChangeReceivedQty = (e, product) => {
        e?.preventDefault();
        product.received = e.target.value;

        setDataSource(prevDataSource => [...prevDataSource.filter(item => item.key !== product.key), product].sort((a, b) => a?.productIdd - b?.productId));
    }

    const onChangeApprovedQty = (e, product) => {
        e?.preventDefault();
        product.sent = e.target.value;

        setDataSource(prevDataSource => [...prevDataSource.filter(item => item.key !== product.key), product].sort((a, b) => a?.productId - b?.productId));
    }

    const onBlurQty = (e, product) => {
        e?.preventDefault();
        const newQuantity = validateRegexQty(e.target.value);
        product.quantity = newQuantity;
        product.subtotal = newQuantity * product.salePrice;

        setDataSource(prevDataSource => {

            let items = [...prevDataSource.filter(item => item.key !== product.key), product].sort((a, b) => a?.productId - b?.productId);
            calculateTotal(items);

            return items;
        });
    }

    const onBlurReceivedQty = (e, product) => {
        e?.preventDefault();

        const newQuantity = validateRegexQty(e.target.value);
        product.received = newQuantity;
        product.subtotal = newQuantity * product.salePrice;

        setDataSource(prevDataSource => {

            let items = [...prevDataSource.filter(item => item.key !== product.key), product].sort((a, b) => a?.productId - b?.productId);
            calculateTotal(items);

            return items;
        });
    }

    const onBlurApprovedQty = (e, product) => {
        e?.preventDefault();

        const newQuantity = validateRegexQty(e.target.value);
        product.sent = newQuantity;
        product.subtotal = newQuantity * product.salePrice;

        setDataSource(prevDataSource => {

            let items = [...prevDataSource.filter(item => item.key !== product.key), product].sort((a, b) => a?.productId - b?.productId);
            calculateTotal(items);

            return items;
        });
    }

    const calculateTotal = (items) => {

        let sumaTotal = items.reduce((acumulador, objetoActual) => {
            return acumulador + objetoActual.subtotal;
        }, 0);
        setSubtotal(sumaTotal);
        setTotal(sumaTotal * (1 + IVA));
    }

    const onDeleteProduct = (e, product) => {
        e?.preventDefault();

        if (loading) {
            return;
        }

        setDataSource(prevDataSource => {

            let items = prevDataSource.filter(item => item.key !== product.key);
            calculateTotal(items);

            return items;
        });
    }

    const clearForm = () => {
        reset(formValues => ({
            ...formValues,
            comment: null,
            branchIdDestination: null,
            branchIdOrigin: null,
            providerId: null,
        }));
        setDataSource([]);
        setSubtotal(0);
    }

    const onModalHide = (e, refresh) => {
        e?.preventDefault();
        clearForm();
        onHide(refresh);
    }

    const onSearchProductError = (err) => {
        handleException(err);
    }

    const filterStore = (value) => {
        if (isTransfer) {
            setAlterStoreOptions(storeOptions.filter(i => i.value !== value));
        } else {
            setAlterStoreOptions(storeOptions);
        }
    }

    const showConfirmAlert = async (formData, status) => {
        if (dataSource.length === 0) {
            return;
        }

        let title = "";
        let html = "";
        let confirmButtonText = "";
        let confirmButtonColor = null;
        let icon = "";
        let statusValue = currentStatus ? currentStatus?.id : status.value;
        let nextStatus = null;
        switch (statusValue) {
            case Statuses.Values.Registered.value:
            case Statuses.Values.Updated.value:
                title = "¿<strong>Enviar</strong> orden de compra?";
                html = `Vamos a enviar la órden de compra a aprobación, asegúrate de revisar las cantidades y productos.`;
                icon = "info";
                confirmButtonText = "Enviar orden";
                confirmButtonColor = "#3fc3ee";
                nextStatus = Statuses.Values.Pending;
                break;
            case Statuses.Values.Approved.value:
            case Statuses.Values.Requested.value:
                title = "¿<strong>Recibir</strong> orden de compra?";
                html = "No podrás realizar cambios una vez que confirmes.";
                icon = "info";
                confirmButtonText = "He recibido la orden";
                confirmButtonColor = "#3fc3ee";
                nextStatus = Statuses.Values.Received;
                break;
            case Statuses.Values.Pending.value:
                title = "¿<strong>Aprobar</strong> orden de compra?";
                html = "No podrás realizar cambios una vez que confirmes.";
                icon = "info";
                confirmButtonText = "Aprobar orden";
                confirmButtonColor = "#3fc3ee";
                nextStatus = Statuses.Values.Approved;
                break;
            default:
                title = "¿<strong>Solicitar</strong> orden de compra?";
                html = `Al solicitar la orden no necesitará aprobación ni generará un traslado y podrás marcarla como recibida.`;
                icon = "info";
                confirmButtonText = "Solicitar orden";
                confirmButtonColor = "#3fc3ee";
                nextStatus = Statuses.Values.Requested;
        }

        const result = await MySwal.fire({
            title: title,
            html: html,
            icon: icon,
            showCancelButton: true,
            cancelButtonText: "Regresar",
            confirmButtonText: confirmButtonText,
            confirmButtonColor: confirmButtonColor,
        });

        if (result.isConfirmed) {
            if (statusValue === Statuses.Values.Approved.value) {
                await onChangePurchase(statusValue, Statuses.Values.Received.id);
            } else if (statusValue == Statuses.Values.Pending.value) {
                await onChangePurchase(statusValue, Statuses.Values.Approved.id);
            } else if (statusValue == Statuses.Values.Requested.value) {
                if (isNew) {
                    await onSavePurchase(formData, nextStatus);
                } else {
                    await onChangePurchase(statusValue, Statuses.Values.Received.id);
                }
            } else {
                await onSavePurchase(formData, nextStatus);
            }
        } else {
            MySwal.close();
        }
    }

    const convertToDatasource = (orders, status) => {

        let products = [];
        orders?.map((order) => {
            let product = {
                key: generarCodigo(),
                productId: order.product?.id,
                quantity: order.quantity,
                name: order.product?.name,
                salePrice: order.product?.salePrice,
                unit: order.product?.unmed?.abbreviation,
            };

            if (status) {
                if (status?.id === Statuses.Values.Requested.value
                    || status?.id === Statuses.Values.Approved.value) {
                    product.received = order.received ? order.received : order.quantity;
                }

                if (status?.id === Statuses.Values.Pending.value) {
                    product.sent = order.sent ? order.sent : order.quantity;
                }
            }

            product.subtotal = product.quantity * product.salePrice;
            products.push(product);
        });

        setDataSource(products);
    }

    const cleanNotes = () => {
        const cleanedNotes = notes.map((obj) => {
            const newObj = {};
            for (const prop in obj) {
                if (prop !== 'key') {
                    newObj[prop] = obj[prop];
                }
            }
            return newObj;
        });
        return cleanedNotes;
    }

    const calculateSecondaryActions = () => {
        if (loading) {
            return null;
        }

        if (isNew) {
            return (
                <input
                    type="button"
                    value="Guardar"
                    className="btn btn-dark"
                    onClick={handleSubmit((data) => onSavePurchase(data, Statuses.Values.Registered))}
                />
            );
        }

        if (currentStatus) {
            if (currentStatus.id === Statuses.Values.Registered.value
                || currentStatus.id === Statuses.Values.Updated.value) {
                return (
                    <input
                        type="button"
                        value="Guardar"
                        className="btn btn-dark"
                        onClick={handleSubmit((data) => onSavePurchase(data, Statuses.Values.Updated))}
                    />
                );
            }
        }

        return null;
    }

    const calculateSubmitActions = () => {
        if (loading) {
            return (
                <div className="col-6 text-end">
                    <button disabled={loading} className="btn btn-submit me-2">
                        <Spinner animation="border" role="status" size="sm" />
                    </button>
                </div>
            );
        }

        if (currentStatus) {
            if (currentStatus.id === Statuses.Values.Requested.value
                || currentStatus.id === Statuses.Values.Approved.value
            ) {
                return (
                    <div className="col-6 text-end">
                        <input type="submit" value="Recibir" className="btn btn-primary" />
                    </div>
                );
            }

            if (currentStatus.id === Statuses.Values.Pending.value) {
                return (
                    <div className="col-6 text-end">
                        <input type="submit" value="Aprobar" className="btn btn-primary" />
                    </div>
                );
            }
        }

        return (
            <div className="col-6 text-end">
                <input
                    type="button"
                    value="Solicitar"
                    className="btn btn-secondary me-2"
                    onClick={handleSubmit((data) => showConfirmAlert(data, Statuses.Values.Accepted))}
                />
                <input type="submit" value="Enviar" className="btn btn-primary" />
            </div>
        );
    }

    const showSearchProduct = () => {
        if (!currentStatus) {
            return false;
        }

        return currentStatus?.id === Statuses.Values.Approved.value
            || currentStatus?.id === Statuses.Values.Requested.value;
    }

    const showQtyColumn = (currentStatus) => {
        if (!currentStatus) {
            return false;
        }

        if (currentStatus?.id === Statuses.Values.Registered.value
            || currentStatus?.id === Statuses.Values.Updated.value) {
            return false;
        }

        return true;
    }

    const showReceivedQtyColumn = (currentStatus) => {
        if (!currentStatus) {
            return true;
        }

        if (currentStatus?.id === Statuses.Values.Delivered.value) {
            return false;
        }

        return true;
    }

    const showEditReceivedQtyColumn = (currentStatus) => {
        if (!currentStatus) {
            return true;
        }

        if (currentStatus?.id === Statuses.Values.Approved.value
            || currentStatus?.id === Statuses.Values.Requested.value) {
            return false;
        }
        return true;
    }

    const showApprovedQtyColumn = (currentStatus) => {
        if (!currentStatus) {
            return true;
        }

        if (currentStatus?.id === Statuses.Values.Approved.value) {
            return false;
        }

        return true;
    }

    const showEditApprovedQtyColumn = (currentStatus) => {
        if (!currentStatus) {
            return true;
        }

        if (currentStatus?.id === Statuses.Values.Pending.value) {
            return false;
        }

        return true;
    }

    const showRequestedQtyColumn = (currentStatus) => {
        if (!currentStatus) {
            return true;
        }

        if (currentStatus?.id === Statuses.Values.Pending.value
            || currentStatus?.id === Statuses.Values.Requested.value
            || currentStatus?.id === Statuses.Values.Approved.value) {
            return false;
        }

        return true;
    }

    const onChangeColumns = (currentStatus) => {
        const shorColumnWidth = 120;

        const columns = [
            {
                title: "Recibidos",
                align: "center",
                hidden: showReceivedQtyColumn(currentStatus),
                width: shorColumnWidth,
                dataIndex: "received",
                render: (text, record) => (
                    <span>{record.received}</span>
                ),
            },
            {
                title: "Recibidos",
                align: "center",
                width: shorColumnWidth,
                hidden: showEditReceivedQtyColumn(currentStatus),
                render: (text, record) => (
                    <input
                        type='text'
                        className='form-control text-center'
                        onChange={(e) => onChangeReceivedQty(e, record)}
                        onBlur={(e) => onBlurReceivedQty(e, record)}
                        value={record.received}
                        maxLength={14}
                        disabled={loading}
                    />
                ),
            },
            {
                title: "Enviados",
                align: "center",
                hidden: showApprovedQtyColumn(currentStatus),
                width: shorColumnWidth,
                dataIndex: "sent",
                render: (text, record) => (
                    <span>{record.sent}</span>
                ),
            },
            {
                title: "Enviados",
                align: "center",
                width: shorColumnWidth,
                hidden: showEditApprovedQtyColumn(currentStatus),
                render: (text, record) => (
                    <input
                        type='text'
                        className='form-control text-center'
                        onChange={e => onChangeApprovedQty(e, record)}
                        onBlur={(e) => onBlurApprovedQty(e, record)}
                        value={record.sent}
                        maxLength={14}
                        disabled={loading}
                    />
                ),
            },
            {
                title: "Solicitados",
                hidden: showRequestedQtyColumn(currentStatus),
                align: "center",
                width: shorColumnWidth,
                dataIndex: "quantity",
                render: (text, record) => (
                    <span>{record.quantity}</span>
                ),
            },
            {
                title: "Cantidad",
                align: "center",
                width: shorColumnWidth,
                hidden: showQtyColumn(currentStatus),
                dataIndex: "quantity",
                render: (text, record) => (
                    <input
                        type='text'
                        className={errors.quantity ? 'form-control is-invalid' : 'form-control text-center'}
                        onChange={(e) => onChangeQty(e, record)}
                        onBlur={(e) => onBlurQty(e, record)}
                        value={record.quantity}
                        maxLength={14}
                        disabled={loading}
                    />
                ),
            },
            {
                title: "Unidad",
                align: "center",
                width: 60,
                dataIndex: "unit",
            },
            {
                title: "Producto",
                dataIndex: "name",
            },
            {
                title: "Precio unitario",
                align: "center",
                dataIndex: "salePrice",
                width: 120,
                render: (text, record) => (
                    <span>{formatearMoneda(record.salePrice)}</span>
                ),
            },
            {
                title: "Subtotal",
                align: "right",
                dataIndex: "subtotal",
                width: 120,
                render: (text, record) => (
                    <span>{formatearMoneda(record.subtotal)}</span>
                ),
            },
            {
                title: "Acción",
                align: "center",
                dataIndex: "action",
                width: 60,
                hidden: showQtyColumn(currentStatus),
                render: (text, record) => (
                    <div className="action-table-data">
                        <div className="">
                            <a className="edit-delete-action me-2 p-2" onClick={e => onDeleteProduct(e, record)}>
                                <Trash2 className='feather-trash-2' />
                            </a>
                        </div>
                    </div>
                )
            },
        ];

        setProductColumns(columns);
    }

    useEffect(() => {
        if (alterStoreOptions.length === 0) {
            setAlterStoreOptions(storeOptions);
        }
        if (!isNew) {
            onLoadPurchase();
        } else {
            onLoadOptions();
        }

        onChangeColumns();
    }, [currentId]);

    return (
        <Modal show={show} centered onHide={onModalHide} id={id} className="modal-lg" backdrop="static" keyboard={false}>
            <form onSubmit={handleSubmit((data) => showConfirmAlert(data, Statuses.Values.Updated))}>
                <Modal.Header className="custom-modal-header" closeButton={!loading}>
                    <Modal.Title>
                        <h4>{isNew ? "Nueva orden de compra" : "Actualizar orden de compra"}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormCreatedBy isNew={isNew} />
                    <div className="row">
                        {
                            !isTransfer ?
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Proveedor<span className="text-danger"> *</span></label>
                                    <Controller
                                        name="providerId"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Select
                                                inputRef={ref}
                                                className={errors.providerId ? "form-control is-invalid" : ""}
                                                options={providerOptions}
                                                placeholder="Elige un proveedor"
                                                value={value}
                                                onChange={onChange}
                                                isClearable
                                                styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                                noOptionsMessage={() => "Sin opciones"}
                                                isDisabled={loading || isNew === false}
                                            />
                                        )}
                                        rules={
                                            { required: "Se requiere un proveedor" }
                                        }
                                    />
                                    {
                                        errors.providerId ?
                                            <div className="invalid-feedback">
                                                {errors.providerId.message}
                                            </div>
                                            : null
                                    }
                                </div>
                                : null
                        }
                        {
                            isTransfer ?
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Sucursal origen<span className="text-danger"> *</span></label>
                                    <Controller
                                        name="branchIdOrigin"
                                        control={control}
                                        render={({ field: { onChange, value, ref } }) => (
                                            <Select
                                                inputRef={ref}
                                                className={errors.branchIdOrigin ? "form-control is-invalid" : ""}
                                                options={storeOptions}
                                                placeholder="Elige la sucursal de origen"
                                                value={value}
                                                onChange={(val) => { filterStore(val?.value); onChange(val); }}
                                                isClearable
                                                styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                                noOptionsMessage={() => "Sin opciones"}
                                                isDisabled={loading || isNew === false}
                                            />
                                        )}
                                        rules={
                                            { required: "Se requiere una sucursal origen" }
                                        }
                                    />
                                    {
                                        errors.branchIdOrigin ?
                                            <div className="invalid-feedback">
                                                {errors.branchIdOrigin.message}
                                            </div>
                                            : null
                                    }
                                </div>
                                : null
                        }
                        <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <label className="form-label">Sucursal destino<span className="text-danger"> *</span></label>
                            <Controller
                                name="branchIdDestination"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Select
                                        inputRef={ref}
                                        className={errors.branchIdDestination ? "form-control is-invalid" : ""}
                                        options={alterStoreOptions}
                                        placeholder="Elige la sucursal de destino"
                                        value={value}
                                        onChange={onChange}
                                        isClearable
                                        styles={{ menu: provided => ({ ...provided, zIndex: 100 }) }}
                                        noOptionsMessage={() => "Sin opciones"}
                                        isDisabled={loading || isNew === false}
                                    />
                                )}
                                rules={
                                    { required: "Se requiere una sucursal destino" }
                                }
                            />
                            {
                                errors.branchIdDestination ?
                                    <div className="invalid-feedback">
                                        {errors.branchIdDestination.message}
                                    </div>
                                    : null
                            }
                        </div>
                        {
                            showSearchProduct()
                                ? null
                                :
                                <div className={currentProduct ? "col-lg-9 col-md-9 col-sm-12" : "col-12"}>
                                    <SearchProduct
                                        productSelectedCallback={(product) => onChangeProduct(product)}
                                        errorCallback={(err) => onSearchProductError(err)}
                                        resultProductsCallback={(results) => setResultProducts(results)}
                                    />
                                </div>
                        }
                        {currentProduct ?
                            <div className="col-lg-3 col-md-3 col-sm-12 d-flex justify-content-end align-items-end mb-3">
                                <button
                                    className='btn btn-info w-100'
                                    onClick={e => onAddProduct(e)}>
                                    <PlusCircle /> Agregar producto
                                </button>
                            </div>
                            : null}
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <Table
                                    columns={productColumns}
                                    dataSource={dataSource}
                                    rowKey={(record) => record.key}
                                    pagination={false}
                                    size="small"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            dataSource.length == 0 ?
                                <div className="col-lg-6">
                                    <div className='invalid-feedback' style={{ display: 'block' }}>Se requiere al menos 1 producto.</div>
                                </div>
                                : null
                        }
                        <div className="col-lg-6 ms-auto">
                            <div className="total-order w-100 max-widthauto m-auto mb-4">
                                <ul>
                                    <li>
                                        <h4>Subtotal</h4>
                                        <h5>{formatearMoneda(subtotal)}</h5>
                                    </li>
                                    <li>
                                        <h4>IVA {IVA > 0 ? `(${IVA * 100}%)` : "Incluido"}</h4>
                                        <h5>{formatearMoneda(subtotal * IVA)}</h5>
                                    </li>
                                    <li>
                                        <h4>Total</h4>
                                        <h5>{formatearMoneda(total)}</h5>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {isNew
                        ? <div className="row">
                            <div className="col-12 mb-3">
                                <label className="form-label">Comentarios</label>
                                <textarea
                                    rows={3}
                                    maxLength={800}
                                    placeholder="Escribe tus comentarios"
                                    className={!errors.comment ? "form-control" : "form-control is-invalid"}
                                    disabled={loading}
                                    {...register("comment")}
                                />
                                {
                                    errors.comment ?
                                        <div className="invalid-feedback">
                                            {errors.comment.message}
                                        </div>
                                        : null
                                }
                                <p>Máximo 800 caracteres.</p>
                            </div>
                        </div>
                        : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="container row">
                        <div className="col-6 text-start">
                            <button
                                className="btn btn-cancel me-2"
                                disabled={loading}
                                onClick={(e) => onModalHide(e, false)}
                            >
                                Regresar
                            </button>
                            {calculateSecondaryActions()}
                        </div>
                        {calculateSubmitActions()}
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

AddPurchases.propTypes = {
    id: PropTypes.string.isRequired,
    isTransfer: PropTypes.bool,
    show: PropTypes.bool,
    isNew: PropTypes.bool,
    currentId: PropTypes.number,
    onHide: PropTypes.func
};

export default AddPurchases
