import React, { useEffect, useState } from 'react'
import ImageWithBasePath from '../../core/img/imagewithbasebath'
import { useBarcode } from 'next-barcode';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setNotes, setShowRemoteResponseToast } from '../../core/redux/action';
import { showInternalErrorAlert } from '../components/customAlert';
import { Articles } from '../../services/internal/articles';
import { Link, useSearchParams } from 'react-router-dom';
import ToolsHead from '../components/toolsHead';
import { fechaHoraFormat, formatearFecha } from '../../core/utils';
import NotesTable from '../components/notes';
import { all_routes } from '../../Router/all_routes';
import { Transfers } from '../../services/internal/transfers';
import { Step, Stepper } from 'react-form-stepper';
import { BsCheck, BsClockFill, BsTruck, BsX } from "react-icons/bs";
import { Functions, Modules, verifyPermission } from '../../Router/authorization';

const TransferDetail = () => {

    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const loading = useSelector((state) => state.loading);
    const codeParam = searchParams.get('code');
    const [dataItem, setDataItem] = useState(null);
    const route = all_routes;
    const { inputRef } = useBarcode({
        value: dataItem?.code,
        options: {
            //format: "EAN13",
        }
    });

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadItem = async () => {
        try {
            let data = Articles.requestData;
            data = {};
            data.id = Number(codeParam);
            if (isNaN(data.id)) {
                return;
            }
            dispatch(fetchDataRequest());

            const response = await Transfers.getSingle(token, codeParam);

            setDataItem(response.data.data);
            //console.log(response)
            if (response.data.data?.comentary) {
                dispatch(setNotes(JSON.parse(response.data.data?.comentary)));
            } else {
                dispatch(setNotes(null));
            }
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const calculateStep = () => {
        const currentStatus = dataItem?.status?.value;
        const customStyle = { fontFamily: '"Nunito", sans-serif' };
        const stepperStyle = {
            size: 70,
            activeBgColor: "#7367F0",
            completedBgColor: "#10095d",
            labelFontSize: 18,
        };
        const connetorsStyle = {
            size: 3,
            activeColor: "#7367F0",
            completedColor: "#10095d",
        };
        const iconSize = 35;
        switch (currentStatus) {
            case "Pendiente":
                return (
                    <Stepper style={customStyle} styleConfig={stepperStyle} connectorStyleConfig={connetorsStyle} connectorStateColors>
                        <Step label="Pendiente" active><BsTruck size={iconSize} /></Step>
                        <Step label="Aprobado" ><BsClockFill size={iconSize} /></Step>
                        <Step label="Entregado" ><BsClockFill size={iconSize} /></Step>
                    </Stepper>
                );
            case "Aprobado":
                return (
                    <Stepper style={customStyle} styleConfig={stepperStyle} connectorStyleConfig={connetorsStyle} connectorStateColors>
                        <Step label="Pendiente" completed><BsCheck size={iconSize} /></Step>
                        <Step label="Aprobado" active><BsTruck size={iconSize} /></Step>
                        <Step label="Entregado" ><BsClockFill size={iconSize} /></Step>
                    </Stepper>
                );
            case "Entregado":
                return (
                    <Stepper style={customStyle} styleConfig={stepperStyle} connectorStyleConfig={connetorsStyle} connectorStateColors>
                        <Step label="Pendiente" completed><BsCheck size={iconSize} /></Step>
                        <Step label="Aprobado" completed><BsCheck size={iconSize} /></Step>
                        <Step label="Entregado" active ><BsTruck size={iconSize} /></Step>
                    </Stepper>
                );
            case "Rechazado":
                return (
                    <Stepper style={customStyle} styleConfig={stepperStyle} connectorStyleConfig={connetorsStyle} connectorStateColors>
                        <Step label="Pendiente" completed><BsTruck size={iconSize} /></Step>
                        <Step label="Rechazado" active ><BsX size={iconSize} /></Step>
                    </Stepper>
                );
            default:
                return null;
        }
    }

    useEffect(() => {
        if (codeParam) {
            onLoadItem();
        }
    }, [codeParam]);

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="page-title">
                        <h4>Ficha de Traslado</h4>
                        <h6>Información completa del traslado.</h6>
                    </div>
                    <ToolsHead
                        showExportData={verifyPermission(rolePermissions, Modules.Traslados, Functions.Exportar)}
                        showPrint={true}
                        onReload={onLoadItem}
                    />
                </div>
                {/* /add */}
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                                        <div className="bar-code-view">
                                            <ImageWithBasePath src="assets/img/logo.png" alt="img" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
                                        <div className="bar-code-view">
                                            {loading &&
                                                <div id="global-loader" style={{ position: 'relative', height: 80 }}>
                                                    <div className="whirly-loader"></div>
                                                </div>
                                            }
                                            <svg ref={inputRef} />
                                        </div>
                                    </div>
                                </div>
                                <div className="productdetails mb-4">
                                    <ul className="product-bar">
                                        <li>
                                            <h4>Código</h4>
                                            <h6 className="text-orange fw-semibold">{dataItem?.code}</h6>
                                        </li>
                                        {dataItem?.originProvider &&
                                            <li>
                                                <h4>Proveedor</h4>
                                                <h6 className="text-dark">{dataItem?.originProvider ? dataItem?.originProvider?.name : "NA"}</h6>
                                            </li>
                                        }
                                        {dataItem?.originBranchId &&
                                            <li>
                                                <h4>Sucursal origen</h4>
                                                <h6 className="text-secondary">{dataItem?.originBranchId ? dataItem?.originBranchId?.name : "NA"}</h6>
                                            </li>
                                        }
                                        <li>
                                            <h4>Sucursal destino</h4>
                                            <h6 className="text-secondary">{dataItem?.destinyBranchId?.name}</h6>
                                        </li>
                                        <li>
                                            <h4>Fecha</h4>
                                            <h6>{dataItem?.registerDate ? formatearFecha(dataItem?.registerDate, fechaHoraFormat) : "NA"}</h6>
                                        </li>
                                        <li>
                                            <h4>Solicitado por:</h4>
                                            <h6 className="text-primary">{dataItem?.requesterUser ? dataItem?.requesterUser?.alias : "NA"}</h6>
                                        </li>
                                        {dataItem?.sendedDate &&
                                            <li>
                                                <h4>Fecha de aprobación</h4>
                                                <h6>{dataItem?.sendedDate ? formatearFecha(dataItem?.sendedDate, fechaHoraFormat) : "NA"}</h6>
                                            </li>
                                        }
                                        {dataItem?.emiterUser &&
                                            <li>
                                                <h4>Aprobado por:</h4>
                                                <h6 className="text-primary">{dataItem?.emiterUser ? dataItem?.emiterUser?.alias : "NA"}</h6>
                                            </li>
                                        }
                                        {dataItem?.deliveryDate &&
                                            <li>
                                                <h4>Fecha de recibido</h4>
                                                <h6>{dataItem?.deliveryDate ? formatearFecha(dataItem?.deliveryDate, fechaHoraFormat) : "NA"}</h6>
                                            </li>
                                        }
                                        {dataItem?.receptorUser &&
                                            <li>
                                                <h4>Recibido por:</h4>
                                                <h6 className="text-primary">{dataItem?.receptorUser ? dataItem?.receptorUser?.alias : "NA"}</h6>
                                            </li>
                                        }
                                        <li>
                                            <h4>Estatus</h4>
                                            <h6 className="text-purple fw-semibold">{dataItem?.status?.value}</h6>
                                        </li>
                                    </ul>
                                </div>
                                <div className="row">
                                    {calculateStep()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="card">
                            <div className="row p-3">
                                <h4>Comentarios</h4>
                            </div>
                            <div className="card-body">
                                <NotesTable />
                            </div>
                        </div>
                        {
                            dataItem?.purchase?.id ?
                                <div className="card">
                                    <div className="row p-3">
                                        <h4>Orden de compra</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row text-center">
                                            <Link
                                                to={route.purchasedetails + "?code=" + dataItem?.purchase?.id}
                                                className="text-blue fw-bold">
                                                Ir a la orden
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                : null}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransferDetail
