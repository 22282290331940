import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import {
    ArrowLeft,
    ChevronDown,
    ChevronUp,
    Info,
    List,
    Mail,
    Hash,
    FileText,
    CheckCircle,
    Calendar,
    PlusCircle
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setDepartmentOptions, setDesignationOptions, setRoleOptions, setShowRemoteResponseToast, setStateOptions, setStoreOptions, setToogleHeader, setUserDetails, setUserList } from "../../../core/redux/action";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import FormCreatedBy from "../createdByForm";
import Select from "react-select";
import AddCatalog from "../../../core/modals/inventory/addcatalog";
import { customRegex, emailRegex, fechaFormat, noOptionsText, phoneRegex, strongPassRegex, zipRegex } from "../../../core/utils";
import { Controller, useForm } from "react-hook-form";
import { Users } from "../../../services/internal/users";
import { showInternalErrorAlert } from "../customAlert";
import variables from "../../../style/scss/utils/_variables.scss";
import AvatarUpload from "../fileUpload/AvatarUpload";
import { Catalogs } from "../../../services/internal/catalogs";

registerLocale('es', es)

const FormEmployee = ({ isNew = false }) => {
    const { setValue, getValues, register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const loading = useSelector((state) => state.loading);
    const token = useSelector((state) => state.token);
    const data = useSelector((state) => state.toggle_header);
    const dispatch = useDispatch();
    const [showRoleModal, setShowRoleModal] = useState(false);
    const [showDepartmentModal, setShowDepartmentModal] = useState(false);
    const [showDesignationModal, setShowDesignationModal] = useState(false);
    const [inputType, setInputType] = useState("password");
    const roleOptions = useSelector((state) => state.roleOptions);
    const departmentOptions = useSelector((state) => state.departmentOptions);
    const storeOptions = useSelector((state) => state.storeOptions);
    const stateOptions = useSelector((state) => state.stateOptions);
    const designationOptions = useSelector((state) => state.designationOptions);
    const [searchParams] = useSearchParams();
    const [imagePath, setImagePath] = useState("assets/img/profiles/profile.png");
    const [userId, setUserId] = useState(null);

    const paramUserId = searchParams.get('code');
    const route = all_routes;

    const onShowPassword = () => {
        if (inputType === "text") {
            setInputType("password");
            return;
        }
        setInputType("text");
    }

    const renderCollapseTooltip = (props) => (
        <Tooltip id="refresh-tooltip" {...props}>
            Colapsar
        </Tooltip>
    );

    const calculateResponse = (isLoad, data) => {

        if (isNew) {
            return Users.postSingle(token, data);
        }

        if (isLoad) {
            return Users.getSingle(token, data.id);
        }

        return Users.putSingle(token, data);
    }

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadCatalogs = async () => {
        try {
            dispatch(fetchDataRequest());

            if (storeOptions.length === 0) {
                const responseA = await Catalogs.getOptions(token, Catalogs.Name.Branches);
                dispatch(setStoreOptions(responseA.data.data));
            }

            if (departmentOptions.length === 0) {
                const responseB = await Catalogs.getOptions(token, Catalogs.Name.Areas);
                dispatch(setDepartmentOptions(responseB.data.data));
            }

            if (roleOptions.length === 0) {
                const responseC = await Catalogs.getOptions(token, Catalogs.Name.Roles);
                dispatch(setRoleOptions(responseC.data.data));
            }

            if (stateOptions.length === 0) {
                const responseD = await Catalogs.getOptions(token, Catalogs.Name.States);
                dispatch(setStateOptions(responseD.data.data));
            }

            if (designationOptions.length === 0) {
                const responseE = await Catalogs.getOptions(token, Catalogs.Name.Designations);
                dispatch(setDesignationOptions(responseE.data.data));
            }

            dispatch(fetchDataSuccess(true));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadItem = async () => {
        try {
            let data = Users.requestData;
            data = {};
            data.id = paramUserId ? Number(paramUserId) : userId;
            if (isNaN(data.id)) {
                return;
            }

            dispatch(fetchDataRequest());

            let branches = [];
            let designations = [];
            let areas = [];
            let roles = [];
            let states = [];
            if (storeOptions.length === 0) {
                const responseA = await Catalogs.getOptions(token, Catalogs.Name.Branches);
                branches = responseA.data.data;
                dispatch(setStoreOptions(responseA.data.data));
            } else {
                branches = storeOptions;
            }

            if (departmentOptions.length === 0) {
                const responseB = await Catalogs.getOptions(token, Catalogs.Name.Areas);
                areas = responseB.data.data;
                dispatch(setDepartmentOptions(responseB.data.data));
            } else {
                areas = departmentOptions;
            }

            if (roleOptions.length === 0) {
                const responseC = await Catalogs.getOptions(token, Catalogs.Name.Roles);
                roles = responseC.data.data;
                dispatch(setRoleOptions(responseC.data.data));
            } else {
                roles = roleOptions;
            }

            if (stateOptions.length === 0) {
                const responseD = await Catalogs.getOptions(token, Catalogs.Name.States);
                states = responseD.data.data;
                dispatch(setStateOptions(responseD.data.data));
            } else {
                states = stateOptions;
            }

            if (designationOptions.length === 0) {
                const responseE = await Catalogs.getOptions(token, Catalogs.Name.Designations);
                designations = responseE.data.data;
                dispatch(setDesignationOptions(responseE.data.data));
            } else {
                designations = designationOptions;
            }

            const response = await calculateResponse(true, data);

            setValue('areaId', areas.find((i) => i.value === response.data.data.area?.id));
            setValue('designationId', designations.find((i) => i.value === response.data.data.designation?.id));
            setValue('branchId', branches.find((i) => i.value === response.data.data.branch?.id));
            setValue('roleId', roles.find((i) => i.value === response.data.data.role?.id));
            setValue('name', response.data.data.name);
            setValue('surname', response.data.data.surname);
            setValue('lastname', response.data.data.lastname);
            setValue('joinDate', new Date(response.data.data.joinDate));
            setValue('employeeId', response.data.data.employeeId);
            setValue('email', response.data.data.email);
            setValue('phone', response.data.data.phone);
            setValue('curp', response.data.data.curp);
            setValue('alias', response.data.data.alias);
            setValue('address', response.data.data.address);
            setValue('neigborhood', response.data.data.neigborhood);
            setValue('town', response.data.data.town);
            setValue('stateId', states.find((i) => i.value === response.data.data.state?.id));
            setValue('zipCode', response.data.data.zipCode);
            setValue('taxId', response.data.data.taxId);
            setValue('businessName', response.data.data.businessName);
            setValue('taxZipCode', response.data.data.taxZipCode);
            setValue('enabled', response.data.data.enabled ? "activo" : "inactivo");
            if (response.data.data.imagePath) {
                setImagePath(response.data.data.imagePath);
            }
            setUserId(response.data.data.id);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onSaveItem = async (formData) => {
        //console.log(formData)
        try {
            let data = Users.requestData;
            data = {};
            data.id = userId;

            if (isNew) {
                data.password = formData.newPass;
            }
            data.areaId = formData.areaId?.value;
            data.designationId = formData.designationId?.value;
            data.branchId = formData.branchId?.value;
            data.roleId = formData.roleId?.value;
            data.name = formData.name;
            data.surname = formData.surname;
            data.lastname = formData.lastname;
            data.joinDate = formData.joinDate;
            data.employeeId = formData.employeeId;
            data.email = formData.email;
            data.phone = formData.phone;
            data.curp = formData.curp;
            data.alias = formData.alias;
            data.address = formData.address;
            data.neigborhood = formData.neigborhood;
            data.town = formData.town;
            data.stateId = formData.stateId?.value;
            data.zipCode = formData.zipCode;
            data.taxId = formData.taxId;
            data.businessName = formData.businessName;
            data.taxZipCode = formData.taxZipCode;
            data.enabled = formData.enabled === "activo";
            data.imagePath = imagePath;

            dispatch(fetchDataRequest());

            const response = await calculateResponse(false, data);
            //console.log(response)
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            clearForm();
            dispatch(setUserList([]));
            dispatch(setUserDetails({}));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onHideRoleModal = () => {
        setShowRoleModal(false);
    }

    const onHideDepartmentModal = () => {
        setShowDepartmentModal(false);
    }

    const onHideDesignationModal = () => {
        setShowDesignationModal(false);
    }

    const clearForm = async () => {
        if (isNew) {
            reset(formValues => ({
                ...formValues,
                areaId: null,
                designationId: null,
                branchId: null,
                name: null,
                surname: null,
                lastname: null,
                joinDate: null,
                employeeId: null,
                email: null,
                phone: null,
                curp: null,
                alias: null,
                address: null,
                neigborhood: null,
                town: null,
                stateId: null,
                zipCode: null,
                taxId: null,
                businessName: null,
                taxZipCode: null,
                newPass: null,
                pass: null
            }));
        } else {
            await onLoadItem();
        }
    }

    const onChangedImage = async (newImagePath) => {
        setImagePath(newImagePath);
        try {
            dispatch(fetchDataRequest());

            const data = {
                id: userId,
                alias: getValues("alias"),
                email: getValues("email"),
                roleId: getValues("roleId"),
                imagePath: newImagePath,
            }

            const response = await Users.putSingle(token, data);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    useEffect(() => {
        if (isNew === false && paramUserId) {
            onLoadItem();
        } else {
            onLoadCatalogs();
        }

    }, [paramUserId]);

    return (
        <div className="page-wrapper">
            <div className="content">
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>{isNew ? "Registrar usuario" : "Actualizar usuario"}</h4>
                            <h6>{isNew ? "Puedes registrar un usuario y su información." : "Puedes actualizar un empleado y su información."}</h6>
                        </div>
                    </div>
                    <ul className="table-top-head">
                        <li>
                            <div className="page-btn">
                                <Link to={route.employeegrid} className="btn btn-secondary">
                                    <ArrowLeft className="me-2" />
                                    Lista de Usuarios
                                </Link>
                            </div>
                        </li>
                        <li>
                            <OverlayTrigger placement="top" overlay={renderCollapseTooltip}>
                                <a
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Colapsar"
                                    id="collapse-header"
                                    className={data ? "active" : ""}
                                    onClick={() => {
                                        dispatch(setToogleHeader(!data));
                                    }}
                                >
                                    <ChevronUp className="feather-chevron-up" />
                                </a>
                            </OverlayTrigger>
                        </li>
                    </ul>
                </div>
                {/* /add */}
                <form onSubmit={handleSubmit((data) => onSaveItem(data))}>
                    <div className="card">
                        <div className="card-body add-product pb-0">
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample"
                            >
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingOne">
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-controls="collapseOne"
                                        >
                                            <div className="addproduct-icon">
                                                <h5>
                                                    <Info className="add-info" />

                                                    <span>Información del sistema</span>
                                                </h5>
                                                <a>
                                                    <ChevronDown className="chevron-down-add" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            <div className="profile-set">
                                                <div className="profile-head"></div>
                                                <div className="row">
                                                    <div className="col-lg-2 col-sm-12 col-12 d-flex justify-content-center mb-3">
                                                        <AvatarUpload
                                                            prefix="user_image"
                                                            imageGrouperId="users"
                                                            userId={`${userId}`}
                                                            avatarPath={imagePath}
                                                            onImageUploaded={onChangedImage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-sm-12 col-12 mb-3">
                                                    <label className="form-label">Alias de usuario<span className="text-danger"> *</span></label>
                                                    <input
                                                        type="text"
                                                        placeholder="Escribe el alias para el usuario"
                                                        className={!errors.alias ? "form-control" : "form-control is-invalid"}
                                                        {...register("alias",
                                                            {
                                                                required: "Alias del usuario es requerido"
                                                            }
                                                        )}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                    {
                                                        errors.alias ?
                                                            <div className="invalid-feedback">
                                                                {errors.alias.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-6 col-sm-12 col-12 mb-3">
                                                    <div className="add-newplus">
                                                        <label className="form-label">Rol en el sistema<span className="text-danger"> *</span></label>
                                                        <a
                                                            onClick={() => setShowRoleModal(true)}
                                                        >
                                                            <PlusCircle className="plus-down-add text-secondary" />
                                                            <span className="text-secondary">Nuevo</span>
                                                        </a>
                                                    </div>
                                                    <Controller
                                                        name="roleId"
                                                        control={control}
                                                        render={({ field: { onChange, value, ref } }) => (
                                                            <Select
                                                                inputRef={ref}
                                                                className={errors.roleId ? "form-control is-invalid" : ""}
                                                                options={roleOptions}
                                                                placeholder="Elige un rol"
                                                                value={value}
                                                                onChange={onChange}
                                                                noOptionsMessage={() => noOptionsText}
                                                                isDisabled={loading || userId === 1}
                                                                isClearable
                                                            />
                                                        )}
                                                        rules={{ required: 'Requerido' }}
                                                    />
                                                    {
                                                        errors.roleId ?
                                                            <div className="invalid-feedback">
                                                                {errors.roleId.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                {!isNew ?
                                                    <div className="col-lg-12 col-sm-12 mb-3">
                                                        <label className="form-label">Estatus<span className="text-danger"> *</span></label>
                                                        <div className="col-lg-12">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    id="estatus_activo"
                                                                    type="radio"
                                                                    value="activo"
                                                                    disabled={loading}
                                                                    {...register("enabled",
                                                                        {
                                                                            required: 'Es necesario marcar este campo'
                                                                        }
                                                                    )}
                                                                />
                                                                <label className="form-check-label" htmlFor="estatus_activo">Activo</label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    id="estatus_inactivo"
                                                                    type="radio"
                                                                    value="inactivo"
                                                                    disabled={loading}
                                                                    {...register("enabled")}
                                                                />
                                                                <label className="form-check-label" htmlFor="estatus_inactivo">Inactivo</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                            {isNew ?
                                                <div className="row">
                                                    <div className="modal-title-head people-cust-avatar">
                                                        <h6>Contraseña</h6>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-12 mb-3">
                                                        <label className="form-label">Nueva contraseña<span className="text-danger"> *</span></label>
                                                        <div className="pass-group">
                                                            <input
                                                                type={inputType}
                                                                className={!errors.pass ? "form-control" : "form-control is-invalid"}
                                                                {...register("pass", {
                                                                    required: "Requerido.",
                                                                    pattern: {
                                                                        value: strongPassRegex,
                                                                        message: "Tu contraseña no cumple las reglas"
                                                                    }
                                                                })} />
                                                            {
                                                                !errors.pass ?
                                                                    <span className={inputType === "text" ? "fas toggle-password fa-eye-slash" : "fas toggle-password fa-eye"}
                                                                        onClick={onShowPassword} />
                                                                    :
                                                                    <div className="invalid-feedback">
                                                                        {errors.pass.message}
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-12 mb-3">
                                                        <label className="form-label"> Confirmar contraseña<span className="text-danger"> *</span></label>
                                                        <div className="pass-group">
                                                            <input
                                                                type={inputType}
                                                                className={!errors.newPass ? "form-control" : "form-control is-invalid"}
                                                                {...register("newPass", {
                                                                    required: "Requerido.",
                                                                    pattern: {
                                                                        value: strongPassRegex,
                                                                        message: "Tu contraseña no cumple las reglas"
                                                                    },
                                                                    validate: {
                                                                        confirmPass: (value) => {
                                                                            const { pass } = getValues();
                                                                            return pass === value || "Contraseñas no coinciden";
                                                                        }
                                                                    }
                                                                })} />
                                                            {
                                                                !errors.newPass ?
                                                                    <span className={inputType === "text" ? "fas toggle-password fa-eye-slash" : "fas toggle-password fa-eye"}
                                                                        onClick={onShowPassword} />
                                                                    : <div className="invalid-feedback">
                                                                        {errors.newPass.message}
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-sm-12 mb-3">
                                                        <div className="alert alert-info" role="alert">
                                                            <li>Al menos una letra minúscula (a-z).</li>
                                                            <li>Al menos una letra mayúscula (A-Z).</li>
                                                            <li>Al menos un dígito (0-9).</li>
                                                            <li>Al menos un carácter especial (como @ $ % # . ! *).</li>
                                                            <li>Longitud mínima de 8 caracteres.</li>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div className="modal-title-head people-cust-avatar mb-3">
                                                    <h6>Cambio de contraseña</h6>
                                                    <div className="alert alert-info" role="alert">
                                                        Para cambiar la contraseña de un usuario existente, debes solicitarle hacerlo desde su <strong>Perfil</strong> o mediante la opción <strong>Olvidé contraseña</strong> al iniciar sesión.
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample2"
                            >
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingTwo">
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-controls="collapseTwo"
                                        >
                                            <div className="text-editor add-list">
                                                <div className="addproduct-icon list icon">
                                                    <h5>
                                                        <CheckCircle className="add-info" />
                                                        <span>Información personal</span>
                                                    </h5>
                                                    <a>
                                                        <ChevronDown className="chevron-down-add" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample2"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <label className="form-label">Sucursal</label>
                                                    <Controller
                                                        name="branchId"
                                                        control={control}
                                                        render={({ field: { onChange, value, ref } }) => (
                                                            <Select
                                                                inputRef={ref}
                                                                className={errors.branchId ? "form-control is-invalid" : ""}
                                                                options={storeOptions}
                                                                placeholder="Elige una sucursal"
                                                                value={value}
                                                                onChange={onChange}
                                                                noOptionsMessage={() => noOptionsText}
                                                                isDisabled={loading}
                                                                isClearable
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        errors.branchId ?
                                                            <div className="invalid-feedback">
                                                                {errors.branchId.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <div className="add-newplus">
                                                        <label className="form-label">Área</label>
                                                        <a
                                                            onClick={() => setShowDepartmentModal(true)}
                                                        >
                                                            <PlusCircle className="plus-down-add text-secondary" />
                                                            <span className="text-secondary">Nueva</span>
                                                        </a>
                                                    </div>
                                                    <Controller
                                                        name="areaId"
                                                        control={control}
                                                        render={({ field: { onChange, value, ref } }) => (
                                                            <Select
                                                                inputRef={ref}
                                                                className={errors.areaId ? "form-control is-invalid" : ""}
                                                                options={departmentOptions}
                                                                placeholder="Elige un área"
                                                                value={value}
                                                                onChange={onChange}
                                                                noOptionsMessage={() => noOptionsText}
                                                                isDisabled={loading}
                                                                isClearable
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        errors.areaId ?
                                                            <div className="invalid-feedback">
                                                                {errors.areaId.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <div className="add-newplus">
                                                        <label className="form-label">Puesto</label>
                                                        <a
                                                            onClick={() => setShowDesignationModal(true)}
                                                        >
                                                            <PlusCircle className="plus-down-add text-secondary" />
                                                            <span className="text-secondary">Nuevo</span>
                                                        </a>
                                                    </div>
                                                    <Controller
                                                        name="designationId"
                                                        control={control}
                                                        render={({ field: { onChange, value, ref } }) => (
                                                            <Select
                                                                inputRef={ref}
                                                                className={errors.designationId ? "form-control is-invalid" : ""}
                                                                options={designationOptions}
                                                                placeholder="Elige un área"
                                                                value={value}
                                                                onChange={onChange}
                                                                noOptionsMessage={() => noOptionsText}
                                                                isDisabled={loading}
                                                                isClearable
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        errors.designationId ?
                                                            <div className="invalid-feedback">
                                                                {errors.designationId.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Fecha de ingreso</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                                                        <Controller
                                                            control={control}
                                                            name='joinDate'
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    selected={field.value}
                                                                    onChange={(date) => field.onChange(date)}
                                                                    dateFormat={fechaFormat}
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    todayButton={"Clic aqui para Hoy"}
                                                                    locale={es}
                                                                    wrapperClassName="form-control"
                                                                    className={!errors.joinDate ? "form-control" : "form-control is-invalid"}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Número de empleado</label>
                                                    <input
                                                        type="text"
                                                        placeholder="Escribe el número de empleado"
                                                        className={!errors.employeeId ? "form-control" : "form-control is-invalid"}
                                                        {...register("employeeId",
                                                            {
                                                                pattern: {
                                                                    value: customRegex,
                                                                    message: 'Solo se permiten letras, números, guiones bajos y medios. Debe tener al menos 5 caracteres.'
                                                                }
                                                            }
                                                        )}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                    {
                                                        errors.employeeId ?
                                                            <div className="invalid-feedback">
                                                                {errors.employeeId.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <label className="form-label">Nombre(s)</label>
                                                    <input
                                                        type="text"
                                                        className={!errors.name ? "form-control" : "form-control is-invalid"}
                                                        {...register("name")}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                    {
                                                        errors.name ?
                                                            <div className="invalid-feedback">
                                                                {errors.name.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <label className="form-label">Apellido Paterno</label>
                                                    <input
                                                        type="text"
                                                        className={!errors.surname ? "form-control" : "form-control is-invalid"}
                                                        {...register("surname")}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                    {
                                                        errors.surname ?
                                                            <div className="invalid-feedback">
                                                                {errors.surname.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <label className="form-label">Apellido Materno</label>
                                                    <input
                                                        type="text"
                                                        className={!errors.lastname ? "form-control" : "form-control is-invalid"}
                                                        {...register("lastname")}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                    {
                                                        errors.lastname ?
                                                            <div className="invalid-feedback">
                                                                {errors.lastname.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Correo electrónico<span className="text-danger"> *</span></label>
                                                    <div className="input-group flex-wrap">
                                                        <span className="input-group-text"><Mail size={15} color={variables.primaryColor} /></span>
                                                        <input
                                                            type="text"
                                                            placeholder="Escribe el email"
                                                            className={!errors.email ? "form-control" : "form-control is-invalid"}
                                                            {...register("email",
                                                                {
                                                                    required: "Necesitamos un correo electrónico",
                                                                    pattern: {
                                                                        value: emailRegex,
                                                                        message: "No tiene estructura de un email válido"
                                                                    },
                                                                }
                                                            )}
                                                            disabled={loading}
                                                            maxLength={300}
                                                        />
                                                        {
                                                            errors.email ?
                                                                <div className="invalid-feedback">
                                                                    {errors.email.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Teléfono</label>
                                                    <div className="input-group flex-wrap">
                                                        <ImageWithBasePath
                                                            src="assets/img/flags/mx.png"
                                                            alt="img"
                                                            id="mx"
                                                            style={{ height: 41 }}
                                                            className={"input-group-text"}
                                                        />
                                                        <span className="input-group-text">+52</span>
                                                        <input
                                                            type="text"
                                                            className={!errors.phone ? "form-control" : "form-control is-invalid"}
                                                            maxLength={10}
                                                            placeholder="Escribe el teléfono a 10 dígitos"
                                                            {...register("phone", {
                                                                pattern: {
                                                                    value: phoneRegex,
                                                                    message: "No tiene estructura de un teléfono válido"
                                                                },
                                                            })}
                                                            disabled={loading}
                                                        />
                                                        {
                                                            errors.phone ?
                                                                <div className="invalid-feedback">
                                                                    {errors.phone.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-sm-12 col-12 mb-3">
                                                    <label className="form-label">Calle y número</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register("address")}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                </div>
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <label className="form-label">Colonia</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register("neigborhood")}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                </div>
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <label className="form-label">Municipio/Delegación</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register("town")}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                </div>
                                                <div className="col-lg-4 col-sm-4 col-12 mb-3">
                                                    <label className="form-label">Estado</label>
                                                    <Controller
                                                        name="stateId"
                                                        control={control}
                                                        render={({ field: { onChange, value, ref } }) => (
                                                            <Select
                                                                inputRef={ref}
                                                                className={errors.stateId ? "form-control is-invalid" : ""}
                                                                options={stateOptions}
                                                                placeholder="Elige un estado"
                                                                value={value}
                                                                onChange={onChange}
                                                                noOptionsMessage={() => noOptionsText}
                                                                isDisabled={loading}
                                                                isClearable
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        errors.stateId ?
                                                            <div className="invalid-feedback">
                                                                {errors.stateId.message}
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Código Postal</label>
                                                    <div className="input-group flex-wrap">
                                                        <span className="input-group-text"><Hash size={15} color={variables.primaryColor} /></span>
                                                        <input
                                                            type="text"
                                                            placeholder="Escribe el C.P."
                                                            className={!errors.zipCode ? "form-control" : "form-control is-invalid"}
                                                            maxLength={5}
                                                            {...register("zipCode",
                                                                {
                                                                    pattern: {
                                                                        value: zipRegex,
                                                                        message: "No es un código postal con estructura válido."
                                                                    }
                                                                }
                                                            )}
                                                            disabled={loading}
                                                        />
                                                        {
                                                            errors.zipCode ?
                                                                <div className="invalid-feedback">
                                                                    {errors.zipCode.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">CURP</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register("curp")}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample3"
                            >
                                <div className="accordion-item">
                                    <div className="accordion-header" id="heading3">
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse3"
                                            aria-controls="collapse3"
                                        >
                                            <div className="text-editor add-list">
                                                <div className="addproduct-icon list icon">
                                                    <h5>
                                                        <FileText className="add-info" />
                                                        <span>Información fiscal</span>
                                                    </h5>
                                                    <a>
                                                        <ChevronDown className="chevron-down-add" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapse3"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="heading3"
                                        data-bs-parent="#accordionExample2"
                                    >
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-12 col-sm-12 col-12 mb-3">
                                                    <label className="form-label">Razón social</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register("businessName")}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">RFC</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        {...register("taxId")}
                                                        disabled={loading}
                                                        maxLength={300}
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mb-3">
                                                    <label className="form-label">Código Postal</label>
                                                    <div className="input-group flex-wrap">
                                                        <span className="input-group-text"><Hash size={15} color={variables.primaryColor} /></span>
                                                        <input
                                                            type="text"
                                                            placeholder="Escribe el C.P."
                                                            className={!errors.taxZipCode ? "form-control" : "form-control is-invalid"}
                                                            maxLength={5}
                                                            {...register("taxZipCode",
                                                                {
                                                                    pattern: {
                                                                        value: zipRegex,
                                                                        message: "No es un código postal con estructura válido."
                                                                    }
                                                                }
                                                            )}
                                                            disabled={loading}
                                                        />
                                                        {
                                                            errors.taxZipCode ?
                                                                <div className="invalid-feedback">
                                                                    {errors.taxZipCode.message}
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="accordion-card-one accordion"
                                id="accordionExample4"
                            >
                                <div className="accordion-item">
                                    <div className="accordion-header" id="headingFour">
                                        <div
                                            className="accordion-button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-controls="collapseFour"
                                        >
                                            <div className="text-editor add-list">
                                                <div className="addproduct-icon list">
                                                    <h5>
                                                        <List className="add-info" />
                                                        <span>Otros datos</span>
                                                    </h5>
                                                    <a>
                                                        <ChevronDown className="chevron-down-add" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample4"
                                    >
                                        <div className="accordion-body">
                                            <FormCreatedBy isNew={isNew} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="mb-4">
                            <div className="d-flex justify-content-center mb-4">
                                {
                                    loading ?
                                        <button disabled={loading} className="btn btn-submit">
                                            <Spinner animation="border" role="status" size="sm" />
                                        </button>
                                        :
                                        <input type="submit" value={isNew ? "Registrar" : "Actualizar"} className="btn btn-submit" />
                                }
                            </div>
                        </div>
                    </div>
                </form>
                {/* /add */}
            </div>
            {
                showRoleModal
                    ? <AddCatalog id="add-role" type="role" show={showRoleModal} onHide={onHideRoleModal} isNew />
                    : null
            }
            {
                showDesignationModal
                    ? <AddCatalog id="add-designation" type="designation" show={showDesignationModal} onHide={onHideDesignationModal} isNew />
                    : null
            }
            {
                showDepartmentModal
                    ?
                    <AddCatalog id="add-department" type="department" show={showDepartmentModal} onHide={onHideDepartmentModal} isNew />
                    : null
            }
        </div>
    );
};

FormEmployee.propTypes = {
    isNew: PropTypes.bool
};

export default FormEmployee;
