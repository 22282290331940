import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { ArrowLeft, Plus, Trash2 } from "react-feather";
import { DragSortTable } from "@ant-design/pro-components";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Checkbox } from "antd";
import AddIngredient from "../../../core/modals/recipes/addingredient";
import FormCreatedBy from "../../components/createdByForm";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setRecipeBookList, setRecipeList, setShowRemoteResponseToast } from "../../../core/redux/action";
import { showInternalErrorAlert } from "../../components/customAlert";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import ImageUploader from "../../components/fileUpload/ImageUpload";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { convertToOptions, generarCodigo, noOptionsText, validateRegexQty } from "../../../core/utils";
import { Copy } from "feather-icons-react/build/IconComponents";
import { MdOutlineFactory } from "react-icons/md";
import ProduceRecipe from "../../../core/modals/recipes/producerecipe";
import { Recipes } from "../../../services/internal/recipes";
import { RecipeBook } from "../../../services/internal/recipeBook";
import { Functions, Modules, verifyPermission } from "../../../Router/authorization";

const Recipe = () => {

    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = useSelector((state) => state.loading);
    const recipeBookList = useSelector((state) => state.recipeBookList);
    const { setValue, register, handleSubmit, formState: { errors }, control } = useForm();
    const token = useSelector((state) => state.token);
    const rolePermissions = useSelector((state) => state.rolePermissions);
    const [ingredientsDataSource, setIngredientsDataSource] = useState([]);
    const [resultsDataSource, setResultsDataSource] = useState([]);
    const [imagesPath, setImagesPath] = useState(["assets/img/recipes/receta.jpg"]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);
    const [showProduceRecipeModal, setShowProduceRecipeModal] = useState(false);
    const [recipe, setRecipe] = useState(null);
    const [recipeBookOptions, setRecipeBookOptions] = useState([]);

    const [searchParams] = useSearchParams();
    const paramRecipeId = searchParams.get('code');
    const paramBookId = searchParams.get('book');
    const route = all_routes;

    const handleException = (err) => {
        console.log(err);
        if (err.response) {
            dispatch(fetchDataFailure(err.response.data));
            if (err.response.status >= 400 && err.response.status < 500) {
                dispatch(fetchDataErrors(err.response.data.errors));
                dispatch(setShowRemoteResponseToast(true));
                return;
            } else {
                showInternalErrorAlert(false, err.response.statusText);
                return;
            }
        }
        dispatch(fetchDataFailure(err));
        showInternalErrorAlert(true, err.code);
    }

    const onLoadRecipe = async () => {
        try {
            dispatch(fetchDataRequest());

            let recipeOptions = [];
            if (recipeBookList.length === 0) {
                const response = await RecipeBook.getList(token, { enabled: true });
                recipeOptions = convertToOptions(response.data.data);
                setRecipeBookOptions(recipeOptions);
                dispatch(setRecipeBookList(response.data.data));
            } else {
                recipeOptions = convertToOptions(recipeBookList);
                setRecipeBookOptions(recipeOptions);
            }


            const response = await Recipes.getSingle(token, paramRecipeId);
            setValue("name", response.data.data.name);
            setValue("recipeBookId", recipeOptions.find((i) => i.value === response.data.data.recipeBookId));
            setIngredientsDataSource(convertoToDataSource(response.data.data.articleRelations.filter((f) => f.result === false)));
            setResultsDataSource(convertoToDataSource(response.data.data.articleRelations.filter((f) => f.result === true)));
            if (response.data.data.imagePath) {
                setImagesPath([response.data.data.imagePath]);
            }
            setRecipe(response.data.data);
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onLoadOptions = async () => {
        try {
            dispatch(fetchDataRequest());

            const response = await RecipeBook.getList(token, { enabled: true });
            const options = convertToOptions(response.data.data);
            setRecipeBookOptions(options);
            if (!isNaN(Number(paramBookId))) {
                setValue("recipeBookId", options.find((i) => i.value === Number(paramBookId)));
            }
            dispatch(setRecipeBookList(response.data.data));
            dispatch(fetchDataSuccess(response.data.success));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onSaveItem = async (formData) => {
        try {
            let data = Recipes.requestData;
            data = {};
            data.name = formData.name;
            data.description = formData.description;
            data.recipeBookId = formData.recipeBookId?.value;
            data.enabled = true;
            if (imagesPath?.length > 0) {
                data.imagePath = imagesPath[0];
            } else {
                data.imagePath = null;
            }
            data.ingredients = convertoToElement(ingredientsDataSource);
            data.results = convertoToElement(resultsDataSource);

            dispatch(fetchDataRequest());
            if (paramRecipeId && !isNaN(Number(paramRecipeId))) {
                data.recipeId = paramRecipeId;
                const response = await Recipes.putSingle(token, data);
                dispatch(fetchDataSuccess(response.data.success));
            } else {
                const response = await Recipes.postSingle(token, data);
                dispatch(fetchDataSuccess(response.data.success));
                navigate(route.recipegrid + `?code=${paramBookId}`);
            }

            dispatch(setShowRemoteResponseToast(true));
            dispatch(setRecipeList([]));
        }
        catch (err) {
            handleException(err);
        }
    }

    const onDeleteItem = async () => {
        try {
            if (isNaN(Number(paramRecipeId))) {
                return;
            }
            dispatch(fetchDataRequest());
            const response = await Recipes.deleteSingle(token, paramRecipeId);
            dispatch(fetchDataSuccess(response.data.success));
            dispatch(setShowRemoteResponseToast(true));
            dispatch(setRecipeList([]));
            navigate(route.recipegrid + `?code=${paramBookId}`);
        }
        catch (err) {
            handleException(err);
        }
    }

    const showDeleteItemAlert = async (text) => {
        const result = await MySwal.fire({
            title: "¿Estás seguro?",
            html: `Vamos a eliminar ${text}, esta acción es <strong>irreversible</strong>.`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
            customClass: {
                confirmButton: "btn btn-warning",
            },
        });

        if (result.isConfirmed) {
            await onDeleteItem();
        } else {
            MySwal.close();
        }
    };

    const handleIngredientDragSortEnd = (
        beforeIndex,
        afterIndex,
        newIngredientsDataSource
    ) => {
        setIngredientsDataSource(newIngredientsDataSource);
    };

    const handleResultDragSortEnd = (
        beforeIndex,
        afterIndex,
        newResultsDataSource
    ) => {
        setResultsDataSource(newResultsDataSource);
    };

    const onAddIngredient = (ingredient) => {
        if (ingredientsDataSource.find((f) => f.id === ingredient?.id)) {
            dispatch(fetchDataFailure({ message: "Ya tienes este ingrediente en la lista." }));
            dispatch(setShowRemoteResponseToast(true));
            setShowAddModal(false);
            return;
        }
        if (resultsDataSource.find((f) => f.id === ingredient?.id)) {
            dispatch(fetchDataFailure({ message: "Ya tienes este producto en la lista de resultados." }));
            dispatch(setShowRemoteResponseToast(true));
            setShowAddModal(false);
            return;
        }
        setShowAddModal(false);
        setIngredientsDataSource(prevIngredientsDataSource => [...prevIngredientsDataSource, ingredient]);
    };

    const onAddResult = (result) => {
        if (resultsDataSource.find((f) => f.id === result?.id)) {
            dispatch(fetchDataFailure({ message: "Ya tienes este resultado en la lista." }));
            dispatch(setShowRemoteResponseToast(true));
            setShowResultModal(false);
            return;
        }
        if (ingredientsDataSource.find((f) => f.id === result?.id)) {
            dispatch(fetchDataFailure({ message: "Ya tienes este producto en la lista de ingredientes." }));
            dispatch(setShowRemoteResponseToast(true));
            setShowResultModal(false);
            return;
        }
        setShowResultModal(false);
        setResultsDataSource(prevResultsDataSource => [...prevResultsDataSource, result]);
    };

    const onChangeIngredientQuantity = (e, record) => {
        ingredientsDataSource.map((i) => {
            if (i.key === record.key) {
                i.quantity = validateRegexQty(e.target.value);
            }
        });
        setIngredientsDataSource(prevIngredientsDataSource => [...prevIngredientsDataSource]);
    };

    const onChangeResultQuantity = (e, record) => {
        resultsDataSource.map((i) => {
            if (i.key === record.key) {
                i.quantity = validateRegexQty(e.target.value);
            }
        });
        setResultsDataSource(prevResultsDataSource => [...prevResultsDataSource]);
    };

    const onDeleteIngredient = (record) => {
        setIngredientsDataSource(prevIngredientsDataSource => [...prevIngredientsDataSource.filter(f => f.key !== record.key)]);
    };

    const onDeleteResult = (record) => {
        setResultsDataSource(prevResultsDataSource => [...prevResultsDataSource.filter(f => f.key !== record.key)]);
    };

    const onImagesChanged = (newImages) => {
        setImagesPath(newImages);
    }

    const convertoToElement = (items) => {
        let elements = [];
        items?.map((item) => {
            elements.push(
                {
                    element: item.id,
                    quantity: item.quantity
                }
            );
        });
        return elements;
    }

    const convertoToDataSource = (items) => {
        let elements = [];
        items?.map((item) => {
            elements.push(
                {
                    unmed: {
                        abbreviation: item.article.unmed.abbreviation
                    },
                    name: item.article.name,
                    quantity: item.quantity,
                    key: generarCodigo(),
                    id: item.article.id,
                    inventoried: item.article.inventoried
                }
            );
        });
        return elements;
    }

    const onShowAddModal = (e) => {
        e?.preventDefault();
        setShowAddModal(true);
    }

    const onShowResultModal = (e) => {
        e?.preventDefault();
        setShowResultModal(true);
    }

    useEffect(() => {
        if (paramRecipeId) {
            onLoadRecipe();
        } else {
            onLoadOptions();
        }
    }, [paramRecipeId, paramBookId]);


    const minWidth = 30;
    const shortWidth = 130;
    const mediumWidth = 160;

    const columns = [
        {
            dataIndex: 'sort',
            width: minWidth,
            className: 'drag-visible',
        },
        {
            title: "Afecta existencias",
            align: "center",
            width: mediumWidth,
            render: (text, record) => (
                <Checkbox checked={record?.inventoried} disabled />
            ),
        },
        {
            title: "Cantidad",
            dataIndex: "quantity",
            align: "center",
            width: shortWidth,
            render: (text, record) => (
                <input
                    type="text"
                    className="form-control text-center"
                    defaultValue={text}
                    onBlur={(e) => onChangeIngredientQuantity(e, record)}
                    disabled={loading}
                    maxLength={14}
                />
            ),
        },
        {
            title: "Unidad",
            dataIndex: "unit",
            align: "center",
            width: shortWidth,
            render: (text, record) => (
                <span>{record?.unmed?.abbreviation}</span>
            ),
        },
        {
            title: "Ingrediente",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <Link
                    to={route.productdetails + `?code=${record.id}`}
                    target="_blank" rel="noopener noreferrer"
                    className="text-primary fw-semibold"
                >
                    {text}
                </Link>
            ),
        },
        {
            title: "Acción",
            dataIndex: "actions",
            align: "center",
            width: shortWidth,
            key: "actions",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <a className="confirm-text p-2" onClick={() => onDeleteIngredient(record)}>
                            <Trash2 className="feather-trash-2" />
                        </a>
                    </div>
                </div>
            ),
        },

    ];

    const columns2 = [
        {
            dataIndex: 'sort',
            width: minWidth,
            className: 'drag-visible',
        },
        {
            title: "Afecta existencias",
            align: "center",
            width: mediumWidth,
            render: (text, record) => (
                <Checkbox checked={record?.inventoried} disabled />
            ),
        },
        {
            title: "Cantidad",
            dataIndex: "quantity",
            align: "center",
            width: shortWidth,
            render: (text, record) => (
                <input
                    type="text"
                    className="form-control text-center"
                    defaultValue={text}
                    onBlur={(e) => onChangeResultQuantity(e, record)}
                    disabled={loading}
                    maxLength={14}
                />
            ),
        },
        {
            title: "Unidad",
            dataIndex: "unit",
            align: "center",
            width: shortWidth,
            render: (text, record) => (
                <span>{record?.unmed?.abbreviation}</span>
            ),
        },
        {
            title: "Resultado",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <Link
                    to={route.productdetails + `?code=${record.id}`}
                    target="_blank" rel="noopener noreferrer"
                    className="text-primary fw-semibold"
                >
                    {text}
                </Link>
            ),
        },

        {
            title: "Acción",
            dataIndex: "actions",
            align: "center",
            width: shortWidth,
            key: "actions",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <a className="confirm-text p-2" onClick={() => onDeleteResult(record)}>
                            <Trash2 className="feather-trash-2" />
                        </a>
                    </div>
                </div>
            ),
        },

    ];

    return (
        <div className="page-wrapper">
            <form className="content" onSubmit={handleSubmit((data) => onSaveItem(data))}>
                <div className="page-header">
                    <div className="add-item d-flex">
                        <div className="page-title">
                            <h4>Receta</h4>
                            <h6>Puedes editar los productos y resultados de la receta.</h6>
                        </div>
                    </div>
                    <div className="page-btn">
                        <Link to={route.recipegrid + `?code=${paramBookId}`} className="btn btn-secondary">
                            <ArrowLeft className="me-2" />
                            Lista de Recetas
                        </Link>
                    </div>
                    {paramRecipeId && verifyPermission(rolePermissions, Modules.Recetarios, Functions.Copiar) &&
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-copy">Copiar receta</Tooltip>}
                        >
                            <div className="page-btn">
                                <a className="btn btn-warning">
                                    <Copy size={25} />
                                </a>
                            </div>
                        </OverlayTrigger>
                    }
                    {paramRecipeId && verifyPermission(rolePermissions, Modules.Recetarios, Functions.Producir) &&
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-produce">Producir receta</Tooltip>}
                        >
                            <div className="page-btn">
                                <a className="btn btn-purple" onClick={() => setShowProduceRecipeModal(true)}>
                                    <MdOutlineFactory size={25} />
                                </a>
                            </div>
                        </OverlayTrigger>
                    }
                    {paramRecipeId && verifyPermission(rolePermissions, Modules.Recetarios, Functions.Eliminar) &&
                        <div className="page-btn">
                            <a className="btn btn-primary" onClick={() => showDeleteItemAlert("la receta")}>
                                <Trash2 size={25} />
                            </a>
                        </div>
                    }
                </div>
                {/* /product list */}
                <div className="card table-list-card p-4">
                    <div className="row">
                        <div className="col-12 mb-3 d-flex align-items-center">
                            <ImageUploader
                                containerName="imagenes"
                                prefix="recipe_image"
                                imageGrouperId={`recipes/${paramRecipeId ? paramRecipeId : generarCodigo()}`}
                                onImagesChanged={onImagesChanged}
                                imagesPath={imagesPath}
                            />
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 mb-4">
                            <label className="form-label">Nombre<span className="text-danger"> *</span></label>
                            <input
                                type="text"
                                className={!errors.name ? "form-control" : "form-control is-invalid"}
                                {...register("name",
                                    {
                                        required: "Nombre es requerido.",
                                    }
                                )}
                                disabled={loading}
                                maxLength={300}
                            />
                            {
                                errors.name ?
                                    <div className="invalid-feedback">
                                        {errors.name.message}
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="col-lg-6 col-sm-6 col-12 mb-4">
                            <label className="form-label">Recetario <span className="text-danger">*</span></label>
                            <Controller
                                name="recipeBookId"
                                control={control}
                                render={({ field: { onChange, value, ref } }) => (
                                    <Select
                                        inputRef={ref}
                                        className={errors.recipeBookId ? "form-control is-invalid" : ""}
                                        options={recipeBookOptions}
                                        placeholder="Elige un recetario"
                                        value={value}
                                        onChange={onChange}
                                        noOptionsMessage={() => noOptionsText}
                                        isDisabled={loading}
                                        isClearable
                                    />
                                )}
                                rules={{ required: 'Requerido' }}
                            />
                            {
                                errors.recipeBookId ?
                                    <div className="invalid-feedback">
                                        {errors.recipeBookId.message}
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                    <div className="page-header mb-1">
                        <h4>Ingredientes</h4>
                        <div className="page-btn">
                            <button
                                className="btn btn-added"
                                onClick={(e) => onShowAddModal(e)}
                                disabled={loading}
                            >
                                <Plus className="me-2" />
                                Añadir
                            </button>
                        </div>
                    </div>
                    <div className="table-responsive p-0 mb-3">
                        <DragSortTable
                            className="table"
                            showHeader={ingredientsDataSource.length > 0}
                            toolBarRender={false}
                            search={false}
                            toolbar={false}
                            columns={columns}
                            rowKey="key"
                            pagination={false}
                            dataSource={ingredientsDataSource}
                            dragSortKey="sort"
                            size="small"
                            onDragSortEnd={handleIngredientDragSortEnd}
                        />
                    </div>
                    <div className="page-header mb-1">
                        <h4>Resultados</h4>
                        <div className="page-btn">
                            <button
                                className="btn btn-added"
                                onClick={(e) => onShowResultModal(e)}
                                disabled={loading}
                            >
                                <Plus className="me-2" />
                                Añadir
                            </button>
                        </div>
                    </div>
                    <div className="table-responsive p-0 mb-3">
                        <DragSortTable
                            className="table"
                            showHeader={ingredientsDataSource.length > 0}
                            toolBarRender={false}
                            search={false}
                            toolbar={false}
                            columns={columns2}
                            rowKey="key"
                            pagination={false}
                            dataSource={resultsDataSource}
                            dragSortKey="sort"
                            onDragSortEnd={handleResultDragSortEnd}
                        />
                    </div>
                    <FormCreatedBy />
                </div>
                <div className="d-flex justify-content-center mb-4">
                    {
                        loading ?
                            <button disabled={loading} className="btn btn-submit">
                                <Spinner animation="border" role="status" size="sm" />
                            </button>
                            :
                            <input type="submit" value="Guardar" className="btn btn-primary" />
                    }
                </div>
            </form>
            {
                showAddModal
                    ? <AddIngredient id="add-ingredient" show={showAddModal} isResult={false} onAddItem={onAddIngredient} onHide={() => setShowAddModal(false)} />
                    : null
            }
            {
                showResultModal
                    ? <AddIngredient id="add-result" show={showResultModal} isResult={true} onAddItem={onAddResult} onHide={() => setShowResultModal(false)} />
                    : null
            }
            {
                showProduceRecipeModal
                    ? <ProduceRecipe id="produceRecipe" show={showProduceRecipeModal} onHide={() => setShowProduceRecipeModal(false)} recipe={recipe} />
                    : null
            }
        </div>
    );
};

export default Recipe;
