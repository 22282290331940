import React from "react";
import { Route, Navigate } from "react-router-dom";
import ProductList from "../feature-module/inventory/productlist";
import Dashboard from "../feature-module/dashboard/Dashboard";
import AddProduct from "../feature-module/inventory/addproduct";
import SalesDashbaord from "../feature-module/dashboard/salesdashbaord";

const routes = all_routes;

import DepartmentGrid from "../feature-module/hrm/departmentgrid";
import Designation from "../feature-module/hrm/designation";
import Shift from "../feature-module/hrm/shift";
import AttendanceEmployee from "../feature-module/hrm/attendance-employee";
import ExpiredProduct from "../feature-module/inventory/expiredproduct";
import CategoryList from "../feature-module/inventory/categorylist";
import EditProduct from "../feature-module/inventory/editproduct";
import PurchasesList from "../feature-module/purchases/purchaseslist";
import InvoiceSettings from "../feature-module/settings/appsetting/invoicesettings";
import PosSettings from "../feature-module/settings/websitesettings/possettings";
import EmailSettings from "../feature-module/settings/systemsettings/emailsettings";
import PaymentGateway from "../feature-module/settings/financialsettings/paymentgateway";
import Customers from "../feature-module/people/customers";
import Suppliers from "../feature-module/people/suppliers";
import StoreList from "../feature-module/people/storelist";
import Managestock from "../feature-module/stock/managestock";
import StockAdjustment from "../feature-module/stock/stockAdjustment";
import StockTransfer from "../feature-module/stock/stockTransfer";
import SalesReport from "../feature-module/Reports/salesreport";
import PurchaseReport from "../feature-module/Reports/purchasereport";
import InventoryReport from "../feature-module/Reports/inventoryreport";
import Invoicereport from "../feature-module/Reports/invoicereport";
import SupplierReport from "../feature-module/Reports/supplierreport";
import CustomerReport from "../feature-module/Reports/customerreport";
import ExpenseReport from "../feature-module/Reports/expensereport";
import IncomeReport from "../feature-module/Reports/incomereport";
import TaxReport from "../feature-module/Reports/taxreport";
import Pos from "../feature-module/sales/pos";
import Holidays from "../feature-module/hrm/holidays";
import SalesList from "../feature-module/sales/saleslist";
import InvoiceReport from "../feature-module/sales/invoicereport";
import Profile from "../feature-module/pages/profile";
import Signin from "../feature-module/pages/login/signin";
import Forgotpassword from "../feature-module/pages/forgotpassword/forgotpassword";
import Resetpassword from "../feature-module/pages/resetpassword/resetpassword";
import Error404 from "../feature-module/pages/errorpages/error404";
import Error500 from "../feature-module/pages/errorpages/error500";
import Users from "../feature-module/usermanagement/users";
import RolesPermissions from "../feature-module/usermanagement/rolespermissions";
import Permissions from "../feature-module/usermanagement/permissions";
import EmployeesGrid from "../feature-module/hrm/employeesgrid";
import EditEmployee from "../feature-module/hrm/editemployee";
import AddEmployee from "../feature-module/hrm/addemployee";
import LeavesEmployee from "../feature-module/hrm/leavesemployee";
import LeaveTypes from "../feature-module/hrm/leavetypes";
import ProductDetail from "../feature-module/inventory/productdetail";
import { Units } from "../feature-module/inventory/units";
import TaxRates from "../feature-module/settings/financialsettings/taxrates";
import { all_routes } from "./all_routes";
import AddCustomer from "../feature-module/people/addcustomer";
import EditCustomer from "../feature-module/people/editcustomer";
import EditSupplier from "../feature-module/people/editsupplier";
import AddSupplier from "../feature-module/people/addsupplier";
import CashBreak from "../feature-module/sales/cashbreak";
import RecipeBook from "../feature-module/pages/recipes/recipebook";
import RecipeGrid from "../feature-module/pages/recipes/recipegrid";
import Recipe from "../feature-module/pages/recipes/recipe";
import SignOut from "../feature-module/pages/login/signout";
import { InventoryOutflow } from "../feature-module/inventory/expiredtypes";
import PurchaseProductList from "../feature-module/purchases/purchaseProductList";
import MenuGrid from "../feature-module/pages/menu/menugrid";
import Menu from "../feature-module/pages/menu/menudetail";
import { CashOutflowList } from "../feature-module/sales/cashoutflowlist";
import PurchaseDetail from "../feature-module/purchases/purchasedetail";
import TransferDetail from "../feature-module/purchases/transferdetail";
import PackageGrid from "../feature-module/pages/packages/packagegrid";
import PackageDetail from "../feature-module/pages/packages/packagedetail";
import SimplePos from "../feature-module/sales/simplePos";
import SaleDetails from "../feature-module/sales/saleDetails";
import LeaveEmployeeDetails from "../feature-module/hrm/leaveEmployeDetails";
import CashBreakDetails from "../feature-module/sales/cashBreakDetails";
import { Functions, Modules } from "./authorization";

export const authorizedRoutes = [
  {
    id: 1,
    path: routes.home,
    name: "home",
    element: <Dashboard />,
    route: Route,
    module: Modules.DashboardGeneral,
    function: Functions.Ver,
  },
  {
    id: 2,
    path: routes.salesdashboard,
    name: "salesdashboard",
    element: <SalesDashbaord />,
    route: Route,
    module: Modules.DashboardVentas,
    function: Functions.Ver,
  },
  {
    id: 3,
    path: routes.storelist,
    name: "storelist",
    element: <StoreList />,
    route: Route,
    module: Modules.Sucursales,
    function: Functions.Ver,
  },
  {
    id: 4,
    path: routes.productlist,
    name: "productlist",
    element: <ProductList />,
    route: Route,
    module: Modules.Productos,
    function: Functions.Ver,
  },
  {
    id: 5,
    path: routes.addproduct,
    name: "addproduct",
    element: <AddProduct />,
    route: Route,
    module: Modules.Productos,
    function: Functions.Registrar,
  },
  {
    id: 6,
    path: routes.units,
    name: "unit",
    element: <Units />,
    route: Route,
    module: Modules.Unidades,
    function: Functions.Ver,
  },
  {
    id: 7,
    path: routes.expiredproduct,
    name: "expiredproduct",
    element: <ExpiredProduct />,
    route: Route,
    module: Modules.OperacionesInventario,
    function: Functions.Ver,
  },
  {
    id: 8,
    path: routes.categorylist,
    name: "categorylist",
    element: <CategoryList />,
    route: Route,
    module: Modules.Categorias,
    function: Functions.Ver,
  },
  {
    id: 9,
    path: routes.editproduct,
    name: "editproduct",
    element: <EditProduct />,
    route: Route,
    module: Modules.Productos,
    function: Functions.Modificar,
  },
  {
    id: 10,
    path: routes.managestock,
    name: "managestock",
    element: <Managestock />,
    route: Route,
    module: Modules.TiposOperacionInventario,
    function: Functions.Ver,
  },
  {
    id: 11,
    path: routes.stockadjustment,
    name: "stockadjustment",
    element: <StockAdjustment />,
    route: Route,
    module: Modules.Existencias,
    function: Functions.Ver,
  },
  {
    id: 12,
    path: routes.stocktransfer,
    name: "stocktransfer",
    element: <StockTransfer />,
    route: Route,
    module: Modules.Traslados,
    function: Functions.Ver,
  },
  {
    id: 13,
    path: routes.expiredtypes,
    name: "expiredtypes",
    element: <InventoryOutflow />,
    route: Route,
    module: Modules.TiposOperacionInventario,
    function: Functions.Ver,
  },
  {
    id: 14,
    path: routes.productdetails,
    name: "productdetails",
    element: <ProductDetail />,
    route: Route,
    module: Modules.Productos,
    function: Functions.Modificar,
  },
  {
    id: 15,
    path: routes.purchaselist,
    name: "purchaselist",
    element: <PurchasesList />,
    route: Route,
    module: Modules.OrdenesCompra,
    function: Functions.Ver,
  },
  {
    id: 16,
    path: routes.suppliers,
    name: "suppliers",
    element: <Suppliers />,
    route: Route,
    module: Modules.Proveedores,
    function: Functions.Ver,
  },
  {
    id: 17,
    path: routes.purchasedetails,
    name: "purchasedetails",
    element: <PurchaseDetail />,
    route: Route,
    module: Modules.OrdenesCompra,
    function: Functions.Ver,
  },
  {
    id: 18,
    path: routes.transferdetails,
    name: "transferdetails",
    element: <TransferDetail />,
    route: Route,
    module: Modules.Traslados,
    function: Functions.Ver,
  },
  {
    id: 19,
    path: routes.purchaseProductList,
    name: "purchaseProductList",
    element: <PurchaseProductList />,
    route: Route,
    module: Modules.OrdenesCompra,
    function: Functions.Ver,
  },
  {
    id: 20,
    path: routes.addsupplier,
    name: "addsupplier",
    element: <AddSupplier />,
    route: Route,
    module: Modules.Proveedores,
    function: Functions.Registrar,
  },
  {
    id: 21,
    path: routes.editsupplier,
    name: "editsupplier",
    element: <EditSupplier />,
    route: Route,
    module: Modules.Proveedores,
    function: Functions.Modificar,
  },
  {
    id: 22,
    path: routes.packagegrid,
    name: "packagegrid",
    element: <PackageGrid />,
    route: Route,
    module: Modules.Paquetes,
    function: Functions.Ver,
  },
  {
    id: 23,
    path: routes.package,
    name: "package",
    element: <PackageDetail />,
    route: Route,
    module: Modules.Paquetes,
    function: Functions.Modificar,
  },
  {
    id: 24,
    path: routes.menugrid,
    name: "menugrid",
    element: <MenuGrid />,
    route: Route,
    module: Modules.Menus,
    function: Functions.Ver,
  },
  {
    id: 25,
    path: routes.menu,
    name: "menu",
    element: <Menu />,
    route: Route,
    module: Modules.Menus,
    function: Functions.Modificar,
  },
  {
    id: 26,
    path: routes.recipebook,
    name: "recipebook",
    element: <RecipeBook />,
    route: Route,
    module: Modules.Recetarios,
    function: Functions.Ver,
  },
  {
    id: 27,
    path: routes.recipegrid,
    name: "recipegrid",
    element: <RecipeGrid />,
    route: Route,
    module: Modules.Recetarios,
    function: Functions.Ver,
  },
  {
    id: 28,
    path: routes.recipe,
    name: "recipe",
    element: <Recipe />,
    route: Route,
    module: Modules.Recetarios,
    function: Functions.Modificar,
  },
  {
    id: 29,
    path: routes.customers,
    name: "customers",
    element: <Customers />,
    route: Route,
    module: Modules.Clientes,
    function: Functions.Ver,
  },
  {
    id: 30,
    path: routes.saleslist,
    name: "saleslist",
    element: <SalesList />,
    route: Route,
    module: Modules.Ventas,
    function: Functions.Ver,
  },
  {
    id: 31,
    path: routes.saleDetails,
    name: "saleDetails",
    element: <SaleDetails />,
    route: Route,
    module: Modules.Ventas,
    function: Functions.Ver,
  },
  {
    id: 32,
    path: routes.cashBreakDetails,
    name: "cashBreakDetails",
    element: <CashBreakDetails />,
    route: Route,
    module: Modules.CortesCaja,
    function: Functions.Ver,
  },
  {
    id: 33,
    path: routes.cashoutflowlist,
    name: "cashoutflowlist",
    element: <CashOutflowList />,
    route: Route,
    module: Modules.SalidasEfectivo,
    function: Functions.Ver,
  },
  {
    id: 34,
    path: routes.addcustomer,
    name: "addcustomer",
    element: <AddCustomer />,
    route: Route,
    module: Modules.Clientes,
    function: Functions.Registrar,
  },
  {
    id: 35,
    path: routes.editcustomer,
    name: "editcustomer",
    element: <EditCustomer />,
    route: Route,
    module: Modules.Clientes,
    function: Functions.Modificar,
  },
  {
    id: 36,
    path: routes.cashbreak,
    name: "cashbreak",
    element: <CashBreak />,
    route: Route,
    module: Modules.CortesCaja,
    function: Functions.Ver,
  },
  {
    id: 37,
    path: routes.departmentgrid,
    name: "departmentgrid",
    element: <DepartmentGrid />,
    route: Route,
    module: Modules.Areas,
    function: Functions.Ver,
  },
  {
    id: 38,
    path: routes.designation,
    name: "designation",
    element: <Designation />,
    route: Route,
    module: Modules.Puestos,
    function: Functions.Ver,
  },
  {
    id: 39,
    path: routes.shift,
    name: "shift",
    element: <Shift />,
    route: Route,
    module: Modules.Horarios,
    function: Functions.Ver,
  },
  {
    id: 40,
    path: routes.attendanceemployee,
    name: "attendanceemployee",
    element: <AttendanceEmployee />,
    route: Route,
    module: Modules.Asistencia,
    function: Functions.Ver,
  },
  {
    id: 41,
    path: routes.leaveEmployeeDetails,
    name: "leaveEmployeeDetails",
    element: <LeaveEmployeeDetails />,
    route: Route,
    module: Modules.Vacaciones,
    function: Functions.Ver,
  },
  {
    id: 42,
    path: routes.users,
    name: "users",
    element: <Users />,
    route: Route,
    module: Modules.Clientes,
    function: Functions.Ver,
  },
  {
    id: 43,
    path: routes.rolespermission,
    name: "rolespermission",
    element: <RolesPermissions />,
    route: Route,
    module: Modules.Roles,
    function: Functions.Ver,
  },
  {
    id: 44,
    path: routes.permissions,
    name: "permissions",
    element: <Permissions />,
    route: Route,
    module: Modules.Permisos,
    function: Functions.Ver,
  },
  {
    id: 45,
    path: routes.employeegrid,
    name: "employeegrid",
    element: <EmployeesGrid />,
    route: Route,
    module: Modules.Usuarios,
    function: Functions.Ver,
  },
  {
    id: 46,
    path: routes.addemployee,
    name: "addemployee",
    element: <AddEmployee />,
    route: Route,
    module: Modules.Usuarios,
    function: Functions.Registrar,
  },
  {
    id: 47,
    path: routes.editemployee,
    name: "editemployee",
    element: <EditEmployee />,
    route: Route,
    module: Modules.Usuarios,
    function: Functions.Modificar,
  },
  {
    id: 48,
    path: routes.leavesemployee,
    name: "leavesemployee",
    element: <LeavesEmployee />,
    route: Route,
    module: Modules.Vacaciones,
    function: Functions.Ver,
  },
  {
    id: 49,
    path: routes.leavestype,
    name: "leavestype",
    element: <LeaveTypes />,
    route: Route,
    module: Modules.TiposJustificacion,
    function: Functions.Ver,
  },
  {
    id: 50,
    path: routes.holidays,
    name: "holidays",
    element: <Holidays />,
    route: Route,
    module: Modules.DiasFestivos,
    function: Functions.Ver,
  },
  {
    id: 51,
    path: routes.salesreport,
    name: "salesreport",
    element: <SalesReport />,
    route: Route,
    module: Modules.ReporteVentas,
    function: Functions.Ver,
  },
  {
    id: 52,
    path: routes.purchasereport,
    name: "purchasereport",
    element: <PurchaseReport />,
    route: Route,
    module: Modules.ReporteCuentasPorPagar,
    function: Functions.Ver,
  },
  {
    id: 53,
    path: routes.inventoryreport,
    name: "inventoryreport",
    element: <InventoryReport />,
    route: Route,
    module: Modules.ReporteProduccion,
    function: Functions.Ver,
  },
  {
    id: 54,
    path: routes.invoicereport,
    name: "invoicereport",
    element: <Invoicereport />,
    route: Route,
    module: Modules.ReporteIngresos,
    function: Functions.Ver,
  },
  {
    id: 55,
    path: routes.supplierreport,
    name: "supplierreport",
    element: <SupplierReport />,
    route: Route,
    module: Modules.ReporteCuentasPorPagar,
    function: Functions.Ver,
  },
  {
    id: 56,
    path: routes.customerreport,
    name: "customerreport",
    element: <CustomerReport />,
    route: Route,
    module: Modules.ReporteIngresos,
    function: Functions.Ver,
  },
  {
    id: 57,
    path: routes.expensereport,
    name: "expensereport",
    element: <ExpenseReport />,
    route: Route,
    module: Modules.ReporteGastos,
    function: Functions.Ver,
  },
  {
    id: 58,
    path: routes.incomereport,
    name: "incomereport",
    element: <IncomeReport />,
    route: Route,
    module: Modules.ReporteIngresos,
    function: Functions.Ver,
  },
  {
    id: 59,
    path: routes.taxreport,
    name: "taxreport",
    element: <TaxReport />,
    route: Route,
    module: Modules.ReporteCuentasPorPagar,
    function: Functions.Ver,
  },
  {
    id: 60,
    path: routes.invoicereport,
    name: "invoicereport",
    element: <InvoiceReport />,
    route: Route,
    module: Modules.ReporteVentas,
    function: Functions.Ver,
  },
  {
    id: 1,
    path: routes.invoicesettings,
    name: "invoicesettings",
    element: <InvoiceSettings />,
    route: Route,
    module: Modules.ConfRecibos,
    function: Functions.Ver,
  },
  {
    id: 2,
    path: routes.possettings,
    name: "possettings",
    element: <PosSettings />,
    route: Route,
    module: Modules.ConfRecibos,
    function: Functions.Ver,
  },
  {
    id: 3,
    path: routes.emailsettings,
    name: "emailsettings",
    element: <EmailSettings />,
    route: Route,
    module: Modules.ConfSistema,
    function: Functions.Ver,
  },
  {
    id: 4,
    path: routes.paymentgateway,
    name: "paymentgateway",
    element: <PaymentGateway />,
    route: Route,
    module: Modules.ConfMetodosPago,
    function: Functions.Ver,
  },
  {
    id: 5,
    path: routes.taxrates,
    name: "taxrates",
    element: <TaxRates />,
    route: Route,
    module: Modules.ConfMetodosPago,
    function: Functions.Ver,
  },
];


export const publicRoutes = [
  {
    id: 1,
    path: routes.signin,
    name: "signin",
    element: <Signin />,
    route: Route,
  },
  {
    id: 2,
    path: routes.forgotPassword,
    name: "forgotPassword",
    element: <Forgotpassword />,
    route: Route,
  },
  {
    id: 3,
    path: routes.resetpassword,
    name: "resetpassword",
    element: <Resetpassword />,
    route: Route,
  },
  {
    id: 4,
    path: routes.error404,
    name: "error404",
    element: <Error404 />,
    route: Route,
  },
  {
    id: 5,
    path: routes.error500,
    name: "error500",
    element: <Error500 />,
    route: Route,
  },
  {
    id: 6,
    path: "/",
    name: "Root",
    element: <Navigate to="/" />,
    route: Route,
  },
  {
    id: 7,
    path: "*",
    name: "NotFound",
    element: <Navigate to="/" />,
    route: Route,
  },
];

export const posRoutes = [
  {
    id: 1,
    path: routes.pos,
    name: "pos",
    element: <Pos />,
    route: Route,
    module: Modules.PuntoVenta,
    function: Functions.Ver,
  },
  {
    id: 2,
    path: routes.simplepos,
    name: "simplepos",
    element: <SimplePos />,
    route: Route,
    module: Modules.PuntoVenta,
    function: Functions.Ver,
  },
];

export const userRoutes = [
  {
    id: 1,
    path: routes.profile,
    name: "profile",
    element: <Profile />,
    route: Route,
  },
  {
    id: 2,
    path: routes.signout,
    name: "signout",
    element: <SignOut />,
    route: Route,
  },
];
