import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataErrors, fetchDataFailure, fetchDataRequest, fetchDataSuccess, setCategoryOptions, setShowRemoteResponseToast, setStoreOptions } from "../../core/redux/action";
import { showInternalErrorAlert } from "../components/customAlert";
import { Catalogs } from "../../services/internal/catalogs";
import { Functions, Modules, verifyPermission } from "../../Router/authorization";
import { fechaFormat, fechaHoraFormat, formatearFecha, generarCodigo, noOptionsText } from "../../core/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Calendar, HelpCircle, RefreshCw, Search } from "react-feather";
import { Table } from "antd";
import FilterControl from "../components/filterControl";
import ToolsHead from "../components/toolsHead";
import { Reports } from "../../services/internal/reports";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import variables from "../../style/scss/utils/_variables.scss";
import es from 'date-fns/locale/es';
import { Controller, useForm } from "react-hook-form";
import ReactApexChart from "react-apexcharts";
import alasql from "alasql";

const InventoryReport = () => {

  registerLocale('es', es)

  const sd = new Date();
  const ed = new Date();
  sd.setHours(0, 0, 0);
  ed.setHours(23, 59, 59);
  const chartHeight = 350;

  const loading = useSelector((state) => state.loading);
  const token = useSelector(state => state.token);
  const rolePermissions = useSelector(state => state.rolePermissions);
  const categoryOptions = useSelector(state => state.categoryOptions);
  const storeOptions = useSelector(state => state.storeOptions);
  const dispatch = useDispatch();
  const { handleSubmit, setValue, formState: { errors }, control } = useForm();
  const [filterName, setFilterName] = useState("");
  const [filterStore, setFilterStore] = useState(null);
  const [filterCategory, setFilterCategory] = useState(null);
  const [filterStart, setFilterStart] = useState(sd);
  const [filterEnd, setFilterEnd] = useState(ed);
  const [dataSource, setDataSource] = useState([]);
  const [chartOptions, setChartOptions] = useState(null);
  const [chartSeries, setChartSeries] = useState(null);
  const [chartTitle, setChartTitle] = useState(null);

  const onChangeName = (e) => {
    setFilterName(e.target.value.trim())
  }

  const handleException = (err) => {
    console.log(err);
    if (err.response) {
      dispatch(fetchDataFailure(err.response.data));
      if (err.response.status >= 400 && err.response.status < 500) {
        dispatch(fetchDataErrors(err.response.data.errors));
        dispatch(setShowRemoteResponseToast(true));
        return;
      } else {
        showInternalErrorAlert(false, err.response.statusText);
        return;
      }
    }
    dispatch(fetchDataFailure(err));
    showInternalErrorAlert(true, err.code);
  }

  const onLoadOptions = async () => {
    try {
      dispatch(fetchDataRequest());

      const responseA = await Catalogs.getOptions(token, Catalogs.Name.Branches);
      dispatch(setStoreOptions(responseA.data.data));

      const responseB = await Catalogs.getOptions(token, Catalogs.Name.Categories);
      dispatch(setCategoryOptions(responseB.data.data));

      dispatch(fetchDataSuccess(responseB.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onLoadReport = async () => {
    let filters = {};
    if (filterName) {
      filters.article = filterName;
    }
    if (filterStore) {
      if (Array.isArray(filterStore)) {
        filters.branches = filterStore?.map(o => (o?.value));
      } else {
        filters.branches = filterStore?.value;
      }
    }
    if (filterCategory) {
      if (Array.isArray(filterCategory)) {
        filters.categories = filterCategory?.map(o => (o?.value));
      } else {
        filters.categories = filterCategory?.value;
      }
    }
    if (filterStart) {
      const date = new Date(filterStart);
      date.setHours(0, 0, 0);
      filters.dateStart = date.toISOString();
    }
    if (filterEnd) {
      const date = new Date(filterEnd);
      date.setHours(23, 59, 59);
      filters.dateEnd = date.toISOString();
    }

    try {
      dispatch(fetchDataRequest());
      const response = await Reports.getInventories(token, filters);
      setDataSource(response.data.data.operations);
      dispatch(fetchDataSuccess(response.data.success));
    }
    catch (err) {
      handleException(err);
    }
  }

  const onChangeStart = (field) => {
    const fe = new Date(filterEnd);
    if (fe?.getTime() < field?.getTime()) {
      setFilterEnd(fe?.setDate(field?.getDate() + 1));
    }
    setFilterStart(field);
  }

  const onChangeEnd = (field) => {
    setFilterEnd(field);
  }

  const getChartSeries = () => {
    let groupProp = "branchName";
    if (filterStore?.length > 0) {
      groupProp = "categoryName";
    }

    if (filterCategory?.length > 0 || filterName) {
      groupProp = "productName";
    }

    return [
      {
        name: 'Entradas',
        color: variables.successColor,
        data: getSum('incomes', groupProp)
      },
      {
        name: 'Salidas',
        color: variables.dangerColor,
        data: getSum('outComes', groupProp)
      }
    ];

    /*const catS = getDistinct("categoryName");
    const stS = getDistinct("store");
    const seriesE = catS.map(c => (
      {
        name: c + ', Entradas',
        group: 'Entradas',
        color: '#80c7fd',
        data: stS.map(s => {
          const result = getSumWhere2("stockIn", s, c);
          return (
            result ? result : 0
          );
        })
      }
    ));

    const seriesS = catS.map(c => (
      {
        name: c + ', Salidas',
        group: 'Salidas',
        color: '#80f1cb',
        data: stS.map(s => {
          const result = getSumWhere2("stockOut", s, c);
          return (
            result ? result : 0
          );
        })
      }
    ));

    return [...seriesE, ...seriesS];*/
  }

  const getChartOptions = () => {
    let prop = "branchName";

    if (filterStore?.length > 0) {
      prop = "categoryName";
    }

    if (filterCategory?.length > 0 || filterName) {
      prop = "productName";
    }

    return {
      chart: {
        type: 'bar',
        height: chartHeight,
        fontFamily: 'Nunito',
        stacked: false,
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      dataLabels: {
        formatter: (val) => {
          return Number(val).toFixed(4);
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900
              },
              position: 'top',
            }
          }
        },
      },
      xaxis: {
        categories: getDistinct(prop)
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return Number(val).toFixed(0);
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return Number(val).toFixed(4);
          }
        }
      },
      legend: {
        position: 'left',
        horizontalAlign: 'left',
      },
      theme: {
        palette: 'palette8',
      }
    };
  }

  const getDistinct = (property) => {
    if (dataSource?.length === 0) {
      return [];
    }

    try {
      const result = alasql(`COLUMN OF SELECT DISTINCT [${property}] FROM ? ORDER BY [${property}]`, [dataSource]);
      return result;
    }
    catch (e) {
      console.error(e);
      return [];
    }
  }

  const getSum = (sumProp, groupProp) => {
    if (dataSource?.length === 0) {
      return [];
    }

    try {
      const result = alasql(`COLUMN OF SELECT ACUMULADO FROM (SELECT [${groupProp}], SUM([${sumProp}]) AS ACUMULADO FROM ? GROUP BY [${groupProp}] ORDER BY [${groupProp}])`, [dataSource]);
      //console.log(result)
      return result;
    }
    catch (e) {
      console.error(e);
      return [];
    }
  }
  /*
    const getSumWhere = (sumProp, groupProp, whereProp) => {
      if (dataSource?.length === 0) {
        return [];
      }
  
      try {
        const result = alasql(`COLUMN OF SELECT ACUMULADO FROM (SELECT [${groupProp}], SUM([${sumProp}]) AS ACUMULADO FROM ? WHERE [categoryName]='${whereProp}' GROUP BY [${groupProp}] ORDER BY [${groupProp}])`, [dataSource]);
        //console.log(result)
        return result;
      }
      catch (e) {
        console.error(e);
        return [];
      }
    }*/
  /*
    const getSumWhere2 = (sumProp, storeValue, categoryValue) => {
      if (dataSource?.length === 0) {
        return [];
      }
  
      try {
        const result = alasql(`VALUE OF SELECT acumulado FROM (SELECT [store],[categoryName], SUM([${sumProp}]) AS acumulado FROM ? WHERE [store]='${storeValue}' AND [categoryName]='${categoryValue}' GROUP BY [store],[categoryName] ORDER BY [store],[categoryName])`, [dataSource]);
        return result;
      }
      catch (e) {
        console.error(e);
        return [];
      }
    }*/

  const getChartTitle = () => {
    if (filterName) {
      return `Operaciones de ${filterName}`;
    }


    if (filterStore?.length > 0) {
      if (Array.isArray(filterStore)) {
        return `Operaciones de ${filterStore?.map(s => (s?.label))?.join(', ')} por Categorías`;
      }

      return `Operaciones de ${filterStore?.label} por Categorías`;
    }

    if (filterCategory?.length > 0) {
      if (Array.isArray(filterCategory)) {
        return `Operaciones por categoría(s) de ${filterCategory?.map(s => (s?.label))?.join(', ')}`;
      }

      return `Operaciones por categoría(s) de ${filterCategory?.label} por Producto`
    }

    return "Operaciones por Sucursales";
  }

  const onRefresh = () => {
    setFilterCategory([]);
    setFilterStore([]);
    setFilterName("");
    setValue("start", sd);
    setValue("end", sd);
    setFilterStart(sd);
    setFilterEnd(ed);
    //onLoadReport();
  }

  useEffect(() => {
    if (storeOptions.length === 0 || categoryOptions.length === 0) {
      onLoadOptions();
    }
    onLoadReport();
  }, []);

  useEffect(() => {
    setChartSeries(getChartSeries());
    setChartOptions(getChartOptions());
    setChartTitle(getChartTitle());
  }, [dataSource]);

  const columns = [
    {
      title: "Fecha",
      align: 'center',
      defaultSortOrder: "descend",
      render: (text, record) => (
        <span>{record.date ? formatearFecha(record.date ?? new Date().toISOString(), fechaHoraFormat) : "Aún no hay datos"}</span>
      ),
      sorter: (a, b) => a.date?.localeCompare(b.date)
    },
    {
      title: "Sucursal",
      render: (text, record) => (
        <span className="text-primary-emphasis fw-semibold">{record.branchName}</span>
      ),
      sorter: (a, b) => a.branchName?.localeCompare(b.branchName)
    },
    {
      title: "Categoría",
      render: (text, record) => (
        <span className="text-secondary fw-semibold">{record.categoryName}</span>
      ),
      sorter: (a, b) => a.categoryName?.localeCompare(b.categoryName)
    },
    {
      title: "Producto",
      render: (text, record) => (
        <span className="text-primary fw-semibold">
          {record.productName}
        </span>
      ),
      sorter: (a, b) => a.productName?.localeCompare(b.productName)
    },
    {
      title: "Código de producto",
      align: 'center',
      render: (text, record) => (
        <span className="text-orange">{record.code}</span>
      ),
      sorter: (a, b) => a.code?.localeCompare(b.code)
    },
    {
      title: "Entradas",
      dataIndex: "incomes",
      align: 'center',
      render: (text, record) => (
        record.incomes !== 0 ? <span className="badges bg-success">{text}</span> : null
      ),
      sorter: (a, b) => a.incomes - b.incomes,
    },
    {
      title: "Salidas",
      dataIndex: "outComes",
      align: 'center',
      render: (text, record) => (
        record.outComes !== 0 ? <span className="badges bg-danger">{text}</span> : null
      ),
      sorter: (a, b) => a.outComes - b.outComes,
    },
  ];

  const renderInfoTooltip = (props, text) => (
    <Tooltip id="info-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Reporte de inventario</h4>
              <h6>Utiliza los filtros para personalizar los resultados.</h6>
            </div>
          </div>
          <ToolsHead
            showExportData={true}
            showExcel={verifyPermission(rolePermissions, Modules.Existencias, Functions.Exportar)}
            onReload={onRefresh}
          />
        </div>
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <form className="pe-4 pt-4 ps-4" onSubmit={handleSubmit((data) => onLoadReport(data))}>
              <div className="row">
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <div className="input-group">
                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => renderInfoTooltip(props, "Fecha Inicio")}
                    >
                      <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                    </OverlayTrigger>
                    <Controller
                      control={control}
                      name='start'
                      render={({ field }) => (
                        <DatePicker
                          maxDate={sd}
                          onChange={(date) => { field.onChange(date); onChangeStart(date) }}
                          selected={filterStart}
                          locale="es"
                          dateFormat={fechaFormat}
                          todayButton="Clic aquí para Hoy"
                          className={!errors.start ? "form-control" : "form-control is-invalid"}
                          wrapperClassName={"form-control"}
                          isDisabled={loading}
                          placeholderText="Fecha inicio"
                          showMonthDropdown
                          showYearDropdown
                        />
                      )}
                      rules={{ required: 'Requerido' }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <div className="input-group">
                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => renderInfoTooltip(props, "Fecha Fin")}
                    >
                      <span className="input-group-text"><Calendar size={15} color={variables.primaryColor} /></span>
                    </OverlayTrigger>
                    <Controller
                      control={control}
                      name='end'
                      render={({ field }) => (
                        <DatePicker
                          minDate={filterStart}
                          onChange={(date) => { field.onChange(date); onChangeEnd(date) }}
                          selected={filterEnd}
                          locale="es"
                          dateFormat={fechaFormat}
                          todayButton="Clic aquí para Hoy"
                          className={!errors.end ? "form-control" : "form-control is-invalid"}
                          wrapperClassName={"form-control"}
                          isDisabled={loading}
                          placeholderText="Fecha fin"
                          showMonthDropdown
                          showYearDropdown
                        />
                      )}
                      rules={{ required: 'Requerido' }}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <Select
                    options={storeOptions}
                    placeholder="Elige una sucursal"
                    value={filterStore}
                    onChange={(value) => setFilterStore(value)}
                    noOptionsMessage={() => noOptionsText}
                    components={{ Control: FilterControl }}
                    isDisabled={loading}
                    isClearable
                    isMulti
                  />
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <Select
                    options={categoryOptions}
                    onChange={(value) => setFilterCategory(value)}
                    value={filterCategory}
                    noOptionsMessage={() => noOptionsText}
                    isClearable
                    placeholder="Elige una categoría"
                    components={{ Control: FilterControl }}
                    isDisabled={loading}
                    isMulti
                  />
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Nombre de producto"
                      className="form-control form-control-sm formsearch"
                      onChange={(e) => onChangeName(e)}
                      value={filterName}
                      onBlur={onLoadReport}
                      disabled={loading}
                    />
                    <span className="input-group-text">
                      <Search color={variables.textColor} size={18} />
                    </span>
                    <OverlayTrigger placement="top" overlay={(props) => renderInfoTooltip(props, "Ingresa el nombre del producto")}>
                      <a className="input-group-text link-primary">
                        <HelpCircle size={18} />
                      </a>
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-12 mb-3">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => onLoadReport()}
                    autoFocus={filterName}
                    disabled={loading}
                  >
                    Filtrar <span><RefreshCw size={18} /></span>
                  </button>
                </div>
              </div>
            </form>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                className="table"
                rowKey={() => generarCodigo()}
                columns={columns}
                loading={loading}
                dataSource={dataSource}
                size="small"
                pagination={
                  {
                    position: ["topRight"],
                    total: dataSource?.length,
                    showTotal: (total) => `Total: ${total}`,
                    showSizeChanger: true
                  }
                }
              />
            </div>
            {chartOptions && chartSeries &&
              <div className="chart p-4">
                <h4>{chartTitle}</h4>
                <ReactApexChart
                  options={chartOptions}
                  series={chartSeries}
                  type="bar"
                  height={chartHeight}
                />
              </div>
            }
          </div>
        </div>
        {/* /product list */}
      </div >
    </div >
  );
};

export default InventoryReport;
